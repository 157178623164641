import React, { useState, useEffect } from "react";
import "../../assets/css/ParametresInvoice.css";
import InputTailwind from "../../components/token/Input/inputTailwind";
import { DocumentUpdater } from "../NumerotationQuantity/DocumentUpdater";
import { useUser } from "@/contexts/UserContext";
import { useCurrentInvoiceNumbers } from "@/hooks/useInvoices";
import { useCreateUpdatePhone } from "@/hooks/useCreateUpdatePhone";
import { useCreateUpdateEmail } from "@/hooks/useCreateUpdateEmail";
import ColorPicker from "@/components/token/ColorPicker/ColorPicker";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Toggle from "@/components/token/button/toggle";
import ToggleInput from "@/components/token/Input/toggleInput";
import { notify } from "@/utils/notify";
import { add } from "date-fns";

export default function ParametresInvoice({
  setSelectedLogo,
  setParametersOpen,
  documentData,
  setDocumentData,
  phoneData,
  emailData,
}) {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const currentNumbers = useCurrentInvoiceNumbers({
    enterpriseId,
  });

  const handlePhone = useCreateUpdatePhone(["phones"], undefined, () =>
    notify({
      isSuccess: true,
      message: "Numéro de téléphone mis à jour avec succès",
    })
  );

  const handleEmail = useCreateUpdateEmail(["emails"], undefined, () =>
    notify({ isSuccess: true, message: "Email mis à jour avec succès" })
  );

  const [paramLogo] = useState(null);

  useEffect(() => {
    if (paramLogo) {
      setSelectedLogo(paramLogo);
    }
  }, [paramLogo]);

  const handleSecondaryColorChange = (event) => {
    setDocumentData({
      ...documentData,
      data: {
        ...documentData.data,
        additionalData: documentData.data.additionalData.map((data) =>
          data.additionalDataType === "DOCUMENT_TITLE_COLOR"
            ? { ...data, additionalData: event }
            : data.additionalDataType === "DOCUMENT_TITLE_COLOR"
            ? { ...data, additionalData: event }
            : data
        ),
      },
    });
  };

  const handlePrimaryColorChange = (event) => {
    setDocumentData({
      ...documentData,
      data: {
        ...documentData?.data,
        additionalData: documentData?.data?.additionalData?.map((data) =>
          data?.additionalDataType === "DOCUMENT_BODY_COLOR"
            ? { ...data, additionalData: event }
            : data?.additionalDataType === "DOCUMENT_BODY_COLOR"
            ? { ...data, additionalData: event }
            : data
        ),
      },
    });
  };

  function toggleEmail(status) {
    setDocumentData((prevDocumentData) => {
      const existingEmailData =
        prevDocumentData.data.issuerMail.data ||
        prevDocumentData.data.additionalData.find(
          (data) => data.additionalDataType === "ISSUER_EMAIL"
        )?.additionalData;

      const newData = status
        ? [
            ...prevDocumentData.data.additionalData.filter(
              (data) => data.additionalDataType !== "ISSUER_EMAIL"
            ),
            {
              additionalDataType: "ISSUER_EMAIL",
              additionalData: existingEmailData,
              additionalDataSubType: "NOT_APPLICABLE",
            },
          ]
        : prevDocumentData.data.additionalData.filter(
            (data) => data.additionalDataType !== "ISSUER_EMAIL"
          );

      return {
        ...prevDocumentData,
        data: {
          ...prevDocumentData.data,
          issuerMail: {
            isShown: status,
            data: existingEmailData,
          },
          additionalData: newData,
        },
      };
    });
  }

  function togglePhone(status) {
    setDocumentData((prevDocumentData) => {
      const existingPhoneData =
        prevDocumentData.data.issuerPhone.data ||
        prevDocumentData.data.additionalData.find(
          (data) => data.additionalDataType === "ISSUER_PHONE"
        )?.additionalData;

      const newData = status
        ? [
            ...prevDocumentData.data.additionalData.filter(
              (data) => data.additionalDataType !== "ISSUER_PHONE"
            ),
            {
              additionalDataType: "ISSUER_PHONE",
              additionalData: existingPhoneData,
              additionalDataSubType: "NOT_APPLICABLE",
            },
          ]
        : prevDocumentData.data.additionalData.filter(
            (data) => data.additionalDataType !== "ISSUER_PHONE"
          );

      return {
        ...prevDocumentData,
        data: {
          ...prevDocumentData.data,
          issuerPhone: {
            isShown: status,
            data: existingPhoneData,
          },
          additionalData: newData,
        },
      };
    });
  }

  function contactUpdate() {
    if (documentData?.data?.issuerMail?.data !== emailData?.email) {
      const newEmail = {
        isMain: true,
        isBillingEmail: false,
        email: documentData.data.issuerMail.data,
      };
      handleEmail.mutate({
        stakeholderId: enterpriseId,
        email: newEmail,
        id: emailData?.id,
      });
    }

    if (
      documentData.data.issuerPhone.phone !==
      `${phoneData.geoCode}${phoneData.phoneNumber}`
    ) {
      const newPhone = {
        isMain: true,
        isBillingPhone: true,
        phoneNumber: parseInt(documentData.data.issuerPhone.data.slice(2)),
        geoCode: documentData.data.issuerPhone.data.substring(0, 2),
      };
      handlePhone.mutate({
        stakeholderId: enterpriseId,
        phone: newPhone,
        id: phoneData.id,
      });
    }
  }

  return (
    <div className="mx-auto w-[512px] flex flex-col justify-start items-left h-screen">
      <div className="w-[512px] p-8">
        <ButtonTailwind
          onClick={() => setParametersOpen(false)}
          variant={"text"}
        >
          ← Retour
        </ButtonTailwind>
      </div>

      <div className="flex flex-col gap-2 px-8 pb-8">
        <h6 className="text-black font-bold">Informations de facturation</h6>

        <ToggleInput
          label="Email"
          type="email"
          placeholder={"account.pro@lidap.be"}
          value={documentData?.data?.issuerMail?.data}
          onChange={(e) => {
            setDocumentData({
              ...documentData,
              data: {
                ...documentData.data,
                additionalData: documentData.data.additionalData.map((data) =>
                  data.additionalDataType === "ISSUER_EMAIL"
                    ? { ...data, additionalData: e.target.value }
                    : data
                ),
                issuerMail: {
                  ...documentData?.data?.issuerMail,
                  data: e?.target?.value,
                },
              },
            });
          }}
          isRequired={false}
          icon={false}
          toggle={documentData?.data?.issuerMail?.isShown}
          onToggle={() => {
            toggleEmail(!documentData?.data?.issuerMail?.isShown);
          }}
        ></ToggleInput>

        <ToggleInput
          label="Téléphone"
          type="tel"
          placeholder={"+33 6 12 34 56 78"}
          value={documentData?.data?.issuerPhone?.data}
          onChange={(e) => {
            setDocumentData({
              ...documentData,
              data: {
                ...documentData.data,
                additionalData: documentData.data.additionalData.map((data) =>
                  data.additionalDataType === "ISSUER_PHONE"
                    ? { ...data, additionalData: e.target.value }
                    : data
                ),
                issuerPhone: {
                  ...documentData?.data?.issuerPhone,
                  data: e?.target?.value,
                },
              },
            });
          }}
          isRequired={false}
          icon={false}
          toggle={documentData?.data?.issuerPhone?.isShown}
          onToggle={() => {
            togglePhone(!documentData?.data?.issuerPhone?.isShown);
          }}
        ></ToggleInput>

        <div className="mt-2">
          <ButtonTailwind
            onClick={contactUpdate}
            variant={
              documentData?.data?.issuerMail?.data !== emailData?.email ||
              documentData?.data?.issuerPhone?.data !==
                `${phoneData.geoCode}${phoneData.phoneNumber}`
                ? "filled"
                : "outlined2"
            }
          >
            Mise à jour
          </ButtonTailwind>
        </div>
      </div>
      <div className="flex flex-col gap-2 p-8 pt-0">
        <h6 className="text-black font-bold">Numérotation</h6>
        {currentNumbers.isLoading ? null : (
          <DocumentUpdater
            currentNumbers={currentNumbers}
            isError={currentNumbers.isError}
            isLoading={currentNumbers.isLoading}
            estimateDisabled={
              documentData.data.documentType === "QUOTE" ? false : true
            }
            invoiceDisabled={
              documentData.data.documentType === "INVOICE" ? false : true
            }
            creditNoteDisabled={
              documentData.data.documentType === "CREDIT_NOTE" ? false : true
            }
          />
        )}
      </div>
      <div className="flex flex-col gap-6 p-8">
        <h6 className="text-black font-bold">Couleurs</h6>
        <div>
          <h1 className="text-4xl font-bold text-gray-900">Couleurs</h1>
          <ColorPicker
            color={
              documentData?.data?.additionalData?.find(
                (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
              )?.additionalData
            }
            setColor={handlePrimaryColorChange}
            label={"Couleur principale"}
          />
          <ColorPicker
            color={
              documentData?.data?.additionalData?.find(
                (data) => data.additionalDataType === "DOCUMENT_TITLE_COLOR"
              )?.additionalData
            }
            setColor={handleSecondaryColorChange}
            label={"Couleur secondaire"}
          />
        </div>
      </div>
    </div>
  );
}
{
  /* <h4 className="text-black text-[1.5rem] font-semibold">Logo</h4>

        <div className="flex flex-row gap-4">
          <label
            htmlFor="inputFile"
            className={`${
              Logo ? "" : "border border-[#DBDBDB] rounded-sm"
            } flex justify-center items-center  w-[72px] h-[72px]`}
          >
            {Logo ? (
              <img src={Logo} id="selected-logo"></img>
            ) : (
              <AddPhotoAlternateOutlinedIcon
                sx={"var(--shades-50)"}
              ></AddPhotoAlternateOutlinedIcon>
            )}
          </label>

          <label
            htmlFor="inputFile"
            className="flex flex-col gap-2 justify-start"
          >
            <input
              type="file"
              id="inputFile"
              accept=".png,.jpg,.svg"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />

            <ButtonTailwind onClick={triggerFileInputClick} variant="outlined2">
              Modifier
            </ButtonTailwind>

            <p className="text-xs">
              Vérifier que l&apos;image soit bien au format<br></br> PNG, JPG ou
              SVG et qu&apos;elle ne dépasse<br></br> pas 5 Mo
            </p>
          </label> 
        </div>*/
}
