import SelectFilter from "@/components/token/selectFilter/selectFilter";
import { useSearchParams } from "react-router-dom";
import LidapTable from "@/components/token/table/LidapTable";
import { useLidapTableBuild, useLidapTableInit } from "@/hooks/useLidapTable";
import SubscriptionStatus from "@/components/token/subscription/SubscriptionStatus";
import {
  getSubscriptionList,
  useCreateDefaultSubscriptionByAdmin,
  useResyncSubscription,
  useSubscriptionList,
} from "@/hooks/stripe/useLidapStripe";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import StatusButton from "@/components/token/StatusButton/StatusButton";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { notify } from "@/utils/notify";
import OnboardingStatus from "@/components/token/onboarding/OnboardingStatus";

export function SubscriptionOverview() {
  const [tabFilter, setTabFilter] = useState("ALL");

  const [searchParams, setSearchParams] = useSearchParams();
  const statusFilter = searchParams.get("status");
  const [syncingEnterpriseId, setSyncingEnterpriseId] = useState(null);
  const [createSubscriptionEnterpriseId, setCreateSubscriptionEnterpriseId] =
    useState(null);

  const queryClient = useQueryClient();
  const resync = useResyncSubscription(
    ["admin", "payments", "stripe"],
    undefined,
    () => {
      notify({
        isSuccess: true,
        message: "Synchronisation effectuée",
      });
      setSyncingEnterpriseId(null);
    }
  );
  const createDefaultSubscription = useCreateDefaultSubscriptionByAdmin(
    ["admin", "payments", "stripe"],
    undefined,
    () => {
      notify({
        isSuccess: true,
        message: "Abonnement créé",
      });
      setCreateSubscriptionEnterpriseId(null);
    }
  );

  const lidapTableInit = useLidapTableInit({
    chainingActions: [],
  });

  const status = statusFilter ? statusFilter : null;
  const subscriptions = useSubscriptionList({
    queryParam: {
      status,
      pageNumber: lidapTableInit.page - 1,
      numberOfRecords: lidapTableInit.numberOfRecords,
    },
    reactQueryConfig: {
      keepPreviousData: true,
    },
  });

  const trialDaysRemaining = useCallback((trialEnd, status) => {
    if (!trialEnd || status !== "trialing") return null;

    return Math.floor(
      (new Date(trialEnd) - Date.now()) / (1000 * 60 * 60 * 24)
    );
  }, []);

  //   const comment = useCallback(
  //     (trialEnd, isPaymentMethodSet, isDeleted, cancelAtPeriodEnd) => {
  //       const trialDaysRemaining = Math.floor(
  //         (new Date(trialEnd) - Date.now()) / (1000 * 60 * 60 * 24)
  //       );

  //       if (isDeleted) {
  //         return "Supprimé";
  //       }

  //       if (cancelAtPeriodEnd) {
  //         return "Annulé à la fin de la période";
  //       }

  //       if (trialDaysRemaining >= 0) {
  //         return `Jour(s) restant(s): ${trialDaysRemaining}. Méthode de paiement active: ${
  //           isPaymentMethodSet ? "Oui" : "Non"
  //         }`;
  //       }
  //     },
  //     []
  //   );

  const columns = useMemo(
    () => [
      {
        accessorKey: "commonName",
        header: "Utilisateur",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "w-30 truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
          header:
            "w-30 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
        },
      },
      {
        accessorKey: "email",
        header: "Adresse email",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "w-48 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-48 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        accessorKey: "status",
        header: "Statut",
        cell: ({ cell }) => <SubscriptionStatus status={cell.getValue()} />,
        className: {
          cell: "w-18 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-18 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
        filterFn: "equalsString",
        enableColumnFilter: true,
      },
      {
        accessorKey: "onboardingStatus",
        header: "Flux d'inscription",
        cell: ({ cell }) => <OnboardingStatus status={cell.getValue()} />,
        className: {
          cell: "w-18 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-18 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
        filterFn: "equalsString",
        enableColumnFilter: true,
      },
      {
        accessorKey: "trialEnd",
        header: "Jours restants",
        cell: ({ cell }) =>
          trialDaysRemaining(cell.getValue(), cell.row.original.status),
        className: {
          cell: "w-10 truncate px-3 py-4 text-sm text-gray-500 text-center",
          header:
            "w-14 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        accessorKey: "isPaymentMethodSet",
        header: "Moyen de paiement",
        cell: ({ cell }) =>
          cell.getValue() ? (
            <CheckCircleIcon className="h-5 w-5 text-green-700" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-700" />
          ),
        className: {
          cell: "w-20 truncate px-3 py-4 text-sm text-gray-500 text-center",
          header:
            "w-20 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        accessorKey: "cancelAtPeriodEnd",
        header: "Remarque",
        cell: ({ cell }) =>
          cell.getValue() ? (
            <div className={"flex flex-row gap-2 items-center"}>
              <ExclamationTriangleIcon className="h-5 w-5 text-red-700" />
              <span className={"text-sm"}>
                {"Annulation à la fin de la période"}
              </span>
            </div>
          ) : null,
        className: {
          cell: "w-20 truncate px-3 py-4 text-sm text-gray-500 text-center",
          header:
            "w-20 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      //   {
      //     accessorKey: "customerId",
      //     header: "Identifiant Stripe",
      //     cell: ({ cell }) => cell.getValue(),
      //     className: {
      //       cell: "w-19 truncate px-3 py-4 text-sm text-gray-500",
      //       header:
      //         "w-19 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
      //     },
      //   },
      {
        accessorKey: "created",
        header: "Créé le",
        cell: ({ cell }) => {
          const userCreated = cell.row.original.userCreated;
          return new Date(cell.getValue() || userCreated).toLocaleDateString();
        },
        className: {
          cell: "w-10 truncate px-3 py-4 text-right text-sm text-gray-500",
          header:
            "w-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      {
        accessorKey: "country",
        header: "Pays",
        cell: ({ cell }) => cell.getValue(),
        className: {
          cell: "w-2 truncate px-3 py-4 text-sm text-gray-500",
          header:
            "w-2 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
        },
      },
      //   {
      //     header: "Commentaire",
      //     cell: ({ row }) =>
      //       comment(
      //         row.original.trialEnd,
      //         row.original.isPaymentMethodSet,
      //         row.original.isDeleted,
      //         row.original.cancelAtPeriodEnd
      //       ),
      //     className: {
      //       cell: "w-20 truncate px-3 py-4 text-sm text-gray-500",
      //       header:
      //         "w-20 px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
      //     },
      //   },
      {
        id: "ada",
        header: () => {
          return <span className="sr-only">Additional actions</span>;
        },
        cell: ({ cell }) => {
          const userId = cell.row.original.userId;
          const enterpriseId = cell.row.original.enterpriseId;
          const subscriptionId = cell.row.original.subscriptionId;
          const onboardingStatus = cell.row.original.onboardingStatus;

          const handleEllipsisClick = () => {
            if (!userId) return;

            const {
              queryFn: subscriptionListQueryFn,
              queryKey: subscriptionListQueryKey,
            } = getSubscriptionList({
              queryParam: { userId },
            });

            queryClient.prefetchQuery(
              subscriptionListQueryKey,
              subscriptionListQueryFn,
              {
                staleTime: 1000 * 60 * 5,
              }
            );
          };

          return userId !== null ? (
            <div className="flex justify-end ml-auto rounded-md border-neutral-200 border w-max">
              <StatusButton
                icon={EyeIcon}
                linkTo={`/admin/subscriptions/users/${userId}`}
                tooltipText={"Consulter"}
                onMouseEnter={handleEllipsisClick}
              />
              {enterpriseId !== null ? (
                <StatusButton
                  icon={ArrowPathIcon}
                  tooltipText="Synchroniser"
                  onClick={() => {
                    setSyncingEnterpriseId(enterpriseId);
                    return resync.mutate({ enterpriseId });
                  }}
                  isLoading={
                    resync.isLoading && syncingEnterpriseId === enterpriseId
                  }
                  key={`sync:${enterpriseId}`}
                />
              ) : null}
              {!subscriptionId &&
              enterpriseId &&
              onboardingStatus !== "SUBSCRIPTION_CREATED" &&
              onboardingStatus !== "SUBSCRIPTION_REQUESTED" ? (
                <StatusButton
                  icon={PlusCircleIcon}
                  tooltipText="Créer un abonnement"
                  onClick={() => {
                    setCreateSubscriptionEnterpriseId(enterpriseId);
                    return createDefaultSubscription.mutate({ enterpriseId });
                  }}
                  isLoading={
                    createDefaultSubscription.isLoading &&
                    createSubscriptionEnterpriseId === enterpriseId
                  }
                  key={`dft:${enterpriseId}`}
                />
              ) : null}
            </div>
          ) : null;
        },
        className: {
          cell: "w-16 select-none relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-gray-500 hover:text-indigo-900 cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500",
          header: "w-16 relative py-3.5 pl-3 pr-4 sm:pr-6",
        },
      },
    ],
    [
      queryClient,
      trialDaysRemaining,
      resync,
      syncingEnterpriseId,
      createDefaultSubscription,
      createSubscriptionEnterpriseId,
    ]
  );

  const lidapTableBuild = useLidapTableBuild({
    query: subscriptions,
    columns,
    lidapTableInit,
  });

  const statusArray = [
    // name est affiché, value est la valeur utilisée pour le filtre (correspond à l'API)
    { id: 0, name: "Filtres", value: "" },
    { id: 1, name: "Tous", value: "" },
    { id: 2, name: "Actif", value: "active" },
    { id: 3, name: "Essai", value: "trialing" },
    { id: 4, name: "Échu", value: "past_due" },
    { id: 5, name: "En pause", value: "paused" },
    { id: 6, name: "Incomplet", value: "incomplete" },
    { id: 7, name: "Annulé", value: "canceled" },
    { id: 8, name: "Non payé", value: "unpaid" },
    { id: 9, name: "Incomplet, expiré", value: "incomplete_expired" },
  ];

  return (
    <>
      <LidapTable
        lidapTableInit={lidapTableInit}
        lidapTableBuild={lidapTableBuild}
        additionalFilters={[
          {
            name: "Statut",
            render: (
              <SelectFilter
                status={statusArray}
                filter={statusArray.find(
                  (s) =>
                    s.value ===
                    (status ||
                      lidapTableBuild.table
                        .getColumn("status")
                        .getFilterValue())
                )}
                setFilter={(status) => {
                  lidapTableBuild.table
                    .getColumn("status")
                    .setFilterValue(status.value);
                  searchParams.set("status", status.value);
                  setSearchParams(searchParams);
                }}
              />
            ),
          },
        ]}
        additionalActionsOnPaginate={() => {}}
        actionButtons={[]}
        tabFilters={{
          filterColumn: "status",
          defaultFilter: "ALL",
          tabs: [
            {
              name: "Tous",
              filter: "ALL",
            },
            {
              name: "Actif",
              filter: "active",
            },
            {
              name: "Essai",
              filter: "trialing",
            },
            {
              name: "Échu",
              filter: "past_due",
            },
            {
              name: "En pause",
              filter: "paused",
            },
            {
              name: "Incomplet",
              filter: "incomplete",
            },
            {
              name: "Annulé",
              filter: "canceled",
            },
            {
              name: "Non payé",
              filter: "unpaid",
            },
            {
              name: "Incomplet, expiré",
              filter: "incomplete_expired",
            },
          ],
        }}
        tabFilter={tabFilter}
        setTabFilter={setTabFilter}
      />
    </>
  );
}
