import React, { useState, useEffect } from "react";
import LoadingBlock from "@/components/elements/LoadingBlock";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Select from "@/components/token/select/select";
import InputTailwind from "@/components/token/Input/inputTailwind";
import { useUser } from "@/contexts/UserContext";
import { usePaymentInformation } from "@/hooks/usePaymentInformation";
import { useCreateUpdatePaymentInformation } from "@/hooks/useCreateUpdatePaymentInformation";

const PaymentMethod = {
  IBAN: "IBAN",
  PAYMENT_LINK: "PAYMENT_LINK",
  CHEQUE: "CHEQUE",
  CASH: "CASH",
};

export default function CompteACrediter(props) {
  const userContext = useUser();
  const country = userContext.state.user?.country || "BE";
  const enterpriseId = userContext.state.user.enterpriseId;
  const paymentInformation = usePaymentInformation({
    stakeholderId: enterpriseId,
  });
  const createPaymentInformation = useCreateUpdatePaymentInformation(
    ["payment-information"],
    () => {
      props.setIsAccountFormDisplayed(false);
    }
  );
  const [selectedPayment, setSelectedPayment] = useState({
    id: 1,
    name: "Sélectionnez un moyen de paiement",
    secondInput: false,
    isInputHidden: true,
  });
  const [accountWording, setAccountWording] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");

  useEffect(() => {
    setAccountWording("");
    setSecondInputValue("");
    if (selectedPayment.placeholderAccountWording === "Espèces") {
      setAccountWording("Paiement en espèces");
    }
  }, [selectedPayment]);

  const accounts = (() => {
    if (paymentInformation.isLoading || paymentInformation.isError) {
      return [];
    }

    return paymentInformation.data.payload.stakeholderPaymentInformation.map(
      (p) => {
        const method = p.paymentInformationDetails.paymentMethod;
        return {
          id: p.title,
          name: p.title,
          paymentMethod: p.paymentInformationDetails.paymentMethod,
          iban:
            method === PaymentMethod.IBAN
              ? p.paymentInformationDetails.iban
              : undefined,
          inFavourOf:
            method === PaymentMethod.CHEQUE
              ? p.paymentInformationDetails.inFavourOf
              : undefined,
          url:
            method === PaymentMethod.PAYMENT_LINK
              ? p.paymentInformationDetails.url
              : undefined,
        };
      }
    );
  })();

  const paymentType = [
    {
      id: PaymentMethod.IBAN,
      name: "Compte bancaire",
      placeholderAccountWording: "Libellé du compte",
      secondInput: true,
      label: "IBAN",
      placeholder:
        country === "BE"
          ? "BE71 0961 2345 6769"
          : "FR763 0001 0079 4123 4567 8901 85",
    },
    {
      id: PaymentMethod.PAYMENT_LINK,
      name: "Lien de paiement",
      placeholderAccountWording: "Fournisseur de paiement",
      secondInput: true,
      label: "Lien",
      placeholder: "https://votrefournisseur.com/votrelien",
    },
    {
      id: PaymentMethod.CHEQUE,
      name: "Chèque",
      placeholderAccountWording: "Chèque",
      secondInput: true,
      label: "À l'ordre de",
      placeholder: "John Doe",
    },
    {
      id: PaymentMethod.CASH,
      name: "Espèces",
      placeholderAccountWording: "Espèces",
      secondInput: false,
    },
  ];

  function informationPayload() {
    const isMain = accounts.length === 0;
    const title = accountWording;
    const paymentMethod = selectedPayment.id;

    let payload = {
      isMain,
      paymentInformation: {
        title,
        paymentInformationDetails: {
          paymentMethod,
          // ...iban,
          // ...inFavourOf,
          // ...paymentLink,
        },
      },
    };

    switch (paymentMethod) {
      case PaymentMethod.IBAN:
        payload.paymentInformation.paymentInformationDetails.iban =
          secondInputValue?.replace(/\s/g, "");
        break;
      case PaymentMethod.CHEQUE:
        payload.paymentInformation.paymentInformationDetails.inFavourOf =
          secondInputValue;
        break;
      case PaymentMethod.PAYMENT_LINK:
        payload.paymentInformation.paymentInformationDetails.url =
          secondInputValue;
        break;
      case PaymentMethod.CASH:
      default:
        break;
    }

    return payload;
  }

  function AnnulerMoyenDePaiement() {
    setAccountWording("");
    setSecondInputValue("");
    props.setIsAccountFormDisplayed(false);
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="z-50">
        <div
          className="bg-black opacity-50 absolute top-0 left-0 w-screen h-screen"
          onClick={() => props.setIsAccountFormDisplayed(false)}
        ></div>
        <div className="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-12 bg-white rounded-2xl w-[28rem]">
          <h1 className="text-[2rem] mb-8 text-center">Ajouter un compte</h1>
          <div className="flex flex-col gap-4">
            <div>
              <Select
                data={paymentType}
                selectedItem={selectedPayment}
                setSelectedItem={setSelectedPayment}
                label="Moyen de paiement"
              />
            </div>
            {selectedPayment.isInputHidden ? null : (
              <InputTailwind
                label="Intitulé personnel"
                type="text"
                placeholder={selectedPayment.placeholderAccountWording}
                value={accountWording}
                onChange={(event) => setAccountWording(event.target.value)}
                isRequired={true}
                icon={false}
              />
            )}
            {selectedPayment.secondInput ? (
              <InputTailwind
                label={selectedPayment.label}
                type="text"
                placeholder={selectedPayment.placeholder}
                value={secondInputValue}
                onChange={(event) => setSecondInputValue(event.target.value)}
                isRequired={true}
                icon={false}
              />
            ) : null}
          </div>

          <div className="flex w-full gap-4 mt-8">
            {createPaymentInformation.isLoading ? (
              <LoadingBlock message="Création du compte en cours..." />
            ) : (
              <>
                <ButtonTailwind
                  onClick={() => AnnulerMoyenDePaiement()}
                  variant={"outlined2"}
                  style="w-full"
                >
                  Annuler
                </ButtonTailwind>
                <ButtonTailwind
                  onClick={(e) => {
                    e.preventDefault();
                    createPaymentInformation.mutate({
                      stakeholderId: enterpriseId,
                      paymentInformation: informationPayload(),
                    });
                  }}
                  loading={createPaymentInformation.isLoading}
                  style="w-full"
                >
                  Enregistrer
                </ButtonTailwind>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
