import React from "react";
import ButtonTailwind from "../token/button/buttonTailwind";
import DraftCreationModal from "../token/DraftCreationModal/DraftCreationModal";
import RequireSubscriptionButton from "../token/button/RequireSubscriptionButton";

export default function StepFormDocuments({
  docType,
  numberOfSteps,
  currentStep,
  setStepBack,
  TextSubmit,
  children,
  onSubmit,
  createDraft,
  setDisplayLeavingPopUp,
  setParametersOpen,
  draftDisplay,
  isDocumentCreationLoading,
}) {
  const [isDraftModalDisplayed, setIsDraftModalDisplayed] =
    React.useState(false);

  return (
    <>
      {isDraftModalDisplayed && (
        <DraftCreationModal
          onClick={() => createDraft()}
          isLoading={isDocumentCreationLoading}
          setIsDraftModalDisplayed={setIsDraftModalDisplayed}
        />
      )}
      <div className="w-full xl:w-[512px] flex flex-col justify-between items-center h-screen">
        <div className="w-[512px] h-full flex flex-col gap-8 p-8 overflow-y-auto">
          <div className="flex justify-between items-center w-full">
            <h1 className="text-black text-3xl font-semibold">{docType}</h1>
            <div className="flex gap-4 h-6">
              <svg
                fill="none"
                strokeWidth={2}
                stroke="#8C8C8C"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="cursor-pointer"
                onClick={() => setParametersOpen(true)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <svg
                fill="none"
                strokeWidth={2}
                stroke="#8C8C8C"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="cursor-pointer"
                // onClick={() => navigate("/invoices")}
                onClick={() => setDisplayLeavingPopUp(true)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="flex gap-6 w-full">
            {[...Array(numberOfSteps)].map((_, index) => (
              <div
                key={index}
                className={`w-full h-1 rounded-full ${
                  currentStep >= index ? "bg-black" : "bg-neutral-300"
                }`}
              ></div>
            ))}
          </div>

          <div className="flex flex-col justify-start w-full">{children}</div>
        </div>

        <div className="relative w-full">
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 w-[512px] h-16 bg-gradient-to-t from-white to-transparent pointer-events-none xl:left-auto xl:transform-none xl:translate-x-0"></div>
          <div className="mx-auto w-[512px] flex justify-end gap-2 bg-white items-end self-stretch h-max px-8 pb-8 ">
            {currentStep != 0 && (
              <div className="mr-auto">
                <ButtonTailwind
                  onClick={() => {
                    setStepBack();
                  }}
                  variant={"text"}
                >
                  ← Retour
                </ButtonTailwind>
              </div>
            )}
            <div className="flex gap-2 items-end">
              {draftDisplay && (
                <RequireSubscriptionButton
                  variant={"outlined2"}
                  onClick={() => setIsDraftModalDisplayed(true)}
                >
                  Enregistrer brouillon
                </RequireSubscriptionButton>
              )}
              <RequireSubscriptionButton
                onClick={() => {
                  onSubmit();
                }}
                variant={"filled"}
                style="w-max"
                loading={isDocumentCreationLoading}
              >
                {currentStep + 1 === numberOfSteps ? TextSubmit : "Suivant"}
              </RequireSubscriptionButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
