import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "@/assets/css/SignupForm.css";
import LidapLogo from "@/components/token/logo-lidap-horizontal";
import { Email } from "./stakeholder/contact/Email";
import { Name } from "./User/Name";
import { Password } from "./User/Password";
import ButtonTailwind from "../token/button/buttonTailwind";
import { useOrySignup } from "@/hooks/useOrySignup";
import { useOryAuth } from "@/contexts/OryAuthContext";

export default function OrySignupForm() {
  const location = useLocation();
  const auth = useOryAuth();

  const signup = useOrySignup(
    ["ory-session"],
    [["users", "info"]],
    (oryResponse) => {
      const verificationAction = oryResponse?.data?.continue_with?.find(
        (step) => step.action === "show_verification_ui"
      );
      const verificationFlowId = verificationAction?.flow?.id;
      auth.setEmailVerificationFlowId(verificationFlowId);
    },
    () => {
      setPassword((prevState) => ({
        ...prevState,
        isDirty: false,
      }));
    }
  );

  // useEffect(() => {
  //   if (verificationFlowId) {
  //     navigate(`/mail/verification?flow=${verificationFlowId}`, {
  //       state: { email: mail.data, flowId: verificationFlowId },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [verificationFlowId]);

  const emptySimpleField = { success: false, data: "", isDirty: false };
  const [firstName, setFirstName] = useState(emptySimpleField);
  const [lastName, setLastName] = useState(emptySimpleField);
  const [mail, setMail] = useState({
    ...emptySimpleField,
    ...location.state?.email,
  });
  const [password, setPassword] = useState({
    ...emptySimpleField,
    ...location.state?.password,
  });
  const [termsCheckBox, setCheckBox] = useState(false);
  const [checkBoxFocus, setCheckBoxFocus] = useState(false);

  const isValid = (() => {
    if (
      password.success &&
      firstName.success &&
      lastName.success &&
      mail.success &&
      termsCheckBox === true
    ) {
      return true;
    } else {
      return false;
    }
  })();

  const uppercase = new RegExp(/[A-Z]/).test(password.data);
  const lowercase = new RegExp(/[a-z]/).test(password.data);
  const number = new RegExp(/[0-9]/).test(password.data);
  const specialChar = new RegExp(/(.*[!@#{:$%^=&*()\][\-__+.])/).test(
    password.data
  );
  const inputLength = password.data.length >= 12;

  async function SignUp() {
    const signUpData = {
      email: mail.data,
      password: password.data,
      firstName: firstName.data,
      lastName: lastName.data,
      commonName: firstName.data + " " + lastName.data,
      hasAcceptedTOS: termsCheckBox,
    };

    auth.setIsSignup(true);
    signup.mutate(signUpData);
  }

  const handleKeyDown = (event) => {
    // Vérifie si la touche pressée est Enter
    if (event.key === "Enter") {
      setCheckBox(!termsCheckBox); // Inverse l'état de la checkbox
    }
  };

  const passwordError = (() => {
    /* If the password modification was rejected and the user has not yet provided another input, we display the error*/
    if (signup.isError && !password.isDirty) {
      if (signup.error?.message) {
        return signup.error.message;
      }
    }

    return null;
  })();

  return (
    <div className="flex flex-row w-screen">
      <div className="flex flex-col w-full h-screen">
        <div className="flex">
          <div className="wrap-menu-logo">
            <Link to="/" style={{ cursor: "auto" }} className="wrap-logo-lidap">
              <LidapLogo
                alt="logo de Lidap"
                className="menu-logo-lidap"
                color="var(--shades-900)"
                maxWidth="6rem"
                height="100%"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </div>
        </div>

        <div className="flex justify-center items-center flex-col m-auto w-96 gap-8 h-full pb-16">
          <div id="getStartedSignUp">Inscrivez-vous</div>
          <div className="flex flex-col gap-4">
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => {
                e.preventDefault();
                if (isValid === true) {
                  SignUp();
                }
              }}
            >
              <Email
                name="email"
                label="Email"
                onChange={setMail}
                required={mail.isDirty}
                initialEmail={mail.data}
              />
              <div id="NamesBox">
                {" "}
                <Name
                  name="firstName"
                  label="Prénom"
                  onChange={setFirstName}
                  required={firstName.isDirty}
                  initialName={firstName.data}
                  id="firstName"
                />
                <Name
                  name="lastName"
                  label="Nom"
                  onChange={setLastName}
                  required={lastName.isDirty}
                  initialName={lastName.data}
                  id="lastName"
                />
              </div>

              <div>
                <Password
                  initialPassword={password.data}
                  onChange={setPassword}
                  required={password.isDirty}
                  id="password"
                  name="password"
                  displayError={signup.isError && !password.isDirty}
                  label="Mot de passe"
                  externalError={passwordError}
                />
                <div className="select-none" id="PopUpPassword">
                  <ul id="PopUpPasswordTextBox">
                    <li
                      style={
                        uppercase === true
                          ? { textDecoration: "line-through", color: "#BBBBCA" }
                          : { textDecoration: "none", color: "#000000" }
                      }
                      id="uppercase"
                    >
                      1 Majuscule
                    </li>
                    <li
                      style={
                        lowercase === true
                          ? { textDecoration: "line-through", color: "#BBBBCA" }
                          : { textDecoration: "none", color: "#000000" }
                      }
                      id="lowercase"
                    >
                      1 Minuscule
                    </li>
                    <li
                      style={
                        number === true
                          ? { textDecoration: "line-through", color: "#BBBBCA" }
                          : { textDecoration: "none", color: "#000000" }
                      }
                      id="number"
                    >
                      1 Nombre
                    </li>
                    <li
                      style={
                        specialChar === true
                          ? { textDecoration: "line-through", color: "#BBBBCA" }
                          : { textDecoration: "none", color: "#000000" }
                      }
                      id="specialChar"
                    >
                      1 caractère spécial{" "}
                    </li>
                    <li
                      style={
                        inputLength === true
                          ? { textDecoration: "line-through", color: "#BBBBCA" }
                          : { textDecoration: "none", color: "#000000" }
                      }
                      id="charactersNumber"
                    >
                      12+ caractères
                    </li>
                  </ul>
                </div>
              </div>

              <div id="TermsBox">
                <input
                  type="checkbox"
                  id="TermsCheckBox"
                  // className="outline-0 focus:outline-0 focus:ring-0 focus:ring-offset-0 border-2 checked:border-neutral-600 border-neutral-600 "
                  // className="border-2 border-gray-300"
                  className="hidden"
                  checked={termsCheckBox}
                  onChange={() => {
                    setCheckBox(!termsCheckBox);
                  }}
                ></input>

                <label
                  className="m-0 text-xs text-gray-400 flex gap-1 justify-center items-center"
                  htmlFor="TermsCheckBox"
                >
                  <div
                    tabIndex={0}
                    onFocus={() => setCheckBoxFocus(true)}
                    onBlur={() => setCheckBoxFocus(false)}
                    onKeyDown={handleKeyDown}
                    id="TermsCheckBoxBox"
                    className={`border-neutral-600 ${
                      checkBoxFocus && "border-neutral-800 border-3"
                    } p-1 mr-1 rounded-sm border-2 flex justify-center items-center w-max h-max`}
                  >
                    <div
                      className={`w-2 h-2 rounded-sm ${
                        termsCheckBox ? "bg-neutral-600 " : ""
                      }`}
                    ></div>
                  </div>
                  J&apos;accepte les{" "}
                  <Link
                    to="https://www.lidap.be/legals/cgu"
                    target="_blank"
                    className="TermsConditionsPrivacy"
                  >
                    CGU{" "}
                  </Link>
                  &{" "}
                  <Link
                    to="https://www.lidap.be/legals/confidentiality"
                    target="_blank"
                    className="TermsConditionsPrivacy"
                  >
                    Politique De Confidentialité.
                  </Link>
                </label>
              </div>

              <div className="flex flex-col w-full items-stretch gap-2">
                <ButtonTailwind
                  id="SubmitFormulaire"
                  type="submit"
                  className={`${
                    isValid === true ? "cursor-pointer" : "cursor-default"
                  }`}
                  disabled={!isValid}
                  loading={signup.isLoading}
                >
                  S&apos;inscrire
                </ButtonTailwind>
                <div id="HaveAccount">
                  <div id="ShadowAccount">Vous avez déjà un compte ?</div>

                  <Link
                    to="/login"
                    state={{ email: mail, password: password }}
                    id="LogIn"
                  >
                    Connectez-vous
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="LateralBlock"></div>
    </div>
  );
}
