import React, { useEffect, useState } from "react";
import StepFormDocuments from "@/components/elements/StepFormDocuments";
import ServiceForm from "@/components/elements/ServiceForm/ServiceForm";
import Box from "@mui/material/Box";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import toast from "react-hot-toast";
import Notification from "@/components/token/toaster/notification";
import LastStepEstimateForm from "@/feature/LastStepEstimateForm/LastStepEstimateForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "@/contexts/UserContext";
import { useClients } from "@/hooks/useClients";
import DocumentCreationModal from "@/components/token/documentCreationModal/DocumentCreationModal";
import SearchSelect from "@/components/token/select/SearchSelect";
import { useArticles } from "@/hooks/useArticles";
import { CRUDL } from "@/utils/crudl";
import { usePaymentInformation } from "@/hooks/usePaymentInformation";
import { useInvoices } from "@/hooks/useInvoices";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function EstimateForm(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const clientId = searchParams.get("clientId");

  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  const invoiceNumberToDuplicate = searchParams.get("preload");

  const {
    invoicesData: invoiceToDuplicate,
    isInvoicesLoading: isPreloadLoading,
  } = useInvoices({
    enterpriseId,
    invoiceNumber: invoiceNumberToDuplicate,
  });

  const { clientsData, isClientsLoading } = useClients({
    enterpriseId,
    queryParam: {
      emails: true,
      addresses: true,
    },
  });

  const articlesData = useArticles({ enterpriseId });

  const clients = clientsData ? clientsData.payload : undefined;
  const articles = articlesData ? articlesData?.data?.payload : undefined;

  const paymentInformation = usePaymentInformation({
    stakeholderId: enterpriseId,
  });

  const PaymentMethod = {
    IBAN: "IBAN",
    PAYMENT_LINK: "PAYMENT_LINK",
    CHEQUE: "CHEQUE",
    CASH: "CASH",
  };

  const clientSelected = clientsData
    ? clientsData.payload.find((c) => c.id === clientId)
    : undefined;

  function handleAccountsToCredit(accountToAdd) {
    const updatedData = props?.documentData?.data?.additionalData.filter(
      (data) => data?.additionalDataType !== "PAYMENT_METHOD"
    );

    accountToAdd?.forEach((account) => {
      updatedData.push({
        additionalDataType: "PAYMENT_METHOD",
        additionalDataSubType:
          account.additionalDataSubType || account.paymentMethod,
        title: account.title,
        additionalData: account.additionalData
          ? account.additionalData
          : account.paymentMethod === "IBAN"
          ? account.iban
          : account.paymentMethod === "BIC"
          ? account.bic
          : account.paymentMethod === "PAYMENT_LINK"
          ? account.url
          : account.paymentMethod === "CHEQUE"
          ? account.inFavourOf
          : account.paymentMethod === "CASH"
          ? account.cash
          : undefined,
        externalId: account.externalId || account.id,
        // id: account.externalId || account.id,
      });
    });

    props?.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        additionalData: updatedData,
      },
    });
  }

  useEffect(() => {
    if (isClientsLoading) return;
    const updatedData = props?.documentData?.data?.additionalData?.map(
      (data) => {
        if (data?.additionalDataType === "RECEIVER_NAME") {
          return { ...data, additionalData: clientSelected?.name };
        }
        return data;
      }
    );
    // setAddData(updatedData);
    props?.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        additionalData: updatedData,
      },
    });
  }, [isClientsLoading]);

  const accounts = (() => {
    if (paymentInformation.isLoading || paymentInformation.isError) {
      return [];
    }

    return paymentInformation.data?.payload?.stakeholderPaymentInformation.map(
      (p) => {
        const method = p.paymentInformationDetails.paymentMethod;
        return {
          id: p.id,
          name: p.title,
          title: p.title,
          paymentMethod: p.paymentInformationDetails.paymentMethod,
          iban:
            method === PaymentMethod.IBAN
              ? p.paymentInformationDetails.iban?.replace(/\s/g, "")
              : undefined,
          inFavourOf:
            method === PaymentMethod.CHEQUE
              ? p.paymentInformationDetails.inFavourOf
              : undefined,
          url:
            method === PaymentMethod.PAYMENT_LINK
              ? p.paymentInformationDetails.url
              : undefined,
          cash: method === PaymentMethod.CASH ? p.title : undefined,
        };
      }
    );
  })();

  const onClientSelect = (client) => {
    searchParams.set("clientId", client.id);
    setSearchParams(searchParams);

    const newAddData = props?.documentData?.data?.additionalData?.map(
      (data) => {
        if (data?.additionalDataType === "RECEIVER_NAME") {
          return {
            ...data,
            additionalData: clientsData.payload.find((c) => c.id == client.id)
              .name,
          };
        }
        return data;
      }
    );

    props?.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        additionalData: newAddData,
        step: 1,
      },
    });
  };

  const onServiceSelect = (service) => {
    const serviceStructure = {
      title: service.title,
      quantity: {
        amount: service.quantity.amount,
        unit: service.quantity.unit,
      },
      unitPrice: {
        amount: service.unitPrice.amount,
        currency: service.unitPrice.currency,
      },
      description: service.description,
      vatRate: {
        taxRate: service.vatRate.taxRate,
        countryOrRegion: service.vatRate.countryOrRegion,
      },
      isDisplayed: false,
      id: service.id,
      TemplateId: service.id,
    };
    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        services: [...props.documentData.data.services, serviceStructure],
      },
    });
    setServicesId((currentId) => currentId + 1);
  };

  const [displayLeavingPopUp, setDisplayLeavingPopUp] = useState(false);
  const [servicesIsOk, setServicesIsOk] = useState(false);
  const [servicesId, setServicesId] = useState(0);
  const [currency, setCurrency] = useState("EUR");

  // Function to format a Date object to mm/dd/yyyy
  function formatDate(monthsToAdd = 0) {
    const dateObj = new Date();
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1 + monthsToAdd)
      .toString()
      .padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const [isAccountsSet, setIsAccountsSet] = useState(false);

  useEffect(() => {
    if (accounts?.length > 0) {
      const updatedAccountsToCredit = accounts.filter(
        (account) =>
          !props?.documentData?.data?.accountsToCredit?.some(
            (acc) => acc.id === account.id
          )
      );

      if (
        updatedAccountsToCredit?.length > 0 &&
        !isAccountsSet &&
        props?.documentData?.data?.additionalData?.filter(
          (a) => a.additionalDataType === "PAYMENT_METHOD"
        )?.length === 0
      ) {
        handleAccountsToCredit(updatedAccountsToCredit);

        setIsAccountsSet(true);
      } else if (
        props?.documentData?.data?.accountsToCredit?.length > 0 &&
        !isAccountsSet
      ) {
        setIsAccountsSet(true);
      }
    }
  }, [accounts]);

  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  function toNormalDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (
      props.context === CRUDL.UPDATE &&
      props?.documentData?.data?.selectedClient?.id == 0
    ) {
      if (clients && clients[0]) {
        onClientSelect(
          clients.find((client) => client.id == props.invoiceContent.clientId)
        );
      }
      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          step: 1,
          purchaseOrder: props.invoiceContent.purchaseOrder,
          // conditionsMentions: props?.invoiceContent?.additionalData.find(
          //   (data) => data.additionalDataType === "INVOICE_CONDITIONS"
          // )?.additionalData,
          additionalData: props?.documentData?.data?.additionalData.map(
            (data) => {
              if (data?.additionalDataType === "INVOICE_CONDITIONS") {
                return {
                  ...data,
                  additionalData: props?.invoiceContent?.additionalData.find(
                    (data) => data.additionalDataType === "INVOICE_CONDITIONS"
                  )?.additionalData,
                };
              }
              return data;
            }
          ),
        },
      });

      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          services: props?.invoiceContent?.services,
        },
      });
    }

    const totalPrice =
      props?.documentData?.services?.reduce(
        (acc, service) =>
          acc + service?.unitPrice?.amount * service?.quantity?.amount,
        0
      ) || 0;
    const totalTVAPrice =
      props?.documentData?.services?.reduce(
        (acc, service) =>
          acc +
          service?.unitPrice?.amount *
            service?.quantity?.amount *
            service?.vatRate?.taxRate,
        0
      ) || 0;

    const totalRounded = totalPrice + totalTVAPrice;
    if (!invoiceNumberToDuplicate) {
      props.setDocumentData({
        data: {
          step: props?.documentData?.data?.step || (clientId ? 1 : 0),
          Logo: props?.documentData?.data?.Logo,
          SIREN: props?.siren,
          enterpriseNumber: props?.enterpriseNumber,
          clientId: clientId,
          issuingDate: props?.documentData?.data?.issuingDate || formatDate(0),
          dueDate: props?.documentData?.data?.dueDate || formatDate(1),
          purchaseOrder: props?.documentData?.data?.purchaseOrder,
          services: props?.documentData?.data?.services || [],
          addresses: [
            {
              addressId:
                clients
                  ?.find((client) => client.id == clientId)
                  ?.addresses?.find((address) => address.isMain === true).id ||
                {
                  id: 0,
                  name: "",
                }?.addresses?.find((address) => address.isMain === true).id,
              address:
                clients
                  ?.find((client) => client.id == clientId)
                  ?.addresses?.find((address) => address.isMain === true) ||
                {
                  id: 0,
                  name: "",
                }?.addresses?.find((address) => address.isMain === true),
              addressType: "RECEIVER_BILLING",
            },
            {
              addressId: props?.enterpriseInformation?.addresses?.find(
                (address) => address.isMain === true
              ).id,
              address: props?.enterpriseInformation?.addresses?.find(
                (address) => address.isMain === true
              ),
              addressType: "ISSUER_BILLING",
            },
          ],
          additionalData: props?.documentData?.data?.additionalData || [
            {
              // additionalData: props?.documentData?.data?.conditionsMentions,
              additionalData:
                props?.documentData?.data?.additionalData?.find(
                  (data) => data.additionalDataType === "INVOICE_CONDITIONS"
                )?.additionalData || "",
              additionalDataType: "INVOICE_CONDITIONS",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: "",
              additionalDataType: "VAT_DISCLAIMER",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: clientSelected?.name,
              additionalDataType: "RECEIVER_NAME",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: props?.enterpriseInformation?.name,
              additionalDataType: "ISSUER_NAME",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: props?.invoiceEmail,
              additionalDataType: "ISSUER_EMAIL",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: props?.invoicePhone,
              additionalDataType: "ISSUER_PHONE",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: "",
              additionalDataType: "DOCUMENT_BODY_COLOR",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: "",
              additionalDataType: "DOCUMENT_TITLE_COLOR",
              additionalDataSubType: "NOT_APPLICABLE",
            },
          ],
          selectedClient: clients?.find((client) => client.id == clientId) || {
            id: 0,
            name: "",
          },
          documentType: "QUOTE",
          total: {
            amount: totalPrice,
            currency: currency,
          },
          totalWithTax: {
            amount: totalTVAPrice,
            currency: currency,
          },
          totalRounded: {
            amount: totalRounded,
            currency: currency,
          },
          issuerMail: props?.documentData?.data?.issuerMail || {
            isShown: true,
            data: props?.invoiceEmail,
          },
          issuerPhone: props?.documentData?.data?.issuerPhone || {
            isShown: true,
            data: props?.invoicePhone,
          },
          // conditionsMentions:
          //   props?.documentData?.data?.conditionsMentions ||
          //   props?.documentData?.data?.additionalData?.find(
          //     (data) => data?.additionalDataType === "INVOICE_CONDITIONS"
          //   )?.additionalData ||
          //   "",
        },
        enterpriseId,
      });
    } else if (!isPreloadLoading) {
      const invoice = invoiceToDuplicate.payload.invoice;
      props.setDocumentData({
        data: {
          step:
            props?.documentData?.data?.step ||
            (clientId || invoice.clientId ? 1 : 0),
          Logo: props?.documentData?.data?.Logo,
          SIREN: props?.siren,
          enterpriseNumber: props?.enterpriseNumber,
          clientId: clientId || invoice.clientId,
          issuingDate:
            props?.documentData?.data?.issuingDate ||
            toNormalDate(invoice.issuingDate),
          dueDate:
            props?.documentData?.data?.dueDate || toNormalDate(invoice.dueDate),
          purchaseOrder:
            props?.documentData?.data?.purchaseOrder || invoice.purchaseOrder,
          services: props?.documentData?.data?.services || invoice.services,
          addresses: [
            {
              addressId: props?.documentData?.data?.clientId
                ? clients
                    ?.find(
                      (client) =>
                        client.id == props?.documentData?.data?.clientId
                    )
                    ?.addresses?.find((address) => address.isMain === true).id
                : clients
                    ?.find((client) => client.id == invoice.clientId)
                    ?.addresses?.find((address) => address.isMain === true).id,
              address: props?.documentData?.data?.clientId
                ? clients
                    ?.find(
                      (client) =>
                        client.id == props?.documentData?.data?.clientId
                    )
                    ?.addresses?.find((address) => address.isMain === true)
                : clients
                    ?.find((client) => client.id == invoice.clientId)
                    ?.addresses?.find((address) => address.isMain === true),
              addressType: "RECEIVER_BILLING",
            },
            {
              addressId: props?.enterpriseInformation?.addresses?.find(
                (address) => address.isMain === true
              ).id,
              address: props?.enterpriseInformation?.addresses?.find(
                (address) => address.isMain === true
              ),
              addressType: "ISSUER_BILLING",
            },
          ],
          additionalData: props?.documentData?.data?.additionalData || [
            {
              additionalData: invoice?.additionalData?.find(
                (data) => data?.additionalDataType === "INVOICE_CONDITIONS"
              )?.additionalData,
              additionalDataType: "INVOICE_CONDITIONS",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: invoice?.additionalData?.find(
                (data) => data?.additionalDataType === "RECEIVER_NAME"
              )?.additionalData,
              additionalDataType: "RECEIVER_NAME",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: invoice?.additionalData?.find(
                (data) => data?.additionalDataType === "ISSUER_NAME"
              )?.additionalData,
              additionalDataType: "ISSUER_NAME",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: props?.invoiceEmail,
              additionalDataType: "ISSUER_EMAIL",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: props?.invoicePhone,
              additionalDataType: "ISSUER_PHONE",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: "",
              additionalDataType: "DOCUMENT_BODY_COLOR",
              additionalDataSubType: "NOT_APPLICABLE",
            },
            {
              additionalData: "",
              additionalDataType: "DOCUMENT_TITLE_COLOR",
              additionalDataSubType: "NOT_APPLICABLE",
            },
          ],
          selectedClient: clients?.find(
            (client) => client.id == props?.documentData?.data?.clientId
          ) ||
            clients?.find((client) => client.id == invoice.clientId) || {
              id: 0,
              name: "",
            },
          documentType: "QUOTE",
          total: {
            amount: totalPrice,
            currency: currency,
          },
          totalWithTax: {
            amount: totalTVAPrice,
            currency: currency,
          },
          totalRounded: {
            amount: totalRounded,
            currency: currency,
          },
          issuerMail: props?.documentData?.data?.issuerMail || {
            isShown: true,
            data: props?.invoiceEmail,
          },
          issuerPhone: props?.documentData?.data?.issuerPhone || {
            isShown: true,
            data: props?.invoicePhone,
          },
        },
        enterpriseId,
      });
    }
  }, [clientId, currency, enterpriseId, clients, props?.invoiceStyle]);

  const navigate = useNavigate();

  function addService() {
    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        services: [
          ...props.documentData.data.services,
          { id: servicesId, isDisplayed: true },
        ],
      },
    });
    setServicesId((currentId) => currentId + 1);
    setServicesIsOk(false);
  }

  function duplicateService(serviceId) {
    // Trouver le service à dupliquer
    const serviceToDuplicate = props?.documentData?.data?.services.find(
      (service) => service.id === serviceId
    );

    // Créer une copie du service avec un nouvel ID
    const duplicatedService = { ...serviceToDuplicate, id: servicesId };

    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        services: [
          ...props.documentData.data.services,
          {
            id: servicesId + 1,
            title: duplicatedService.title,
            quantity: {
              amount: duplicatedService.quantity.amount,
              unit: duplicatedService.quantity.unit,
            },
            unitPrice: {
              amount: duplicatedService.unitPrice.amount,
              currency: duplicatedService.unitPrice.currency,
            },
            description: duplicatedService.description,
            vatRate: {
              taxRate: duplicatedService.vatRate.taxRate,
              countryOrRegion: duplicatedService.vatRate.countryOrRegion,
            },
          },
        ],
      },
    });

    setServicesId((currentId) => currentId + 1);
    setServicesIsOk(false);
  }

  function manageServices(id, data) {
    const updatedServices = props?.documentData?.data?.services?.map(
      (service) => {
        if (service.id === id) {
          return { ...service, ...data };
        }
        return service;
      }
    );

    // setServices(updatedServices);
    props.setDocumentData({
      ...props.documentData,
      data: { ...props.documentData.data, services: updatedServices },
    });
  }

  function step1() {
    if (props?.documentData?.data?.selectedClient.id === 0) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Vous devez sélectionner un client pour pouvoir continuer"
          info={null}
        />
      ));
    } else {
      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          step: 1,
        },
      });
    }
  }

  function step2() {
    let allServicesValid = true; // Assume all services are valid initially

    props?.documentData?.data?.services?.forEach((service) => {
      const isTitleError = service.title === "";
      const isQuantityError = service.quantity.amount === null; // or other appropriate checks for a number
      const isPriceError = service.unitPrice.amount === "";
      const isVATError =
        service.vatRate.taxRate === "" || service.vatRate.taxRate === "Autre";

      const isValid = !(
        isTitleError ||
        isQuantityError ||
        isPriceError ||
        isVATError
      );

      // If any service is invalid, mark allServicesValid as false
      if (!isValid) {
        allServicesValid = false;
      }
    });

    if (!allServicesValid) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Veuillez valider tous vos service avant de continuer"
          info={null}
        />
      ));
    } else if (props?.documentData?.data?.services.length === 0) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Vous devez ajouter au moins un service pour continuer"
          info={null}
        />
      ));
    } else if (props?.documentData?.data?.selectedClient.id === 0) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Vous devez sélectionner un client pour pouvoir continuer"
          info={null}
        />
      ));
    } else {
      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          step: 2,
        },
      });
    }
  }

  const submitFunctions = [step1, step2, () => setIsCreationModalOpen(true)];

  function deleteService(serviceId) {
    // Filtrer le tableau pour exclure le service avec l'ID spécifié
    const updatedServices = props?.documentData?.data?.services.filter(
      (service) => service.id !== serviceId
    );

    // Mettre à jour l'état avec le nouveau tableau
    props.setDocumentData({
      ...props.documentData,
      data: { ...props.documentData.data, services: updatedServices },
    });
  }

  return (
    <>
      {isCreationModalOpen && (
        <DocumentCreationModal
          createDocument={() => props.createInvoice("CREATED")}
          unDisplay={() => setIsCreationModalOpen(false)}
          isLoading={props.isDocumentCreationLoading}
        />
      )}

      {displayLeavingPopUp && (
        <div className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center bg-[rgba(0,0,0,0.5)] backdrop-blur-sm z-50">
          <div className="rounded-2xl bg-white flex w-[32rem] py-12 px-8 flex-col items-start gap-8">
            <h1 className="text-black text-4xl font-semibold">
              Êtes-vous sûr de vouloir quitter ?
            </h1>
            <div className="flex gap-4">
              <ButtonTailwind
                variant="outlined2"
                width="100%"
                onClick={() => setDisplayLeavingPopUp(false)}
              >
                Non
              </ButtonTailwind>
              <ButtonTailwind
                variant="filled"
                width="100%"
                onClick={() => {
                  navigate("/invoices"), props?.setDocumentData({});
                }}
              >
                Oui
              </ButtonTailwind>
            </div>
          </div>
        </div>
      )}
      <StepFormDocuments
        docType="Devis"
        setStep={(step) => {
          props.setDocumentData({
            ...props.documentData,
            data: {
              ...props.documentData.data,
              step: step,
            },
          });
        }}
        numberOfSteps={3}
        currentStep={props?.documentData?.data?.step}
        TextSubmit="Créer mon devis"
        onSubmit={submitFunctions[props?.documentData?.data?.step]}
        setStepBack={() => {
          props.setDocumentData({
            ...props.documentData,
            data: {
              ...props.documentData.data,
              step: props?.documentData?.data?.step - 1,
            },
          });
        }}
        setTab={props.setTab}
        createDraft={() => props.createInvoice("DRAFT")}
        setDisplayLeavingPopUp={setDisplayLeavingPopUp}
        setParametersOpen={props.setParametersOpen}
        draftDisplay={
          (props?.documentData?.data?.step != 0 &&
            props?.documentData?.data?.selectedClient?.id != 0) ||
          props?.documentData?.data?.step > 0
        }
        isDocumentCreationLoading={props.isDocumentCreationLoading}
      >
        {(props?.documentData?.data?.step === 0 ||
          !props?.documentData?.data?.step) && (
          <SearchSelect
            label="Client"
            selectedValue={props?.documentData?.data?.selectedClient}
            onSelect={onClientSelect}
            displayCreationElement={() => props.setClientCreationOpen(true)}
            creationElement="Ajouter un client"
            options={clients}
            placeholder="Sélectionnez un client"
            accessor="name"
          />
        )}

        {props?.documentData?.data?.step === 1 && (
          <Box className="flex flex-col w-full gap-4">
            <Box className="flex flex-col w-full gap-2">
              <SearchSelect
                label="Client"
                selectedValue={
                  props?.documentData?.data?.selectedClient ||
                  clients?.find((client) => client.id == clientId) || {
                    id: 0,
                    name: "",
                  }
                }
                onSelect={onClientSelect}
                displayCreationElement={() => props.setClientCreationOpen(true)}
                creationElement="Ajouter un client"
                options={clients}
                placeholder="Sélectionnez un client"
              />
              <SearchSelect
                label="Service"
                selectedValue={"services"}
                onSelect={onServiceSelect}
                displayCreationElement={() => addService()}
                creationElement="Ajouter un service"
                options={articles}
                placeholder="Sélectionnez un service"
                accessor="title"
              />
            </Box>
            <Box className="flex flex-col w-full gap-4">
              {props?.documentData?.data?.services?.map((service) => (
                <div key={service.id} className="w-full min-h-max">
                  <ServiceForm
                    manageServices={manageServices}
                    setCurrency={setCurrency}
                    currency={currency}
                    id={service.id}
                    setServicesIsOk={setServicesIsOk}
                    duplicateService={duplicateService}
                    deleteService={deleteService}
                    title={service.title}
                    quantityAmount={service?.quantity?.amount}
                    quantityUnit={service?.quantity?.unit}
                    priceAmount={service?.unitPrice?.amount}
                    priceCurrency={service?.price?.currency}
                    description={service.description}
                    VATRate={service?.vatRate?.taxRate}
                    VATCountry={service?.vatRate?.countryOrRegion}
                    isDisplayed={service?.isDisplayed}
                    TemplateId={service?.TemplateId}
                  />
                </div>
              ))}{" "}
              <ButtonTailwind variant="text" onClick={() => addService()}>
                <PlusIcon class="h-6 w-6 text-neutral-700" />
                Ajouter un service
              </ButtonTailwind>
            </Box>
          </Box>
        )}

        {props?.documentData?.data?.step === 2 && (
          <LastStepEstimateForm
            paymentInformation={paymentInformation}
            accounts={accounts}
            issuingDate={props?.documentData?.data?.issuingDate}
            dueDate={props?.documentData?.data?.dueDate}
            setIssuingDate={(e) => {
              props.setDocumentData({
                ...props.documentData,
                data: {
                  ...props.documentData.data,
                  issuingDate: e,
                },
              });
            }}
            setDueDate={(e) => {
              props.setDocumentData({
                ...props.documentData,
                data: {
                  ...props.documentData.data,
                  dueDate: e,
                },
              });
            }}
            PurchaseOrderNumber={props.documentData.data.purchaseOrder}
            setPurchaseOrderNumber={(e) => {
              props.setDocumentData({
                ...props.documentData,
                data: {
                  ...props.documentData.data,
                  purchaseOrder: e,
                },
              });
            }}
            accountsToCredit={props?.documentData?.data?.additionalData.filter(
              (data) => data?.additionalDataType === "PAYMENT_METHOD"
            )}
            setAccountsToCredit={(data) => {
              handleAccountsToCredit(data);
            }}
            conditionsMentions={
              props.documentData.data.additionalData.find(
                (data) => data.additionalDataType === "INVOICE_CONDITIONS"
              )?.additionalData || ""
            }
            setConditionsMentions={(e) => {
              // props.setDocumentData({
              //   ...props.documentData,
              //   data: {
              //     ...props.documentData.data,
              //     conditionsMentions: e,
              //   },
              // });
              props?.setDocumentData({
                ...props.documentData,
                data: {
                  ...props.documentData.data,
                  additionalData: props?.documentData?.data?.additionalData.map(
                    (data) => {
                      if (data?.additionalDataType === "INVOICE_CONDITIONS") {
                        return { ...data, additionalData: e };
                      }
                      return data;
                    }
                  ),
                },
              });
            }}
            VATMentions={
              props?.documentData?.data?.additionalData.find(
                (data) => data?.additionalDataType === "VAT_DISCLAIMER"
              )?.additionalData
            }
            setVATMentions={(e) => {
              const newAddData = props?.documentData?.data?.additionalData.map(
                (data) => {
                  if (data?.additionalDataType === "VAT_DISCLAIMER") {
                    return {
                      ...data,
                      additionalData: e,
                    };
                  }
                  return data;
                }
              );
              props?.setDocumentData({
                ...props?.documentData,
                data: {
                  ...props?.documentData?.data,
                  additionalData: newAddData,
                },
              });
            }}
            s
          />
        )}
      </StepFormDocuments>
    </>
  );
}
