import InputTailwind from "@/components/token/Input/inputTailwind";

export function CompanyName({
  initialCompanyName,
  disabled,
  onChange,
  isLoading,
  required,
  name = "companyName",
  label,
  displayError = true,
}) {
  const companyName = initialCompanyName;
  const error = (companyName) => {
    if (required && !companyName) {
      return {
        success: false,
        message: "Ce champ est requis",
      };
    } else if (companyName && companyName.length < 3) {
      return {
        success: false,
        message: "Le nom de l'entreprise doit contenir au moins 3 caractères",
      };
    } else {
      return {
        success: true,
        message: null,
      };
    }
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    onChange({
      success: error(value).success,
      data: value,
      isDirty: true,
    });
  };

  return (
    <InputTailwind
      label={label ? label : "Nom de l'entreprise"}
      type="text"
      placeholder="Votre société"
      value={companyName}
      onChange={(e) => handleCompanyNameChange(e)}
      loading={isLoading}
      disabled={disabled}
      error={displayError ? error(companyName).message : ""}
      isRequired={required}
      name={name}
      maxLength={255}
      minLength={3}
    ></InputTailwind>
  );
}
