import { useQuery } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function useEnterprises({
  enterpriseId,
  queryParam,
  reactQueryOptions,
}) {
  const enterpriseParams = enterpriseId ? `/${enterpriseId}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["enterprises", { enterpriseId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/enterprises${enterpriseParams}/${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load enterprises");
    },
    ...reactQueryOptions,
  });

  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
    error: error,
    refetch: refetch,
  };
}
