import { Fragment, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { ButtonCopyToClipboard } from "@/components/token/button/buttonCopyToClipboard";

export default function Notification({
  isSuccess = false,
  message,
  info,
  traceId,
  errorCode /* Idea is to map this in the future */,
  httpStatusCode,
}) {
  const [show, setShow] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const messageFromHttp = useMemo(() => {
    switch (httpStatusCode) {
      case 400:
        return "La requête est invalide";
      case 401:
        return "L'authentification a échoué";
      case 403:
        return "Vous n'êtes pas autorisé à effectuer cette action";
      case 404:
        return "La ressource n'existe pas";
      case 500:
        return "Une erreur interne est survenue";
      default:
        return "";
    }
  }, [httpStatusCode]);

  const messageFromBackend = useMemo(() => {
    //TODO: improve this (API on backend with error code mapping)
    if (!errorCode) return "";

    switch (errorCode) {
      case "SIGNUP0005":
        return "Erreur inconnue lors de l'inscription";
      case "LOGIN0005":
        return "Email ou mot de passe incorrect";
      case "AUTH0003":
        return "Vous n'êtes pas connecté.e";
      case "AUTH0006":
      case "AUTHEXTA01":
        return "Session expirée, veuillez vous reconnecter";
      case "AUTHEXTA02":
        return "Impossible de vérifier la session";
      case "AUTHEXTA03":
        return "Impossible de récupérer la session";
      case "AUTHEXT003":
        return "Code de vérification invalide ou expiré, veuillez réessayer";
      case "ARTS0001":
        return "Un service existe déjà avec ce titre";
      case "PAYS0002":
        return "Vous possédez déjà une souscription, veuillez utiliser le portail de facturation";
      default:
        return "Erreur inconnue";
    }
  }, [errorCode]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {isSuccess ? (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {messageFromHttp && `${messageFromHttp} - `}
                      {messageFromBackend && `${messageFromBackend}`}
                      {!messageFromBackend ? message : ""}
                    </p>
                    {showMore && traceId && (
                      <>
                        <p className="mt-1 text-sm text-gray-500">
                          Si le problème persiste, veuillez renseigner
                          l&apos;identifiant suivant au support de Lidap{" "}
                          <a
                            style={{ color: "#E16D64" }}
                            href={`mailto:support@lidap.io?subject=Lidap - Erreur ${traceId}${
                              errorCode ? ` [${errorCode}]` : undefined
                            }&body=Bonjour, %0D%0A%0D%0AJe rencontre actuellement un problème avec Lidap. %0D%0A%0D%0AJe vous transmets l'identifiant de l'erreur : ${traceId} %0D%0A%0D%0AMerci de votre aide. %0D%0A%0D%0ACordialement, %0D%0A%0D%0A`}
                          >
                            support@lidap.io
                          </a>
                          :
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <ButtonCopyToClipboard textToCopy={traceId} />{" "}
                          <b>{traceId}</b>
                        </p>
                      </>
                    )}
                    <p className="mt-1 text-sm text-gray-500">{info}</p>
                    {!showMore && traceId && (
                      <button
                        className="mt-1 text-sm text-gray-500"
                        onClick={() => setShowMore(true)}
                      >
                        Plus d&apos;informations
                      </button>
                    )}
                    <p className="mt-1 text-sm text-gray-500">{info}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
