import { useQuery } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function getInvoices({ enterpriseId, invoiceNumber, queryParam }) {
  const invoiceNumberParam = invoiceNumber ? `/${invoiceNumber}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  return {
    queryKey: ["invoices", { enterpriseId, invoiceNumber }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/invoices${invoiceNumberParam}${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load invoices");
    },
  };
}

export function useInvoices(
  { enterpriseId, invoiceNumber, queryParam },
  reactQueryConfig = {}
) {
  const { queryFn, queryKey } = getInvoices({
    enterpriseId,
    invoiceNumber,
    queryParam,
  });

  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    ...reactQueryConfig,
  });

  return {
    isInvoicesLoading: isLoading,
    isInvoicesError: isError,
    invoicesData: data,
    invoicesError: error,
  };
}

export function useNextInvoiceNumber({ enterpriseId, queryParam }) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["invoices", "next-invoice-number", { enterpriseId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/invoices/next-invoice-number${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(
        response,
        "Could not load next invoice numbers"
      );
    },
  });

  return {
    isNextInvoiceNumberLoading: isLoading,
    isNextInvoiceNumberError: isError,
    nextInvoiceNumberData: data,
    nextInvoiceNumberError: error,
  };
}

export function useCurrentInvoiceNumbers(
  { enterpriseId, queryParam },
  reactQueryConfig
) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const query = useQuery({
    queryKey: [
      "invoices",
      "current-invoice-numbers",
      { enterpriseId },
      queryParam,
    ],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/invoices/current-invoice-numbers${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(
        response,
        "Could not load current invoice numbers"
      );
    },
    ...reactQueryConfig,
  });

  return query;
}

export function useLastDocuments(
  { enterpriseId, queryParam },
  reactQueryConfig
) {
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const query = useQuery({
    queryKey: ["documents", "last", { enterpriseId }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/${enterpriseId}/documents/last${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return manageLidapResponse(response, "Could not load last documents");
    },
    ...reactQueryConfig,
  });

  return query;
}
