import React from "react";
import "../assets/css/Sign.css";
import OrySignupForm from "@/components/elements/OrySignupForm";

export default function Sign() {
  return (
    <div id="all">
      <div id="SignBox">
        <div id="LateralBlock"></div>
        <OrySignupForm></OrySignupForm>
      </div>
    </div>
  );
}
