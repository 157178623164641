import React, { useState, useEffect, useRef } from "react";
import { Link, matchPath, NavLink } from "react-router-dom";

import {
  UsersIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  ListBulletIcon,
  LockClosedIcon,
  LockOpenIcon,
  ShieldCheckIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/24/outline";

import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LidapLogo from "@/components/token/logo-lidap-horizontal";
import "@/assets/css/Menu.css";
import PopUpMenu from "./PopUpMenu";
import { useUser } from "@/contexts/UserContext";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { useQueryClient } from "@tanstack/react-query";
import { getRecentClients } from "@/hooks/useClients";
import { getRecentDocuments } from "@/hooks/useRecentDocuments";
import { getRecentArticles } from "@/hooks/useArticles";
import { useImages } from "@/hooks/useImages";
import Base64Image from "@/components/elements/ImageImport/Base64Image";

export default function Menu() {
  const userContext = useUser();
  const user = userContext.state.user;
  const enterpriseId = user?.enterpriseId;

  /* State */
  const [showAdminSubMenu, setShowAdminSubMenu] = useState(false);

  /* Images */
  const images = useImages({
    enterpriseId,
    purpose: "company-logo",
    reactQueryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000 * 60,
    },
    errorOnMissing: false,
  });

  const inputLogo = images.data?.payload;
  const imageHasContent = inputLogo && inputLogo.imageContent;

  /* Hide menu logic */
  const currentPath = window.location.pathname;
  const hideMenuRoutes = ["/login", "/signup", "/mail/:id/verification"];
  const match = (() => {
    for (let i = 0; i < hideMenuRoutes.length; i++) {
      const match = matchPath(
        {
          path: hideMenuRoutes[i],
          exact: true,
          strict: false,
        },
        currentPath
      );
      if (match) {
        return match;
      }
    }
  })();

  const hideMenu = match ? true : false;
  const isAdminPath = currentPath.startsWith("/admin");

  /* Prefetchers */
  const queryClient = useQueryClient();
  const defaultListParam = {
    pageNumber: 0,
    numberOfRecords: 10,
  };
  const { queryFn: clientQueryFn, queryKey: clientQueryKey } = getRecentClients(
    { enterpriseId, queryParam: defaultListParam }
  );
  const { queryFn: documentQueryFn, queryKey: documentQueryKey } =
    getRecentDocuments({
      enterpriseId,
      queryParam: { documentStatus: null, ...defaultListParam },
    });
  const { queryFn: articleQueryFn, queryKey: articleQueryKey } =
    getRecentArticles({ enterpriseId, queryParam: defaultListParam });

  // const [isLogo, setisLogo] = useState(false);
  //TODO: fix this
  const isLogo = false;
  const [isCardDisplayed, setIsCardDisplayed] = useState(false);

  const PopUpMenuRef = useRef();
  const menuBottomRef = useRef();

  const userName = user?.commonName ?? "";
  const firstName = user?.firstName ?? "";
  const lastName = user?.lastName ?? "";
  const userInitials =
    user && user.firstName && user.lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : userName
      ? userName.split(" ").map((n) => n[0])
      : "";
  const isAdmin = user && user.isAdmin ? user.isAdmin : false;

  // Fonction à exécuter lorsqu'un clic en dehors est détecté
  const handleClickOutside = (event) => {
    if (
      PopUpMenuRef.current &&
      !PopUpMenuRef.current.contains(event.target) &&
      !menuBottomRef.current.contains(event.target)
    ) {
      setIsCardDisplayed(false);
    }
  };

  // Ajouter un écouteur d'événements au montage, et le retirer au démontage
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* {isSettingsDisplayed ? (
        <Settings
          setIsSettingsDisplayed={setIsSettingsDisplayed}
          setIsCardDisplayed={setIsCardDisplayed}
        />
      ) : null} */}
      {hideMenu ? null : (
        <div className="flex flex-col h-screen bg-neutral-100 border-r border-gray-300 w-64 select-none ">
          <div className="px-4 py-8">
            <div className="border-b border-gray-300" id="wrap-menu-logo">
              <Link to="/" style={{ cursor: "auto" }} id="wrap-logo-lidap">
                <LidapLogo
                  alt="logo de Lidap"
                  id="menu-logo-lidap"
                  className="text-gray-900"
                  maxWidth="6rem"
                  height="100%"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </div>

            <nav id="NavMenuPart">
              {/* <div id="Admin"> 
              <h2 className="MenuTitles">Admin</h2>
              <Link to="/admin">
                <div id="MenuDashboard" className="MenuNavBoxes">
                  <AdminPanelSettingsIcon
                    sx={{ color: AdminColor }}
                  ></AdminPanelSettingsIcon>
                  <span className="MenuSpan" style={{ color: AdminColor }}>
                    Administration
                  </span>
                </div>
              </Link>
            </div>*/}
              <div id="MenuOverview">
                <p className="mt-8 text-xs text-gray-400">
                  Vue d&apos;ensemble
                </p>

                <NavLink
                  to="/home"
                  className={
                    ({ isActive }) =>
                      isActive
                        ? "text-sm flex flex-row gap-2 items-center text-red-500 whitespace-nowrap" // Active state classes
                        : "text-sm flex flex-row gap-2 items-center text-gray-500 whitespace-nowrap" // Regular state classes
                  }
                >
                  <HomeIcon className="h-5 w-5" />
                  Tableau de bord
                </NavLink>

                <NavLink
                  to="/clients"
                  className={
                    ({ isActive }) =>
                      isActive
                        ? "text-sm flex flex-row gap-2 items-center text-red-500" // Active state classes
                        : "text-sm flex flex-row gap-2 items-center text-gray-500" // Regular state classes
                  }
                  onMouseEnter={() => {
                    queryClient.prefetchQuery(clientQueryKey, clientQueryFn, {
                      staleTime: 1000 * 60 * 5,
                    });
                  }}
                >
                  <UsersIcon className="h-5 w-5" />
                  Clients
                </NavLink>
                <div style={{ display: "none" }}>
                  <NavLink to="/projects" className="MenuNavBoxes, MenuItem">
                    <FolderOutlinedIcon
                      sx={{
                        ".active &": {
                          color: "red",
                        },
                      }}
                    ></FolderOutlinedIcon>
                    Projects
                  </NavLink>
                </div>

                <NavLink
                  to="/invoices"
                  className={
                    ({ isActive }) =>
                      isActive
                        ? "text-sm flex flex-row gap-2 items-center text-red-500 " // Active state classes
                        : "text-sm flex flex-row gap-2 items-center text-gray-500 " // Regular state classes
                  }
                  onMouseEnter={() => {
                    queryClient.prefetchQuery(
                      documentQueryKey,
                      documentQueryFn,
                      {
                        staleTime: 1000 * 60 * 5,
                      }
                    );
                  }}
                >
                  <DocumentDuplicateIcon className="h-5 w-5" />
                  Documents
                </NavLink>

                <NavLink
                  to="/articles"
                  className={
                    ({ isActive }) =>
                      isActive
                        ? "text-sm flex flex-row gap-2 items-center text-red-500 whitespace-nowrap" // Active state classes
                        : "text-sm flex flex-row gap-2 items-center text-gray-500 whitespace-nowrap" // Regular state classes
                  }
                  onMouseEnter={() => {
                    queryClient.prefetchQuery(articleQueryKey, articleQueryFn, {
                      staleTime: 1000 * 60 * 5,
                    });
                  }}
                >
                  <ListBulletIcon className="h-5 w-5" />
                  Mes services
                </NavLink>

                {isAdmin ? (
                  <>
                    <div className={"space-y-4"}>
                      <button
                        to="/admin"
                        className={
                          isAdminPath
                            ? "text-sm flex flex-row gap-2 items-center text-red-500 " // Active state classes
                            : "text-sm flex flex-row gap-2 items-center text-gray-500" // Regular state classes
                        }
                        onClick={() => setShowAdminSubMenu(!showAdminSubMenu)}
                      >
                        {showAdminSubMenu || isAdminPath ? (
                          <LockOpenIcon className="h-5 w-5" />
                        ) : (
                          <LockClosedIcon className="h-5 w-5" />
                        )}
                        Admin
                      </button>
                      {showAdminSubMenu || isAdminPath ? (
                        <ul className="ml-6 space-y-4">
                          <li className="flex items-center">
                            <NavLink
                              to="/admin/whitelist"
                              className={
                                ({ isActive }) =>
                                  isActive
                                    ? "text-sm flex flex-row gap-2 items-center text-red-500 whitespace-nowrap" // Active state classes
                                    : "text-sm flex flex-row gap-2 items-center text-gray-500 whitespace-nowrap" // Regular state classes
                              }
                            >
                              <ShieldCheckIcon className="h-5 w-5" />
                              Liste blanche
                            </NavLink>
                          </li>
                          <li className="flex items-center">
                            <NavLink
                              to="/admin/subscriptions"
                              className={
                                ({ isActive }) =>
                                  isActive
                                    ? "text-sm flex flex-row gap-2 items-center text-red-500 whitespace-nowrap" // Active state classes
                                    : "text-sm flex flex-row gap-2 items-center text-gray-500 whitespace-nowrap" // Regular state classes
                              }
                            >
                              <ReceiptRefundIcon className="h-5 w-5" />
                              Abonnements
                            </NavLink>
                          </li>
                        </ul>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>

              {/* <div id='MenuDocuments'> 
              <h2 className="MenuTitles">Documents</h2>
                  <Link to="/invoices"><div id='MenuInvoices' className="MenuNavBoxes"><RequestPageOutlinedIcon sx={{ color: InvoicesColor }}></RequestPageOutlinedIcon><span className='MenuSpan' style={{color: InvoicesColor}}>Invoices</span></div></Link>
                  <div style={{display: 'none'}}><Link to="/proposals"><div id='MenuProposals' className="MenuNavBoxes"><SendIcon sx={{ color: ProposalsColor }}></SendIcon><span className='MenuSpan' style={{color: ProposalsColor}}>Proposals</span></div></Link></div>
                  <div style={{display: 'none'}}><Link to="/contracts"><div id='MenuContracts' className="MenuNavBoxes"><InsertDriveFileIcon sx={{ color: ContractsColor }}></InsertDriveFileIcon><span className='MenuSpan' style={{color: ContractsColor}}>Contracts</span></div></Link></div>
            </div>*/}
            </nav>
          </div>

          {/* menu-bottom-pop-up */}

          {/* menu-bottom */}
          <PopUpMenu
            isCardDisplayed={isCardDisplayed}
            isLogo={isLogo}
            PopUpMenuRef={PopUpMenuRef}
          />
          <div
            className="flex flex-row text-center justify-center items-center w-full mt-auto cursor-pointer px-2 py-4"
            id="MenuBottom"
            onClick={() => setIsCardDisplayed(!isCardDisplayed)}
            onKeyDown={() => setIsCardDisplayed(!isCardDisplayed)}
            ref={menuBottomRef}
          >
            <div className="flex flex-row justify-between items-center w-full min-w-[10vw] px-2 py-2 ring-1 ring-inset ring-gray-300 rounded-lg shadow-sm bg-white hover:bg-gray-50 dark:hover:bg-neutral-950 gap-4">
              <div className="flex flex-row align-center justify-center items-center gap-2 w-full">
                <div
                  className={`w-8 h-8 ${
                    imageHasContent
                      ? "ring-gray-300"
                      : "bg-red-100 ring-red-600/10"
                  } ring-1 ring-inset rounded-md`}
                  id="logoUserShape"
                >
                  {imageHasContent ? (
                    <Base64Image
                      base64={inputLogo.imageContent}
                      format={inputLogo.imageFormat}
                      alt={"company-logo"}
                      className={"h-7 w-7 rounded-md object-contain"}
                    />
                  ) : (
                    <p className="text-xs text-red-700">{userInitials}</p>
                  )}
                </div>
                <div className="flex flex-col items-start w-28">
                  <p className="text-xs text-black text-left w-28 truncate">
                    {userName}
                  </p>
                  <p className="text-[11px] text-gray-500 whitespace-nowrap">
                    Lidap Business
                  </p>
                </div>
              </div>
              <div className="h-6 w-6" id="SettingsBox">
                <EllipsisVerticalIcon className="h-6 w-6 text-gray-500" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
