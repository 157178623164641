import React, { useState } from "react";
import { useUser } from "@/contexts/UserContext";
import { usePaymentInformation } from "@/hooks/usePaymentInformation";
import LoadingBlock from "@/components/elements/LoadingBlock";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import NewPaymentForm from "@/components/elements/NewPaymentForm/NewPaymentForm";
import { PencilIcon } from "@heroicons/react/24/outline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModifyPaymentForm from "@/components/elements/ModifyPaymentForm/ModifyPaymentForm";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import DeletePaymentFormModal from "@/components/token/DeletePaymentFormModal/DeletePaymentFormModal";
import { useDeletePaymentInformation } from "@/hooks/useDeletePaymentInformation";

export default function MoyenDePaiement() {
  const [isAccountFormDisplayed, setIsAccountFormDisplayed] = useState(false);
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const paymentInformation = usePaymentInformation({
    stakeholderId: enterpriseId,
  });

  const user = userContext.state.user;
  const deletePayment = useDeletePaymentInformation(
    ["payment-information"],
    undefined,
    () => setDeleteScreen(false)
  );

  const [paymentToModify, setPaymentToModify] = useState(null);

  const [deleteScreen, setDeleteScreen] = useState(false);

  if (paymentInformation.isLoading) {
    return (
      <LoadingBlock message="Chargement des informations de paiement..." />
    );
  }

  if (paymentInformation.isError) {
    return (
      <CouldNotLoad
        error={paymentInformation.error}
        message={"Erreur lors du chargement des informations de paiement."}
      />
    );
  }

  const paymentMethodsReference = {
    PAYMENT_LINK: "Lien de paiement",
    IBAN: "Compte Bancaire",
    CHEQUE: "Chèque",
    CASH: "Espèces",
    CREDIT_CARD: "Carte bancaire",
  };

  return (
    <>
      {isAccountFormDisplayed && (
        <NewPaymentForm setIsAccountFormDisplayed={setIsAccountFormDisplayed} />
      )}

      <div className="flex flex-col gap-8 border-b border-gray-300 pb-6">
        <div className="flex flex-col gap-4">
          <div className="text-black">
            <h6>Moyen de paiement</h6>
            <span>Mode de facturation affichés sur les factures</span>
          </div>

          <div className="flex flex-col gap-4">
            {paymentInformation.data?.payload?.stakeholderPaymentInformation.map(
              (paymentMethod) => (
                <React.Fragment key={paymentMethod.id}>
                  {deleteScreen && (
                    <DeletePaymentFormModal
                      deletePayment={deletePayment}
                      paymentInfos={paymentMethod}
                      user={user}
                      setPaymentToModify={setPaymentToModify}
                    />
                  )}
                  {paymentToModify == paymentMethod.id ? (
                    <ModifyPaymentForm
                      paymentInfos={paymentMethod}
                      setPaymentToModify={setPaymentToModify}
                    />
                  ) : null}
                  <div
                    key={paymentMethod.id}
                    className="rounded-lg flex px-4 py-3 justify-between items-center bg-neutral-100 border border-neutral-300 dark:border-neutral-700 shadow-sm"
                  >
                    <div className="flex flex-col items-start">
                      <span className="font-medium text-xs text-neutral-500">
                        <strong className="font-semibold text-sm text-black">
                          {paymentMethod.title}
                        </strong>{" "}
                        {
                          paymentMethodsReference[
                            paymentMethod.paymentInformationDetails
                              .paymentMethod
                          ]
                        }
                      </span>
                      <span className="text-neutral-600">
                        {paymentMethod.paymentInformationDetails.iban
                          ? paymentMethod.paymentInformationDetails.iban
                          : paymentMethod.paymentInformationDetails.url
                          ? paymentMethod.paymentInformationDetails.url
                          : paymentMethod.paymentInformationDetails.inFavourOf
                          ? paymentMethod.paymentInformationDetails.inFavourOf
                          : null}
                      </span>
                    </div>
                    <div className="flex gap-4 items-center">
                      <PencilIcon
                        className="h-5 w-5 text-gray-500 cursor-pointer"
                        onClick={() => setPaymentToModify(paymentMethod.id)}
                      />
                      <DeleteOutlineOutlinedIcon
                        className="h-5 w-5 text-gray-500 cursor-pointer"
                        onClick={() => setDeleteScreen(true)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )
            )}
          </div>
        </div>

        <ButtonTailwind
          variant="outlined"
          style="w-max"
          onClick={() => setIsAccountFormDisplayed(true)}
        >
          Ajouter un compte
        </ButtonTailwind>
      </div>
    </>
  );
}
