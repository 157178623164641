import React, { useEffect, useState } from "react";
import "@/assets/css/Log.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import LidapLogo from "@/components/token/logo-lidap-horizontal";
import { Email } from "@/components/elements/stakeholder/contact/Email";
import { Password } from "@/components/elements/User/Password";
import {
  OryLoginErrors,
  useOryLogin,
  useOryLoginFlow,
} from "@/hooks/useOryLogin";
import { useUserInfo } from "@/hooks/users/useLidapUser";

export default function OryLogin() {
  const location = useLocation();
  /* States */
  const emptySimpleField = { success: false, data: "", isDirty: false };
  const [mail, setMail] = useState({
    ...emptySimpleField,
    ...location.state?.email,
  });
  const [password, setPassword] = useState({
    ...emptySimpleField,
    ...location.state?.password,
  });
  const [erroneousPassword, setErroneousPassword] = useState(null);
  const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] = useState(false);
  const [flow, setFlow] = useState(null);
  const navigate = useNavigate();

  /* Hooks */
  const loginFlow = useOryLoginFlow((data) => {
    setFlow(data);
  });

  // const updateUser = useUpdateUserInfo(["users", "info"], () => {
  //   // navigate("/home?referrer=login");
  // });

  useEffect(() => {
    if (!loginFlow.isSuccess && !loginFlow.isLoading && !loginFlow.isError) {
      loginFlow.mutate();
    }
  }, [loginFlow]);

  const login = useOryLogin(
    ["ory-session"],
    [["users", "info"]],
    () => {
      // notify({
      //   isSuccess: true,
      //   message: "Connexion réussie",
      // });
      // updateUser.mutate();
    },
    () => {
      setErroneousPassword(password.data);
      setPassword((prevState) => ({
        ...prevState,
        isDirty: false,
      }));
      if (
        login?.error &&
        login?.error?.code === OryLoginErrors.ORY_LOGIN_FLOW_EXPIRED
      ) {
        loginFlow.mutate();
      }
    }
  );

  const userInfo = useUserInfo({
    reactQueryConfig: {
      enabled: login.isSuccess,
    },
  });

  useEffect(() => {
    if (userInfo.isSuccess && login.isSuccess) {
      navigate("/home");
    }
  }, [userInfo.isSuccess, navigate, login.isSuccess]);

  /* Derived state */
  const isValid = (() => {
    if (password.success && mail.success) {
      return true;
    } else {
      return false;
    }
  })();

  /* API call */
  const logInData = {
    loginFlow: flow?.loginFlow,
    csrf_token: flow?.csrf_token,
    email: mail.data,
    password: password.data,
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!isValid) {
      setIsInvalidFormSubmitted(true);
      return;
    }
    login.mutate(logInData);
  }

  const passwordError = (() => {
    if (loginFlow.isError) {
      return "Un problème est survenu. Veuillez tenter de rafraîchir la page.";
    }

    /* This technical error should not be shown to the user. Instead, we should regenerate a new login flow */
    if (login?.error?.code === OryLoginErrors.ORY_LOGIN_FLOW_EXPIRED) {
      return null;
    }

    /* If the password is incorrect, we display the error*/
    if (login.isError && !password.isDirty) {
      if (
        login.error?.message &&
        login?.error?.code === OryLoginErrors.ORY_LOGIN_INVALID_CREDENTIALS
      ) {
        return login.error.message;
      }

      return "Une erreur s'est produite lors de la connexion. Veuillez réessayer.";
    }

    return null;
  })();

  /* Render */
  return (
    <div className="flex flex-row w-screen">
      <div className="flex flex-col w-full h-screen">
        <div className="flex">
          <div className="wrap-menu-logo">
            <Link to="/" style={{ cursor: "auto" }} className="wrap-logo-lidap">
              <LidapLogo
                alt="logo de Lidap"
                className="menu-logo-lidap"
                color="var(--shades-900)"
                maxWidth="6rem"
                height="100%"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col m-auto w-96 gap-8 h-full pb-16">
          <div id="message">Connectez vous</div>
          <form
            id="InputContainer"
            onSubmit={handleSubmit}
            data-form-type="login"
          >
            <div className="wrap-inputs">
              <Email
                name="email"
                label="Email"
                onChange={setMail}
                required={true}
                initialEmail={mail.data}
                id="email"
                displayError={login.isError || isInvalidFormSubmitted}
              />

              <div className="wrap-mdp">
                <Password
                  name="password"
                  label="Mot de passe"
                  id="passIn"
                  placeholder="Mot de passe"
                  autoComplete="current-password"
                  required={true}
                  type="password"
                  initialPassword={password.data}
                  icon={false}
                  onChange={setPassword}
                  displayError={login.isError || isInvalidFormSubmitted}
                  externalError={passwordError}
                  erroneousPassword={erroneousPassword}
                  bypassRules={true}
                />

                <div id="ForgotAccount" className="select-none">
                  <Link to="/recovery" id="LinkForgotAccount">
                    Mot de passe oublié
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full items-stretch gap-2">
              <ButtonTailwind
                variant="filled"
                width="100%"
                type="submit"
                loading={login.isLoading}
              >
                Se connecter
              </ButtonTailwind>

              <div id="newAccount">
                <div id="ShadowAccount">Vous n&apos;avez pas de compte ?</div>{" "}
                <Link
                  to="/signup"
                  state={{ email: mail, password: password }}
                  id="getStarted"
                >
                  Commencez ici
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="LateralBlock"></div>
    </div>
  );
}
