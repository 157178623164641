import InputTailwind from "@/components/token/Input/inputTailwind";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import React, { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDeletePaymentInformation } from "@/hooks/useDeletePaymentInformation";
import { useUser } from "@/contexts/UserContext";
import { useCreateUpdatePaymentInformation } from "@/hooks/useCreateUpdatePaymentInformation";
import { notify } from "@/utils/notify";
import DeletePaymentFormModal from "@/components/token/DeletePaymentFormModal/DeletePaymentFormModal";

export default function ModifyPaymentForm({
  setPaymentToModify,
  paymentInfos,
  setAccountsToCredit,
}) {
  const [title, setTitle] = useState("");
  const [valuePaymentForm, setValuePaymentForm] = useState("");
  const [paymentType, setPaymentType] = useState("CASH");
  const [isModified, setIsModified] = useState(false);
  const [deleteScreen, setDeleteScreen] = useState(false);

  const userContext = useUser();
  const user = userContext.state.user;
  const country = user?.country || "BE";
  const deletePayment = useDeletePaymentInformation(["payment-information"]);
  const modifyPayment = useCreateUpdatePaymentInformation([
    "payment-information",
  ]);

  useEffect(() => {
    if (deletePayment.isSuccess) {
      notify({
        isSuccess: true,
        message: "Le compte a bien été supprimé",
      });
      setAccountsToCredit((prev) => {
        return prev.filter((account) => account.id !== paymentInfos.id);
      });
    }
  }, [deletePayment.isSuccess]);

  useEffect(() => {
    if (modifyPayment.isSuccess) {
      notify({
        isSuccess: true,
        message: "Le compte a bien été modifié",
      });

      setAccountsToCredit &&
        setAccountsToCredit((prev) => {
          return prev.filter((account) => account.id !== paymentInfos.id);
        });

      setPaymentToModify(null);
    }
  }, [modifyPayment.isSuccess]);

  useEffect(() => {
    const method = paymentInfos?.paymentInformationDetails?.paymentMethod;
    const newPaymentType =
      method === "PAYMENT_LINK" || method === "IBAN" ? method : "CASH";
    setPaymentType(newPaymentType);

    const newTitle =
      paymentInfos?.title !== undefined || null ? paymentInfos?.title : "";
    setTitle(newTitle);

    const newSecondInputTitle =
      paymentProperties[newPaymentType]?.secondInputTitle || "";
    setValuePaymentForm(newSecondInputTitle);
  }, [
    paymentInfos?.paymentInformationDetails?.paymentMethod,
    paymentInfos?.paymentInformationDetails,
    paymentInfos?.title,
  ]);

  useEffect(() => {
    const paymentInfoDetails = paymentInfos?.paymentInformationDetails;

    const isTitleChanged = title !== "" && title !== paymentInfoDetails?.title;

    const isSecondInputChanged = paymentProperties[
      paymentInfoDetails?.paymentMethod
    ]?.secondInputTitle
      ? valuePaymentForm !==
        paymentProperties[paymentInfoDetails?.paymentMethod]?.secondInputTitle
      : true;

    if (isTitleChanged || isSecondInputChanged) {
      setIsModified(true);
    }
  }, [title, valuePaymentForm, paymentInfos?.paymentInformationDetails]);

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  const paymentProperties = {
    PAYMENT_LINK: {
      isTitle: true,
      label: "Intitulé",
      placeholder: "Stripe",
      titleOnChange: handleTitleChange,
      isSecondInput: true,
      label2: "Lien",
      secondInputTitle: paymentInfos?.paymentInformationDetails?.url || "",
      secondInputPlaceholder: "https://stripe.com",
    },
    IBAN: {
      isTitle: true,
      label: "Intitulé",
      placeholder: country === "BE" ? "Belfius" : "Compte Société Générale",
      titleOnChange: handleTitleChange,
      isSecondInput: true,
      label2: "IBAN",
      secondInputTitle:
        paymentInfos?.paymentInformationDetails?.iban?.replace(/\s/g, "") || "",
      secondInputPlaceholder:
        country === "BE"
          ? "BE71 0961 2345 6769"
          : "FR763 0001 0079 4123 4567 8901 85",
    },
    CASH: {
      isTitle: false,
      label: "Intitulé",
      placeholder: "Compte Société Générale",
      titleOnChange: handleTitleChange,
      isSecondInput: false,
    },
  };

  if (!paymentType) return null;

  const handleSaveClick = () => {
    if (!title || !user.enterpriseId || !paymentInfos.id) {
      return;
    }

    const paymentInformationDetails = {
      paymentMethod: paymentType,
    };
    switch (paymentType) {
      case "PAYMENT_LINK":
        paymentInformationDetails["url"] = valuePaymentForm;
        break;
      case "IBAN":
        paymentInformationDetails["iban"] = valuePaymentForm?.replace(
          /\s/g,
          ""
        );
        break;
      case "CHEQUE":
        paymentInformationDetails["inFavourOf"] = valuePaymentForm;
        break;
      default:
        break;
    }

    modifyPayment.mutate({
      stakeholderId: user.enterpriseId,
      id: paymentInfos.id,
      paymentInformation: {
        isMain: false,
        paymentInformation: {
          title,
          paymentInformationDetails,
        },
      },
    });
  };

  return (
    <>
      {deleteScreen ? (
        <DeletePaymentFormModal
          deletePayment={deletePayment}
          paymentInfos={paymentInfos}
          user={user}
          setPaymentToModify={setPaymentToModify}
        />
      ) : (
        <div
          className="absolute top-0 left-0 z-50 bg-[rgba(0,0,0,0.25)] backdrop-blur-sm flex justify-center items-center w-screen h-screen"
          onMouseDown={() => setPaymentToModify(null)}
        >
          <div
            className="bg-white px-8 py-12 flex flex-col items-start gap-8 rounded-2xl w-[32rem]"
            onMouseDown={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center w-full">
              <h1 className="text-black font-bold text-[2rem]">
                Modifier un compte
              </h1>
              <div
                className="w-12 h-12 flex justify-center items-center cursor-pointer"
                onClick={() => setDeleteScreen(true)}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ color: "#8C8C8C", width: "1.5rem", height: "1.5rem" }}
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              {paymentProperties[paymentType].isTitle && (
                <InputTailwind
                  type="text"
                  label="Moyen de paiement"
                  value={paymentInfos?.paymentInformationDetails?.paymentMethod}
                  readOnly
                />
              )}
              <InputTailwind
                type="text"
                label={paymentProperties[paymentType].label}
                placeholder={paymentProperties[paymentType].placeholder}
                id="title"
                value={title}
                onChange={paymentProperties[paymentType].titleOnChange}
              />
              {paymentProperties[paymentType].isSecondInput && (
                <InputTailwind
                  type="text"
                  label={paymentProperties[paymentType].label2}
                  placeholder={
                    paymentProperties[paymentType].secondInputPlaceholder
                  }
                  id="secondInput"
                  value={valuePaymentForm}
                  onChange={(e) => setValuePaymentForm(e.target.value)}
                />
              )}
            </div>
            <div className="flex gap-4 w-full">
              <ButtonTailwind
                variant="outlined"
                style="flex-1"
                onClick={(e) => {
                  e.stopPropagation(); // Stop propagation for this specific button
                  setPaymentToModify(null);
                }}
              >
                Annuler
              </ButtonTailwind>
              <ButtonTailwind
                variant={isModified ? "filled" : "disabled"}
                style="flex-1"
                onClick={handleSaveClick}
                loading={modifyPayment.isLoading}
              >
                Enregistrer
              </ButtonTailwind>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
