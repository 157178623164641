export const unitPriceReference = [
  { unitValue: "FIXED_PRICE", label: "Forfait" },
  { unitValue: "DAY", label: "Jour" },
  { unitValue: "HOUR", label: "Heure" },
  { unitValue: "UNIT", label: "Unité" },
  { unitValue: "PERSON", label: "Personne" },
  { unitValue: "WORD", label: "Mot" },
  { unitValue: "CHARACTER", label: "Caractère" },
  { unitValue: "ARTICLE", label: "Article" },
  { unitValue: "PAGE", label: "Page" },
  { unitValue: "CLICK", label: "Clic" },
];
