import { useState } from "react";
import { useCreateUpdateEnterprises } from "../useCreateUpdateEnterprises";
import { useUser } from "@/contexts/UserContext";
import { vatCheckDigitFR } from "@/components/elements/stakeholder/identifier/identifierValidator";

export function useFormCreateEnterprise({ onSuccessfulCreation }) {
  const userContext = useUser();
  const userId = userContext.state.user.userId;
  const defaultNation = userContext?.state?.user?.country || "BE";
  const countryIdentifierType = (country) => {
    if (country === "BE") {
      return "COMPANY_NUMBER";
    } else if (country === "FR") {
      return "SIREN";
    } else {
      return "COMPANY_NUMBER";
    }
  };
  const createEnterprise = useCreateUpdateEnterprises(
    ["enterprises"],
    [["users", "info"]],
    onSuccessfulCreation
  );
  const [companyIdentifier, setCompanyIdentifier] = useState({
    success: false,
    data: {
      identifierType: countryIdentifierType(defaultNation),
      countryOrRegion: defaultNation,
      identifier: "",
    },
    isDirty: false,
  });
  const emptySimpleField = { success: false, data: "", isDirty: false };
  const [isFirstEnterprise] = useState(true);
  const [selectedNation, setSelectedNation] = useState(defaultNation);
  const [currency] = useState("EUR");
  const [companyName, setCompanyName] = useState(emptySimpleField);
  const [streetName, setStreetName] = useState(emptySimpleField);
  const [city, setCity] = useState(emptySimpleField);
  const [postalCode, setPostalCode] = useState(emptySimpleField);
  const [enterpriseType] = useState("Company");
  const [tvaFacturation, setTVAFacturation] = useState(false);
  const [isInvalidFormSubmitted, setIsInvalidFormSubmitted] = useState(false);

  const isFormValid = (() => {
    return (
      companyName.success &&
      streetName.success &&
      city.success &&
      postalCode.success
    );
  })();

  const identifiers = [];
  if (companyIdentifier.success) {
    const cpyId = {
      ...companyIdentifier.data,
      identifier: companyIdentifier.data.identifier?.replace(/\D/g, ""),
    };

    identifiers.push(cpyId);

    const vatNumber = (country) => {
      const id = companyIdentifier.data.identifier?.replace(/\D/g, "");
      if (country === "BE") {
        return country + id;
      } else if (country === "FR") {
        return country + vatCheckDigitFR(id).toString().padStart(2, "0") + id;
      }
    };

    if (tvaFacturation) {
      identifiers.push({
        identifierType: "VAT",
        countryOrRegion: "EU",
        identifier: vatNumber(selectedNation),
      });
    }
  }

  function handleSubmit() {
    if (!isFormValid) {
      setIsInvalidFormSubmitted(true);
      return;
    }

    const enterpriseData = {
      owner: userId,
      enterpriseType: enterpriseType,
      currency: currency,
      stakeholder: {
        alias: companyName.data,
        name: companyName.data,
        person: {
          [enterpriseType === "Company" ? "legalName" : "commonName"]:
            companyName.data,
        },
        stakeholderCategory:
          enterpriseType === "Company" ? "LEGAL_PERSON" : "NATURAL_PERSON",
        stakeholderType: "ENTERPRISE",
        addresses: [
          {
            country: selectedNation,
            streetName: streetName.data,
            city: city.data,
            postalCode: postalCode.data,
            isMain: true,
            isBillingAddress: true,
          },
        ],
        identifiers: [...identifiers],
        country: selectedNation,
      },
    };

    createEnterprise.mutate({ enterprise: enterpriseData });
  }

  return {
    companyName,
    setCompanyName,
    streetName,
    setStreetName,
    city,
    setCity,
    postalCode,
    setPostalCode,
    selectedNation,
    setSelectedNation,
    companyIdentifier,
    setCompanyIdentifier,
    tvaFacturation,
    setTVAFacturation,
    createEnterprise,
    isFirstEnterprise,
    isFormValid,
    isInvalidFormSubmitted,
    countryIdentifierType,
    handleSubmit,
  };
}
