import React, { useState, useEffect } from "react";
import Toggle from "@/components/token/button/toggle";
import LoadingBlock from "@/components/elements/LoadingBlock";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Select from "@/components/token/select/select";
import InputTailwind from "@/components/token/Input/inputTailwind";
import ModifyPaymentForm from "@/components/elements/ModifyPaymentForm/ModifyPaymentForm";
import { useUser } from "@/contexts/UserContext";
import { useCreateUpdatePaymentInformation } from "@/hooks/useCreateUpdatePaymentInformation";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const PaymentMethod = {
  IBAN: "IBAN",
  PAYMENT_LINK: "PAYMENT_LINK",
  CHEQUE: "CHEQUE",
  CASH: "CASH",
};

export default function CompteACrediter({
  accountsToCredit,
  setAccountsToCredit,
  accounts,
  paymentInformation,
}) {
  const userContext = useUser();
  const country = userContext.state.user?.country || "BE";
  const enterpriseId = userContext.state.user.enterpriseId;

  const createPaymentInformation = useCreateUpdatePaymentInformation(
    ["payment-information"],
    (data) => {
      setIsAddPaymentDisplayed(false);
      const method =
        data.payload.created.paymentInformationDetails.paymentMethod;
      const p = data.payload.created;
      const updatedAccounts = [
        ...accountsToCredit,
        {
          externalId: p.id,
          name: p.title,
          title: p.title,
          paymentMethod: p.paymentInformationDetails.paymentMethod,
          iban:
            method === PaymentMethod.IBAN
              ? p.paymentInformationDetails.iban?.replace(/\s/g, "")
              : undefined,
          inFavourOf:
            method === PaymentMethod.CHEQUE
              ? p.paymentInformationDetails.inFavourOf
              : undefined,
          url:
            method === PaymentMethod.PAYMENT_LINK
              ? p.paymentInformationDetails.url
              : undefined,
          cash: method === PaymentMethod.CASH ? p.title : undefined,
        },
      ];
      setAccountsToCredit(updatedAccounts);
    }
  );
  const [isAddPaymentDisplayed, setIsAddPaymentDisplayed] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState({
    id: 1,
    name: "Sélectionnez un moyen de paiement",
  });
  const [accountWording, setAccountWording] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");

  const [isAccountsSet, setIsAccountsSet] = useState(false);

  const [paymentToModify, setPaymentToModify] = useState(null);

  useEffect(() => {
    setAccountWording("");
    setSecondInputValue("");
    if (selectedPayment.placeholderAccountWording === "Espèces") {
      setAccountWording("Paiement en espèces");
    }
  }, [selectedPayment]);

  function toggleAccountInAccountsToCredit(account) {
    if (accountsToCredit.some((acc) => acc.externalId === account.id)) {
      const filteredAccounts = accountsToCredit.filter(
        (acc) => acc.externalId !== account.id
      );
      setAccountsToCredit(filteredAccounts);
    } else {
      const updatedAccounts = [...accountsToCredit, account];
      setAccountsToCredit(updatedAccounts);
    }
  }

  // useEffect(() => {
  //   if (accounts.length > 0) {
  //     const updatedAccountsToCredit = accounts.filter(
  //       (account) => !accountsToCredit.some((acc) => acc.id === account.id)
  //     );

  //     if (
  //       updatedAccountsToCredit.length > 0 &&
  //       !isAccountsSet &&
  //       accountsToCredit.length === 0
  //     ) {
  //       // setAccountsToCredit((prevAccounts) => [...updatedAccountsToCredit]);

  //       setIsAccountsSet(true);
  //     } else if (accountsToCredit.length > 0 && !isAccountsSet) {
  //       setIsAccountsSet(true);
  //     }
  //   }
  // }, [accounts]);

  const paymentType = [
    {
      id: PaymentMethod.IBAN,
      name: "Compte bancaire",
      placeholderAccountWording: "Libellé du compte",
      secondInput: true,
      label: "IBAN",
      placeholder:
        country === "BE"
          ? "BE71 0961 2345 6769"
          : "FR763 0001 0079 4123 4567 8901 85",
    },
    {
      id: PaymentMethod.PAYMENT_LINK,
      name: "Lien de paiement",
      placeholderAccountWording: "Fournisseur de paiement",
      secondInput: true,
      label: "Lien",
      placeholder: "https://votrefournisseur.com/votrelien",
    },
    {
      id: PaymentMethod.CHEQUE,
      name: "Chèque",
      placeholderAccountWording: "Chèque",
      secondInput: true,
      label: "À l'ordre de",
      placeholder: "John Doe",
    },
    {
      id: PaymentMethod.CASH,
      name: "Espèces",
      placeholderAccountWording: "Espèces",
      secondInput: false,
    },
  ];

  function informationPayload() {
    const isMain = accounts.length === 0;
    const title = accountWording;
    const paymentMethod = selectedPayment.id;

    let payload = {
      isMain,
      paymentInformation: {
        title,
        paymentInformationDetails: {
          paymentMethod,
          // ...iban,
          // ...inFavourOf,
          // ...url,
        },
      },
    };

    switch (paymentMethod) {
      case PaymentMethod.IBAN:
        payload.paymentInformation.paymentInformationDetails.iban =
          secondInputValue?.replace(/\s/g, "");
        break;
      case PaymentMethod.CHEQUE:
        payload.paymentInformation.paymentInformationDetails.inFavourOf =
          secondInputValue;
        break;
      case PaymentMethod.PAYMENT_LINK:
        payload.paymentInformation.paymentInformationDetails.url =
          secondInputValue;
        break;
      case PaymentMethod.CASH:
      default:
        break;
    }

    return payload;
  }

  function AnnulerMoyenDePaiement() {
    setAccountWording("");
    setSecondInputValue("");
    setIsAddPaymentDisplayed(false);
  }

  function isDuplicateTitle(title) {
    return accounts.some((account) => account.name === title);
  }

  return (
    <div className="flex flex-col gap-3">
      {isAddPaymentDisplayed ? (
        <div className="z-50">
          <div
            className="bg-[rgba(0,0,0,0.5)] backdrop-blur-sm absolute top-0 left-0 w-screen h-screen"
            onClick={() => setIsAddPaymentDisplayed(false)}
          ></div>
          <div className="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-12 bg-white rounded-2xl w-[28rem]">
            <h1 className="text-[2rem] mb-8 text-center">Ajouter un compte</h1>
            <div className="flex flex-col gap-4">
              <div>
                <Select
                  data={paymentType}
                  selectedItem={selectedPayment}
                  setSelectedItem={setSelectedPayment}
                  label="Moyen de paiement"
                />
              </div>
              <InputTailwind
                label="Intitulé"
                type="text"
                placeholder={selectedPayment.placeholderAccountWording}
                value={accountWording}
                onChange={(event) => setAccountWording(event.target.value)}
                isRequired={true}
                icon={false}
                error={
                  isDuplicateTitle(accountWording)
                    ? "Un compte avec cet intitulé existe déjà"
                    : ""
                }
              />
              {selectedPayment.secondInput ? (
                <InputTailwind
                  label={selectedPayment.label}
                  type="text"
                  placeholder={selectedPayment.placeholder}
                  value={secondInputValue}
                  onChange={(event) => setSecondInputValue(event.target.value)}
                  isRequired={true}
                  icon={false}
                />
              ) : null}
            </div>

            <div className="flex w-full gap-4 mt-8">
              {createPaymentInformation.isLoading ? (
                <LoadingBlock message="Création du compte en cours..." />
              ) : (
                <>
                  <ButtonTailwind
                    onClick={() => AnnulerMoyenDePaiement()}
                    variant={"outlined2"}
                    style="w-full"
                  >
                    Annuler
                  </ButtonTailwind>
                  <ButtonTailwind
                    onClick={(e) => {
                      e.preventDefault();
                      createPaymentInformation.mutate({
                        stakeholderId: enterpriseId,
                        paymentInformation: informationPayload(),
                      });
                    }}
                    style="w-full"
                  >
                    Enregistrer
                  </ButtonTailwind>
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <label className="text-black font-bold">Compte à créditer</label>

      {paymentInformation.isLoading ? (
        <LoadingBlock message="Chargement des comptes..." />
      ) : (
        accounts.map((account) => {
          // Filter the stakeholderPaymentInformation for the current account
          const filteredPaymentInfo =
            paymentInformation.data?.payload?.stakeholderPaymentInformation.filter(
              (info) => info.id == account.id
            );

          const isToggled = accountsToCredit.some(
            (acc) => acc.externalId == account.id
          );

          return (
            <div key={account.id} className="flex justify-between">
              <div
                className="flex gap-2 items-center"
                onClick={() => setPaymentToModify(account.id)}
              >
                <label className="text-neutral-400 text-sm font-medium">
                  {account.name}
                </label>
                <EditOutlinedIcon
                  className="cursor-pointer"
                  sx={{ color: "#BFBFBF", height: "1.5rem", width: "1.5rem" }}
                />
                {paymentToModify == account.id ? (
                  <ModifyPaymentForm
                    paymentInfos={filteredPaymentInfo[0]}
                    setPaymentToModify={setPaymentToModify}
                    setAccountsToCredit={setAccountsToCredit}
                  />
                ) : null}
              </div>
              <Toggle
                onClick={() => toggleAccountInAccountsToCredit(account)}
                isToggled={isToggled}
              />
            </div>
          );
        })
      )}

      <ButtonTailwind
        variant="outlined2"
        width="100%"
        onClick={() => setIsAddPaymentDisplayed(true)}
      >
        Ajouter un compte
      </ButtonTailwind>
    </div>
  );
}
