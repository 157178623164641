import React from "react";
import "./ColorPicker.css";

export default function ColorPicker({ color, setColor, label }) {
  return (
    <div className="flex flex-col mt-4 items-start">
      <label htmlFor="colorInput" className="text-sm text-gray-700">
        {label || "Couleur"}
      </label>
      <div className="w-full h-9 flex gap-2 rounded-lg ring-gray-300 placeholder-gray-400 dark:placeholder-[#444] focus:ring-black dark:focus:ring-white border border-neutral-200">
        <input
          type="color"
          className="h-full cursor-pointer color-input"
          value={color}
          id="style1"
          onChange={(e) => setColor(e.target.value)}
        />
        <input
          type="text"
          value={color}
          placeholder="#000000"
          onChange={(e) => setColor(e.target.value)}
          className="px-4 sm:text-sm sm:leading-6 bg-transparent border-none focus:ring-0 cursor-default py-[0px] h-full w-full"
        />
      </div>
    </div>
  );
}
