import { faro } from "@/AppRouter";
import FetchError from "./FetchError";

export default class Lidapi {
  constructor(disableToast = false) {
    this.api_token = null;
    this.api_url = process.env.BASE_API_URL;
    this.disableToast = disableToast;
  }

  init = () => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return headers;
  };

  initFormData = () => {
    let headers = {
      Accept: "application/json",
    };

    return headers;
  };

  signUp = async (data) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/signup`, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de l'inscription",
      this.disableToast,
      { body: data, params: null, query: null }
    );
  };

  login = async (data) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/login`, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la connexion",
      this.disableToast,
      { body: data, params: null, query: null }
    );
  };

  resetEmail = async (mailId) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/mail/${mailId}/reset`, {
      method: "POST",
      headers,
      credentials: "include",
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors du renvoi du code de vérification",
      this.disableToast,
      { body: null, params: mailId, query: null }
    );
  };

  isLoggedIn = async (data) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/is-logged-in`, {
      method: "GET",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });

    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la vérification du login",
      this.disableToast,
      { body: data, params: null, query: null }
    );
  };

  logout = async () => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/logout`, {
      method: "POST",
      headers,
      credentials: "include",
    });
    const forceDisableToast = (() => {
      if (!response.ok && response.status === 401) {
        return true;
      }
    })();
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la déconnexion",
      this.disableToast || forceDisableToast,
      { body: null, params: null, query: null }
    );
  };

  verifyEmail = async (mailId, code) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/mail/${mailId}/verification?vc=${code}`,
      {
        method: "PUT",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la vérification de l'email",
      this.disableToast,
      { body: null, params: mailId, query: null }
    );
  };

  getClients = async ({
    params: { enterpriseId },
    queryParams: { addresses, emails, identifiers, paymentInformation },
  }) => {
    const headers = this.init();
    let queryString = "?";
    queryString += addresses ? `addresses=${addresses}` : "addresses=false";
    queryString += emails ? `&emails=${emails}` : "";
    queryString += identifiers ? `&identifiers=${identifiers}` : "";
    queryString += paymentInformation
      ? `&paymentInformation=${paymentInformation}`
      : "";

    const response = await fetch(
      `${this.api_url}/${enterpriseId}/clients${queryString}`,
      {
        method: "GET",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la récupération des clients",
      this.disableToast,
      { body: null, params: enterpriseId, query: queryString }
    );
  };

  getCountWhitelisted = async () => {
    const headers = this.init();

    const response = await fetch(`${this.api_url}/users/count-whitelisted`, {
      method: "GET",
      headers,
      credentials: "include",
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la recherche du nombre d'utilisateurs whitelistés",
      this.disableToast,
      { body: null, params: null, query: null }
    );
  };

  getUserInfo = async (forceSearch) => {
    const queryString = forceSearch ? `?forceSearch=${forceSearch}` : "";

    const headers = this.init();
    const response = await fetch(`${this.api_url}/users/info${queryString}`, {
      method: "GET",
      headers,
      credentials: "include",
    });

    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la récupération des informations de l'utilisateur",
      this.disableToast,
      { body: null, params: null, query: queryString }
    );
  };

  newClient = async ({ body: data, params: { enterpriseId } }) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/${enterpriseId}/clients`, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création du client",
      this.disableToast,
      { body: data, params: enterpriseId, query: null }
    );
  };

  updateClient = async ({ body: data, params: { enterpriseId, clientId } }) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/clients/${clientId}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour du client",
      this.disableToast,
      { body: data, params: { enterpriseId, clientId }, query: null }
    );
  };

  createEnterprise = async (data) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/enterprises`, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création de l'entreprise",
      this.disableToast,
      { body: data, params: null, query: null }
    );
  };

  updateEnterprise = async (data, id) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/enterprises/${id}`, {
      method: "PATCH",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour de l'entreprise",
      this.disableToast,
      { body: data, params: id, query: null }
    );
  };

  //EXEMPLE DE GET
  getUserList = async (params) => {
    const headers = this.init();
    const url = new URL(`${this.api_url}/users`);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    const response = await fetch(url, { headers });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la récupération des utilisateurs",
      this.disableToast,
      { body: null, params: params, query: null }
    );
  };

  //FACTURES
  getNextInvoiceNumber = async (params) => {
    const headers = this.init();
    const url = new URL(
      `${this.api_url}/${params.enterpriseId}/invoices/next-invoice-number`
    );
    const response = await fetch(url, { headers });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la récupération du prochain numéro de facture",
      this.disableToast,
      { body: null, params: params, query: null }
    );
  };

  createInvoice = async (data, enterpriseId) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/${enterpriseId}/invoices`, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création de facture",
      this.disableToast,
      { body: data, params: enterpriseId, query: null }
    );
  };

  createPaymentInformation = async (stakeholderId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/payment-information`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création de l'information de paiement",
      this.disableToast,
      { body: data, params: stakeholderId, query: null }
    );
  };

  updatePaymentInformation = async (stakeholderId, id, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/payment-information/${id}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour de l'information de paiement",
      this.disableToast,
      { body: data, params: { stakeholderId, id }, query: null }
    );
  };

  deletePaymentInformation = async (stakeholderId, id, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/payment-information/${id}`,
      {
        method: "DELETE",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la suppression de l'information de paiement",
      this.disableToast,
      { body: data, params: { stakeholderId, id }, query: null }
    );
  };

  updateUser = async (data) => {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/users/self`, {
      method: "PUT",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour des données utilisateurs",
      this.disableToast,
      { body: data, params: null, query: null }
    );
  };

  createIdentifier = async (stakeholderId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/identifiers`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'un identifiant",
      this.disableToast,
      { body: data, params: stakeholderId, query: null }
    );
  };

  updateIdentifier = async (stakeholderId, identifierId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/identifiers/${identifierId}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour d'un identifiant",
      this.disableToast,
      { body: data, params: { stakeholderId, identifierId }, query: null }
    );
  };

  createAddress = async (stakeholderId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/addresses`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'une adresse",
      this.disableToast,
      { body: data, params: stakeholderId, query: null }
    );
  };

  updateAddress = async (stakeholderId, addressId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/addresses/${addressId}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour d'une adresse",
      this.disableToast,
      { body: data, params: { stakeholderId, addressId }, query: null }
    );
  };

  createPhone = async (stakeholderId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/phones`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'un téléphone",
      this.disableToast,
      { body: data, params: stakeholderId, query: null }
    );
  };

  updatePhone = async (stakeholderId, phoneId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/phones/${phoneId}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour d'un téléphone",
      this.disableToast,
      { body: data, params: { stakeholderId, phoneId }, query: null }
    );
  };

  createEmail = async (stakeholderId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/emails`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'un email",
      this.disableToast,
      { body: data, params: stakeholderId, query: null }
    );
  };

  updateEmail = async (stakeholderId, emailId, data) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/stakeholders/${stakeholderId}/emails/${emailId}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour d'un email",
      this.disableToast,
      { body: data, params: { stakeholderId, emailId }, query: null }
    );
  };

  uploadImage = async ({ enterpriseId, image, purpose }) => {
    const headers = this.initFormData({
      boundary: "image",
    });
    const formData = new FormData();
    formData.append("image", image);

    const response = await fetch(
      `${this.api_url}/images/${enterpriseId}/${purpose}`,
      {
        method: "PUT",
        headers,
        credentials: "include",
        body: formData,
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors du chargement d'une image",
      this.disableToast,
      { body: null, params: { enterpriseId, purpose }, query: null }
    );
  };

  savePdf = async ({ enterpriseId, invoiceNumber, pdf: pdfAsBase64 }) => {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/invoices/${invoiceNumber}`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify({ pdf: pdfAsBase64 }),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la sauvegarde du PDF",
      this.disableToast
    );
  };

  createNumerotationType = async function (enterpriseId, data) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/invoices/initialize-document-number`,
      {
        method: "PUT",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de l'initialisation des numéros de documents",
      this.disableToast,
      { body: data, params: enterpriseId, query: null }
    );
  };

  updateInvoiceStatus = async function (
    enterpriseId,
    invoiceNumber,
    queryString
  ) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/invoices/${invoiceNumber}/status${queryString}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour du statut de la facture",
      this.disableToast,
      {
        body: null,
        params: { enterpriseId, invoiceNumber },
        query: queryString,
      }
    );
  };

  createArticle = async function (enterpriseId, data) {
    const headers = this.init();
    const response = await fetch(`${this.api_url}/${enterpriseId}/articles`, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création de l'article",
      this.disableToast,
      { body: data, params: enterpriseId, query: null }
    );
  };

  updateArticle = async function (enterpriseId, articleId, data) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/articles/${articleId}`,
      {
        method: "PATCH",
        headers,
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la mise à jour de l'article",
      this.disableToast,
      { body: data, params: { enterpriseId, articleId }, query: null }
    );
  };

  deleteArticle = async function (enterpriseId, articleId) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/articles/${articleId}`,
      {
        method: "DELETE",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la suppression de l'article",
      this.disableToast,
      { body: null, params: { enterpriseId, articleId }, query: null }
    );
  };

  deleteInvoice = async function (enterpriseId, invoiceNumber) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/${enterpriseId}/invoices/${invoiceNumber}`,
      {
        method: "DELETE",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la suppression du document",
      this.disableToast,
      { body: null, params: { enterpriseId, invoiceNumber }, query: null }
    );
  };

  getReferential = async function (
    country,
    identifierType,
    identifier,
    queryString = ""
  ) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/referentials/${country}/${identifierType}/${identifier}${queryString}`,
      {
        method: "GET",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la récupération des données référentielles`,
      this.disableToast,
      {
        body: null,
        params: { country, identifierType, identifier },
        query: null,
      }
    );
  };

  searchReferential = async function (identifier, queryString = "") {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/referentials/${identifier}${queryString}`,
      {
        method: "GET",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la recherche des données référentielles`,
      this.disableToast,
      { body: null, params: identifier, query: null }
    );
  };

  createCheckoutSession = async function ({
    enterpriseId,
    priceId,
    successPath,
    cancelPath,
  }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/payments/stripe/${enterpriseId}/checkout`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify({
          priceId,
          successPath,
          cancelPath,
        }),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'une session de paiement",
      this.disableToast,
      {
        body: {
          priceId,
          successPath,
          cancelPath,
        },
        params: { enterpriseId },
        query: null,
      }
    );
  };

  createCustomerPortal = async function ({
    params: { enterpriseId },
    body: { returnPath },
    queryString = "",
  }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/payments/stripe/${enterpriseId}/customer-portal${queryString}`,
      {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify({
          returnPath,
        }),
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'une session de paiement",
      this.disableToast,
      {
        body: { returnPath },
        params: { enterpriseId },
        query: queryString,
      }
    );
  };

  createDefaultSubscription = async function ({ enterpriseId }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/payments/stripe/${enterpriseId}/subscriptions/default`,
      {
        method: "POST",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'une souscription",
      this.disableToast,
      { body: null, params: { enterpriseId }, query: null }
    );
  };

  createDefaultSubscriptionByAdmin = async function ({ enterpriseId }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/admin/payments/stripe/subscriptions/default`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({ enterpriseId }),
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      "Une erreur s'est produite lors de la création d'une souscription par défaut en mode admin",
      this.disableToast,
      { body: { enterpriseId }, params: null, query: null }
    );
  };

  getAllSettings = async function (settingsType, parentId, queryString = "") {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/settings/${settingsType}/${parentId}${queryString}`,
      {
        method: "GET",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la récupération des paramètres`,
      this.disableToast,
      { body: null, params: { settingsType, parentId }, query: queryString }
    );
  };

  manageSettings = async function ({
    settingsType,
    parentId,
    settingsKey,
    settingsValue,
    queryString = "",
  }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/settings/${settingsType}/${parentId}/${settingsKey}${queryString}`,
      {
        method: "PUT",
        headers,
        body: JSON.stringify({ settingsValue }),
        credentials: "include",
      }
    );

    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la mise à jour des paramètres`,
      this.disableToast,
      {
        body: { settingsValue },
        params: { settingsType, parentId, settingsKey },
        query: queryString,
      }
    );
  };

  getExternalRevenue = async function (enterpriseId, queryString = "") {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/analytics/${enterpriseId}/external/revenue${queryString}`,
      {
        method: "GET",
        headers,
        credentials: "include",
      }
    );
    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la récupération des revenus externes`,
      this.disableToast,
      { body: null, params: { enterpriseId }, query: queryString }
    );
  };

  manageExternalRevenue = async function ({
    enterpriseId,
    revenue,
    queryString = "",
  }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/analytics/${enterpriseId}/external/revenue${queryString}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({ revenue }),
        credentials: "include",
      }
    );

    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la mise à jour des revenus externes`,
      this.disableToast,
      {
        body: { revenue },
        params: { enterpriseId },
        query: queryString,
      }
    );
  };

  getSubscriptionList = async function ({ queryString = "" }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/admin/payments/stripe/subscriptions${queryString}`,
      {
        method: "GET",
        headers,
        credentials: "include",
      }
    );

    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la récupération des abonnements`,
      this.disableToast,
      {
        body: null,
        params: null,
        query: queryString,
      }
    );
  };

  resyncSubscription = async function ({ enterpriseId, queryString = "" }) {
    const headers = this.init();
    const response = await fetch(
      `${this.api_url}/payments/stripe/${enterpriseId}/resync${queryString}`,
      {
        method: "POST",
        headers,
        credentials: "include",
      }
    );

    return manageLidapResponse(
      response,
      `Une erreur s'est produite lors de la synchronisation de la souscription`,
      this.disableToast,
      {
        body: null,
        params: { enterpriseId },
        query: queryString,
      }
    );
  };
}

export async function manageLidapResponse(
  response,
  message,
  disableToast,
  request
) {
  if (!response.ok) {
    const errorData = await response.json();
    const error = errorData.error;
    const fetchError = new FetchError(
      `${message}: ${error ? error.message : "erreur inconnue"}`,
      response.status,
      error.code,
      error.traceId,
      response.url,
      disableToast,
      request
    );

    if (response.status !== 404) {
      const type = (() => {
        if (response.status === 401) {
          return "authn";
        }
        if (response.status === 403) {
          return "authz";
        }
        return "network";
      })();

      faro.api.pushError(fetchError, {
        type: type,
        context: {
          request: JSON.stringify(request),
        },
      });
    }

    throw fetchError;
  }
  return await response.json();
}
