import React, { useEffect, useState } from "react";
import SettingsHeader from "../../token/settingsHeader/SettingsHeader";

import "./Facturation.css";
import ImageImport from "../ImageImport/ImageImport";

import MoyenDePaiement from "../../../feature/MoyenDePaiement/MoyenDePaiement";
import { useUser } from "@/contexts/UserContext";
import { useImages } from "@/hooks/useImages";
import { useUploadImage } from "@/hooks/useUploadImage";
import { notify } from "@/utils/notify";
import { useCurrentInvoiceNumbers } from "@/hooks/useInvoices";
import { DocumentUpdater } from "@/feature/NumerotationQuantity/DocumentUpdater";
import { useGetSettings, useManageSettings } from "@/hooks/useSettings";
import TextArea from "@/components/token/textareas/textareas";
import ButtonTailwind from "@/components/token/button/buttonTailwind";

export default function Facturation() {
  const [conditionsMentions, setConditionsMentions] = useState(null);

  /* Context */
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;
  const images = useImages({
    enterpriseId,
    purpose: "company-logo",
    reactQueryOptions: {
      refetchOnWindowFocus: false,
    },
    errorOnMissing: false,
  });

  const currentNumbers = useCurrentInvoiceNumbers({
    enterpriseId,
  });

  const documentSettings = useGetSettings({
    parentId: enterpriseId,
    settingsType: "document",
  });

  const documentConditions =
    documentSettings.data?.payload?.document?.DOCUMENT_CONDITIONS
      ?.settingsValue || "";

  const manageSettings = useManageSettings(["settings"], undefined, () => {
    notify({
      isSuccess: true,
      message: "Paramètres de facturation mis à jour avec succès",
    });
  });

  /* Updater */
  const uploadImage = useUploadImage(
    ["images", { enterpriseId, purpose: "company-logo" }],
    undefined,
    () =>
      notify({
        isSuccess: true,
        message: "Logo mis à jour avec succès",
      })
  );

  /* States */
  const [companyLogo, setCompanyLogo] = useState(null);

  /* Hooks */

  /* Effects */
  useEffect(() => {
    if ((images.data && !companyLogo) || uploadImage.isError) {
      setCompanyLogo({
        data: {
          imageContent: images.data?.payload?.imageContent,
          id: images.data?.payload?.id,
          purpose: images.data?.payload?.purpose,
          imageFormat: images.data?.payload?.imageFormat,
          file: undefined,
        },
        success: false,
        isDirty: false,
      });
      if (uploadImage.isError) {
        uploadImage.reset();
      }
    }
  }, [images.data, companyLogo, uploadImage]);

  /* Handlers */
  function handleChangeLogo(newLogo) {
    setCompanyLogo(newLogo);
    uploadImage.mutate({
      enterpriseId,
      image: newLogo.data.file,
      purpose: "company-logo",
    });
  }

  function handleSettings() {
    manageSettings.mutate({
      settingsType: "document",
      parentId: enterpriseId,
      settingsKey: "DOCUMENT_CONDITIONS",
      settingsValue: conditionsMentions,
    });
  }

  return (
    <div className="mt-12 mb-24 flex flex-col gap-8 w-full max-w-[768px] mx-auto p-8 bg-white rounded-lg h-max shadow-sm">
      <div>
        <SettingsHeader
          subTitle="Gérez les informations liées à vos factures"
          title="Informations de Facturation"
        />
        <div className="w-full border-t border-grey-400"></div>
      </div>

      <div className="flex flex-col gap-4 border-b border-gray-300 pb-8">
        <div className="text-black">
          <h6>Logo</h6>
          <span>Logo affiché sur l&apos;ensemble des documents</span>
        </div>
        <ImageImport
          logo={companyLogo}
          handleChange={handleChangeLogo}
          alt="company-logo"
          isLoading={images.isLoading}
          isUploading={uploadImage.isLoading}
        />
      </div>

      <MoyenDePaiement />

      <div className="flex flex-col gap-6">
        <TextArea
          label="Conditions de paiement et mentions légales (optionnel)"
          placeholder="Numéro de TVA intracommunaire, mode de paiement, délai de paiement, frais applicables en cas de retard de paiement, etc..."
          value={
            conditionsMentions === null
              ? documentConditions
              : conditionsMentions
          }
          onChange={(e) => setConditionsMentions(e.target.value)}
          maxLength={500}
        />
        <div>
          <ButtonTailwind
            onClick={handleSettings}
            disabled={documentSettings.isLoading}
            loading={manageSettings.isLoading}
          >
            {"Mettre à jour"}
          </ButtonTailwind>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        {currentNumbers.isLoading ? null : (
          <DocumentUpdater
            currentNumbers={currentNumbers}
            isError={currentNumbers.isError}
            isLoading={currentNumbers.isLoading}
          />
        )}
      </div>
    </div>
  );
}
