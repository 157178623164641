import { useUser } from "@/contexts/UserContext";
import { useInvoices } from "../useInvoices";
import { useExternalRevenue } from "../useExternalRevenue";
import { useCallback, useMemo } from "react";

export function useAnalyticsData() {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  /* Defines the number of years in the past we look for data points */
  const yearsOffset = 6;

  // Fetch data from both APIs
  const invoice = useInvoices({
    enterpriseId,
    reactQueryConfig: {
      enabled: !!enterpriseId,
      cacheTime: 5 * 60 * 1000,
    },
  });
  const invoiceContent = invoice.invoicesData?.payload?.items;

  const externalRevenue = useExternalRevenue({
    enterpriseId,
    queryParam: {
      organize: true,
    },
    reactQueryConfig: {
      enabled: !!enterpriseId,
    },
  });

  const revenueContent = externalRevenue?.data?.payload;

  const lidapRevenueData = useMemo(() => {
    const lidapRevenue = [];
    invoiceContent?.forEach((invoice) => {
      if (
        invoice.invoiceStatus === "PAID" &&
        invoice.receiptDate &&
        invoice.documentType !== "QUOTE"
      ) {
        const date = new Date(invoice.receiptDate);
        if (!isNaN(date.getTime())) {
          const a =
            invoice.total.amount *
            (invoice.documentType === "CREDIT_NOTE" ? -1 : 1);

          lidapRevenue.push({
            year: date.getFullYear(),
            month: date.getMonth() + 1, // Month is 1-indexed
            amount: a,
            clientName:
              invoice.additionalData?.find(
                (data) => data.additionalDataType === "RECEIVER_NAME"
              )?.additionalData ||
              invoice.clientId ||
              "Client inconnu",
          });
        }
      }
    });

    return lidapRevenue;
  }, [invoiceContent]);

  // Functions to calculate revenues
  const getRevenueByMonth = useCallback(
    (year = new Date().getFullYear()) => {
      const revenueByMonth = Array(12).fill(0);
      const lidapRevenueByMonth = Array(12).fill(0);
      const externalRevenueByMonth = Array(12).fill(0);

      lidapRevenueData.forEach((data) => {
        if (data.year === year && data.amount !== 0) {
          revenueByMonth[data.month - 1] += data.amount;
          lidapRevenueByMonth[data.month - 1] += data.amount;
        }
      });

      if (revenueContent && revenueContent[year]) {
        for (let i = 1; i < 13; i++) {
          const j = i < 10 ? `0${i}` : i;
          if (revenueContent[year][j]) {
            revenueByMonth[i - 1] += revenueContent[year][j].total;
            externalRevenueByMonth[i - 1] += revenueContent[year][j].total;
          }
        }
      }

      return { revenueByMonth, lidapRevenueByMonth, externalRevenueByMonth };
    },
    [lidapRevenueData, revenueContent]
  );

  const getRevenueByQuarter = useCallback(
    (year = new Date().getFullYear()) => {
      const revenueByQuarter = [0, 0, 0, 0];

      lidapRevenueData.forEach((data) => {
        if (data.year === year && data.amount !== 0) {
          const quarter = Math.floor((data.month - 1) / 3);
          revenueByQuarter[quarter] += data.amount;
        }
      });

      if (revenueContent && revenueContent[year]) {
        for (let i = 1; i < 13; i++) {
          const j = i < 10 ? `0${i}` : i;
          if (revenueContent[year][j]) {
            const quarter = Math.floor((i - 1) / 3);
            revenueByQuarter[quarter] += revenueContent[year][j].total;
          }
        }
      }

      return revenueByQuarter;
    },
    [lidapRevenueData, revenueContent]
  );

  const getRevenueByYear = useCallback(() => {
    const revenueByYear = {};
    const currentYear = new Date().getFullYear();

    // Initialize years from currentYear - yearsOffset to currentYear + yearsOffset
    for (
      let year = currentYear - yearsOffset;
      year <= currentYear + yearsOffset;
      year++
    ) {
      revenueByYear[year] = 0;
    }

    lidapRevenueData.forEach((data) => {
      if (revenueByYear[data.year] !== undefined && data.amount !== 0) {
        revenueByYear[data.year] += data.amount;
      }
    });

    if (revenueContent) {
      for (
        let year = currentYear - yearsOffset;
        year <= currentYear + yearsOffset;
        year++
      ) {
        if (revenueContent[year]) {
          revenueByYear[year] += revenueContent[year].total;
        }
      }
    }

    return revenueByYear;
  }, [lidapRevenueData, revenueContent]);

  const { revenueByMonth, lidapRevenueByMonth, externalRevenueByMonth } =
    useMemo(() => getRevenueByMonth(), [getRevenueByMonth]);
  const monthNamesShort = useMemo(
    () => ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    []
  );

  const getMonthlyData = useCallback(() => {
    const monthsWithRevenue = revenueByMonth
      .map((rev, idx) => ({ rev, idx }))
      .filter(({ rev }) => rev !== 0);

    if (monthsWithRevenue.length === 0) {
      return [];
    } else {
      const firstMonthIndex = monthsWithRevenue[0].idx;
      const lastMonthIndex =
        monthsWithRevenue[monthsWithRevenue.length - 1].idx;

      const monthlyData = [];
      for (let i = firstMonthIndex; i <= lastMonthIndex; i++) {
        monthlyData.push({
          name: monthNamesShort[i],
          revenue: revenueByMonth[i],
        });
      }
      return monthlyData;
    }
  }, [revenueByMonth, monthNamesShort]);

  const monthlyData = useMemo(() => getMonthlyData(), [getMonthlyData]);

  const revenueByQuarter = useMemo(
    () => getRevenueByQuarter(),
    [getRevenueByQuarter]
  );

  const getRevenueByQuarterAndYear = useCallback(() => {
    const totalQuarters = 12;
    const currentDate = new Date();
    const quartersData = [];
    const quarterNameToIndex = {};

    // Generate the last 12 quarters
    for (let i = totalQuarters - 1; i >= 0; i--) {
      const date = new Date(currentDate);
      date.setMonth(currentDate.getMonth() - i * 3);
      const year = date.getFullYear();
      const month = date.getMonth(); // 0-based
      const quarter = Math.floor(month / 3) + 1;
      const key = `Q${quarter} ${year}`;

      // Avoid duplicates in case quarters overlap due to date adjustments
      if (!quarterNameToIndex.hasOwnProperty(key)) {
        quartersData.push({
          name: key,
          revenue: 0,
          date: new Date(year, quarter * 3 - 3), // Start date of the quarter
        });
        quarterNameToIndex[key] = quartersData.length - 1;
      }
    }

    // Aggregate revenue data into quartersData
    lidapRevenueData.forEach((data) => {
      const date = new Date(data.year, data.month - 1);
      const quarter = Math.floor(date.getMonth() / 3) + 1;
      const year = date.getFullYear();
      const key = `Q${quarter} ${year}`;
      if (quarterNameToIndex.hasOwnProperty(key)) {
        const index = quarterNameToIndex[key];
        quartersData[index].revenue += data.amount;
      }
    });

    if (revenueContent) {
      for (const year in revenueContent) {
        for (let i = 1; i <= 12; i++) {
          const j = i < 10 ? `0${i}` : `${i}`;
          if (revenueContent[year][j]) {
            const date = new Date(year, i - 1);
            const quarter = Math.floor(date.getMonth() / 3) + 1;
            const key = `Q${quarter} ${year}`;
            if (quarterNameToIndex.hasOwnProperty(key)) {
              const index = quarterNameToIndex[key];
              quartersData[index].revenue += revenueContent[year][j].total;
            }
          }
        }
      }
    }

    // Sort the quartersData in chronological order
    quartersData.sort((a, b) => a.date - b.date);

    return quartersData;
  }, [lidapRevenueData, revenueContent]);

  const quarterlyData = useMemo(
    () => getRevenueByQuarterAndYear(),
    [getRevenueByQuarterAndYear]
  );

  const revenueByYear = useMemo(() => getRevenueByYear(), [getRevenueByYear]);

  const annualData = useMemo(
    () =>
      Object.keys(revenueByYear)
        .filter(
          (year) =>
            revenueByYear[year] !== 0 && year !== new Date().getFullYear()
        )
        .map((year) => ({
          name: year.toString(),
          revenue: revenueByYear[year],
        }))
        .sort((a, b) => a.name - b.name),
    [revenueByYear]
  );

  const getRevenueByClient = useCallback(() => {
    const revenueByClient = {};

    lidapRevenueData.forEach((data) => {
      const clientName = data.clientName;

      if (!revenueByClient[clientName]) {
        revenueByClient[clientName] = 0;
      }
      revenueByClient[clientName] += data.amount;
    });

    return Object.keys(revenueByClient).map((clientName) => ({
      name: clientName,
      value: revenueByClient[clientName],
    }));
  }, [lidapRevenueData]);

  const revenuePerClient = useMemo(
    () => getRevenueByClient(),
    [getRevenueByClient]
  );

  const totalRevenuePerClient = useMemo(
    () => revenuePerClient.reduce((acc, client) => acc + client.value, 0),
    [revenuePerClient]
  );

  const totalRevenuePerClientDescending = useMemo(
    () => revenuePerClient.sort((a, b) => b.value - a.value),
    [revenuePerClient]
  );

  const computeRevenueReceivedAtMonth = useCallback(
    (month) => revenueByMonth[month] || 0,
    [revenueByMonth]
  );

  const revenueReceivedPerMonth = useMemo(
    () => [
      {
        name: "Janv",
        fullName: "Janvier",
        CA: computeRevenueReceivedAtMonth(0),
      },
      {
        name: "Fevr.",
        fullName: "Février",
        CA: computeRevenueReceivedAtMonth(1),
      },
      { name: "Mars", fullName: "Mars", CA: computeRevenueReceivedAtMonth(2) },
      {
        name: "Avril",
        fullName: "Avril",
        CA: computeRevenueReceivedAtMonth(3),
      },
      { name: "Mai", fullName: "Mai", CA: computeRevenueReceivedAtMonth(4) },
      { name: "Juin", fullName: "Juin", CA: computeRevenueReceivedAtMonth(5) },
      {
        name: "Juil.",
        fullName: "Juillet",
        CA: computeRevenueReceivedAtMonth(6),
      },
      { name: "Août", fullName: "Août", CA: computeRevenueReceivedAtMonth(7) },
      {
        name: "Sept.",
        fullName: "Septembre",
        CA: computeRevenueReceivedAtMonth(8),
      },
      {
        name: "Oct.",
        fullName: "Octobre",
        CA: computeRevenueReceivedAtMonth(9),
      },
      {
        name: "Nov.",
        fullName: "Novembre",
        CA: computeRevenueReceivedAtMonth(10),
      },
      {
        name: "Dec.",
        fullName: "Décembre",
        CA: computeRevenueReceivedAtMonth(11),
      },
    ],
    [computeRevenueReceivedAtMonth]
  );

  /* Revenue of the current year */
  const confirmedRevenue = useMemo(() => {
    const confirmedRevenueOnLidap = invoiceContent?.reduce((acc, curr) => {
      // Filter at the time of accessing the invoice, not inside the reduce function
      const date = new Date(curr.receiptDate);
      if (
        isNaN(date.getTime()) ||
        date.getFullYear() !== new Date().getFullYear()
      )
        return acc;

      if (curr.documentType === "INVOICE" && curr.invoiceStatus === "PAID") {
        return acc + curr.total.amount; // Directly add the total amount of the paid invoice
      } else if (
        curr.documentType === "CREDIT_NOTE" &&
        curr.invoiceStatus === "PAID"
      ) {
        return acc - curr.total.amount; // Subtract the total amount of the paid credit note
      }
      return acc;
    }, 0);

    const confirmedRevenueOnExternal = (() => {
      let t = 0;
      for (const year in revenueContent) {
        if (year === new Date().getFullYear().toString())
          t += revenueContent[year].total;
      }

      return t;
    })();

    return confirmedRevenueOnLidap + confirmedRevenueOnExternal;
  }, [invoiceContent, revenueContent]);

  const expectedRevenue = useMemo(
    () =>
      invoiceContent?.reduce((acc, curr) => {
        if (
          curr.invoiceStatus === "CREATED" &&
          curr.documentType === "INVOICE"
        ) {
          return acc + curr.total.amount;
        } else if (
          curr.invoiceStatus === "CREATED" &&
          curr.documentType === "CREDIT_NOTE"
        ) {
          return acc - curr.total.amount;
        }
        return acc;
      }, 0),
    [invoiceContent]
  );

  const revenueByMonthAbsolute = useMemo(
    () => revenueByMonth.map((rev) => Math.abs(rev)),
    [revenueByMonth]
  );
  const revenueByQuarterAbsolute = useMemo(
    () => revenueByQuarter.map((rev) => Math.abs(rev)),
    [revenueByQuarter]
  );
  const revenueByYearAbsolute = useMemo(
    () => Object.values(revenueByYear).map((rev) => Math.abs(rev)),
    [revenueByYear]
  );
  const maxRevenueByMonth = useMemo(
    () => Math.max(...revenueByMonthAbsolute),
    [revenueByMonthAbsolute]
  );
  const maxRevenueByQuarter = useMemo(
    () => Math.max(...revenueByQuarterAbsolute),
    [revenueByQuarterAbsolute]
  );
  const maxRevenueByYear = useMemo(
    () => Math.max(...revenueByYearAbsolute),
    [revenueByYearAbsolute]
  );
  const maxAbsoluteRevenueByMonth = useMemo(
    () => Math.max(maxRevenueByMonth),
    [maxRevenueByMonth]
  );
  const maxAbsoluteRevenueByQuarter = useMemo(
    () => Math.max(maxRevenueByQuarter),
    [maxRevenueByQuarter]
  );
  const maxAbsoluteRevenueByYear = useMemo(
    () => Math.max(maxRevenueByYear),
    [maxRevenueByYear]
  );

  return {
    monthlyData,
    quarterlyData,
    annualData,
    revenueByQuarter,
    revenueByMonth,
    revenueByYear,
    combinedRevenueData: lidapRevenueData,
    totalRevenuePerClient,
    totalRevenuePerClientDescending,
    revenuePerClient,
    isLoading: invoice.isInvoicesLoading || externalRevenue.isLoading,
    isExternalRevenueLoading: externalRevenue.isLoading,
    revenueReceivedPerMonth,
    confirmedRevenue,
    expectedRevenue,
    lidapRevenueByMonth,
    externalRevenueByMonth,
    getRevenueByMonth,
    maxAbsoluteRevenueByMonth,
    maxAbsoluteRevenueByQuarter,
    maxAbsoluteRevenueByYear,
  };
}
