import { useQuery } from "@tanstack/react-query";
import { stringify } from "@/utils/stringify";
import { manageLidapResponse } from "@/utils/lidapi";

export function useImages({
  enterpriseId,
  purpose,
  queryParam,
  reactQueryOptions,
  errorOnMissing = false,
}) {
  const enterpriseParams = enterpriseId ? `/${enterpriseId}` : "";
  const purposeParams = purpose ? `/${purpose}` : "";
  const queryString = queryParam ? `?${stringify(queryParam)}` : "";

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["images", { enterpriseId, purpose }, queryParam],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.BASE_API_URL}/images${enterpriseParams}${purposeParams}/${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        if (!errorOnMissing && response.status === 404) {
          return {
            payload: {
              imageContent: null,
              id: null,
              purpose: purpose,
              imageFormat: null,
              file: null,
            },
          };
        }
        return manageLidapResponse(response, "Could not load images");
      }
      return manageLidapResponse(response, "Could not load images");
    },
    ...reactQueryOptions,
  });

  return {
    isLoading: isLoading,
    isError: isError,
    data: data,
    error: error,
  };
}
