import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Document,
  Font,
} from "@react-pdf/renderer";
import {
  countriesName,
  countriesCurrency,
} from "@/data/countryCodeCorrespondance";
import InterThin from "@/assets/Fonts/Inter/static/Inter-Light.ttf";
import InterRegular from "@/assets/Fonts/Inter/static/Inter-Regular.ttf";
import IntersSemiBold from "@/assets/Fonts/Inter/static/Inter-SemiBold.ttf";
import InterBold from "@/assets/Fonts/Inter/static/Inter-Bold.ttf";

export default function InvoiceVersionPDF(props) {
  const invoiceContent = props?.documentData;
  const invoiceNumber = invoiceContent.invoiceNumber;
  const services = invoiceContent?.services?.map((service) => {
    //TODO! Align service structure with backend
    if (Object.hasOwnProperty.call(service, "unitPrice")) {
      return service;
    }

    return {
      ...service,
      unitPrice: {
        amount: service.price || 0,
        currency: service.currency,
      },
      quantity: {
        amount: service.quantity || 0,
      },
      vatRate: {
        taxRate: service.VAT,
      },
    };
  });

  const formatNumber = (num) => {
    if (num === undefined || isNaN(num)) return num;
    const parsedNum = parseFloat(num);
    return parsedNum % 1 === 0 ? parsedNum.toFixed(0) : parsedNum.toFixed(2);
  };

  const subtotalHT = invoiceContent?.services?.reduce(
    (acc, service) =>
      acc +
      parseFloat(service?.unitPrice?.amount) *
        parseFloat(service?.quantity?.amount),
    0
  );

  const totalTVA = invoiceContent?.services?.reduce(
    (acc, service) =>
      acc +
      parseFloat(service?.unitPrice?.amount) *
        parseFloat(service?.quantity?.amount) *
        parseFloat(service?.vatRate?.taxRate),
    0
  );

  const totalTTC = (parseFloat(subtotalHT) + parseFloat(totalTVA)).toFixed(2);

  // Now format all the final outputs
  const formattedSubtotalHT = formatNumber(subtotalHT);
  const formattedTotalTVA = formatNumber(totalTVA);
  const formattedTotalTTC = formatNumber(totalTTC);

  const client = props?.documentData?.client;
  const isBillingAddress =
    client && client.addresses
      ? client.addresses.find((address) => address.isBillingAddress)
      : undefined;

  const enterprise = props?.documentData?.enterprise;
  const isBillingEnterpriseAddress =
    enterprise && enterprise.addresses
      ? enterprise.addresses.find((address) => address.isBillingAddress)
      : undefined;

  const senderAddress = invoiceContent?.addresses?.find(
    (address) => address.addressType === "ISSUER_BILLING"
  );

  const receiverAddress = invoiceContent?.addresses?.find(
    (address) => address.addressType === "RECEIVER_BILLING"
  );

  Font.register({
    family: "Inter",
    fonts: [
      { src: InterThin, fontStyle: "normal", fontWeight: 100 },
      { src: InterRegular, fontStyle: "normal", fontWeight: 400 },
      { src: IntersSemiBold, fontStyle: "normal", fontWeight: 600 },
      { src: InterBold, fontStyle: "normal", fontWeight: "bold" },
    ],
  });

  function docTitle() {
    if (invoiceContent?.documentType == "INVOICE") {
      return "Facture";
    } else if (invoiceContent?.documentType == "QUOTE") {
      return "Devis";
    } else if (invoiceContent?.documentType == "CREDIT_NOTE") {
      return "Note de crédit";
    }
  }

  function convertISOToDate(isoDateString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoDateString);

    // Extract day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading 0 if day is less than 10
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading 0 if month is less than 10 (Note: Months are 0-indexed)
    const year = date.getFullYear();

    // Format the components into "DD/MM/YYYY"
    return `${day}/${month}/${year}`;
  }

  const invoiceStyle = StyleSheet.create({
    page: {
      padding: "52px 24px",
    },
    invoicePreviewMainBox: {
      // padding: "64px 40px",
      fontFamily: "Inter",
      fontWeight: "600",
    },
    invoicePreviewHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 24px 48px 24px",
    },
    creditNoteHeadInfos: {
      flexDirection: "column",
      textAlign: "left",
      padding: "16px 24px 0 24px",
    },
    creditReasonTitle: {
      fontSize: "8px",
      fontWeight: "600",
      color: "#000",
      marginBottom: "12px",
    },
    creditReasonWrapper: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      fontWeight: "400",
    },
    creditReason: {
      textAlign: "left",
      fontSize: "8px",
      fontWeight: "400",
    },
    invoiceUserInfosLogo: {
      flexDirection: "row",
    },
    invoiceUserInfosLogoSpace: {
      flexDirection: "row",
      width: "96px",
      height: "48px",
    },
    invoiceLogo: {
      width: "96px",
      height: "48px",
      objectFit: "contain",
    },
    invoiceUserInfosWrapper: {
      flexDirection: "row",
      textAlign: "left",
      fontSize: "8px",
      color: "#8C8C8C",
      padding: "0px 32px 0px 0px",
    },
    invoiceUserInfos: {
      justifyContent: "center",
      flexDirection: "column",
      gap: "2px",
      textAlign: "left",
    },
    invoiceIdBox: {
      marginTop: "8px",
    },
    textInvoice: {
      fontSize: "20px",
      color:
        invoiceContent?.additionalData?.find(
          (data) => data.additionalDataType === "DOCUMENT_TITLE_COLOR"
        )?.additionalData || "#000000", // Default to black if not found
      fontWeight: 700,
    },
    invoiceId: {
      fontSize: "20px",
      color:
        invoiceContent?.additionalData?.find(
          (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
        )?.additionalData || "#737373", // Default to gray if not found
      fontWeight: 700,
    },
    invoiceClientInfosWrapper: {
      flexDirection: "column",
      textAlign: "left",
    },
    invoiceClientInfosGroup: {
      flexDirection: "row",
      marginBottom: "12px",
      width: "256px",
    },
    invoiceClientInfosBox: {
      flexDirection: "column",
      width: "128px",
      maxWidth: "100%",
      gap: "2px",
    },
    invoiceClientInfosTitle: {
      fontSize: "8px",
      fontWeight: "600",
      color: "#000",
    },
    invoiceClientInfos: {
      fontSize: "8px",
      color: "#737373",
      fontWeight: "500",
      lineHeight: "135%",
    },
    invoiceServicesSection: {
      flexDirection: "column",
      textAlign: "left",
      // marginTop: "48px",
      padding: "0 24px",
    },
    namesBox: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      fontWeight: "500",
      paddingBottom: "16px",
      marginBottom: "16px",
      borderBottom: "1px solid #E5E5E5",
    },
    namesBoxRight: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      textAlign: "right",
    },
    service: {
      width: "192px",
      textAlign: "left",
    },
    serviceQuantity: {
      width: "55px",
      textAlign: "right",
    },
    serviceUnit: {
      width: "45px",
      textAlign: "right",
    },
    servicePrice: {
      width: "55px",
      textAlign: "right",
    },
    serviceTVA: {
      width: "40px",
      textAlign: "right",
    },
    serviceTotal: {
      width: "55px",
      textAlign: "right",
    },
    serviceTotalVAT: {
      width: "55px",
      textAlign: "right",
    },
    serviceBox: {
      flexDirection: "column",
      padding: "8px 0px 0px 0px",
    },
    serviceTitleInfos: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      marginBottom: "2px",
    },
    serviceTitle: {
      fontSize: "8px",
      color: "#000",
      fontWeight: "600",
    },
    serviceInfos: {
      flexDirection: "row",
      fontWeight: "400",
    },
    serviceQuantityWrapper: {
      flexDirection: "column",
      justifyContent: "flex-end",
      textAlign: "right",
      gap: "2px",
      width: "61px",
    },
    serviceTitleWrapper: {
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "2px",
      width: "192px",
    },
    bulletPoint: {
      fontSize: "8px",
      fontWeight: "400",
      color: "#000",
      marginRight: "4px",
      marginLeft: "4px",
    },

    serviceDescription: {
      marginTop: "2px",
      textAlign: "left",
      fontSize: "8px",
      fontWeight: "400",
      whiteSpace: "pre-line",
      width: "192px",
    },
    invoiceTotalSection: {
      flexDirection: "column",
      gap: "4px",
      margin: "16px 24px 0 24px",
      paddingTop: "12px",
      borderTop: "1px solid #E5E5E5",
    },
    totalBox: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      fontWeight: "500",
    },
    asterisk: {
      alignContent: "top",
    },
    invoiceTotalsSum: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "8px",
      color:
        // invoiceContent?.additionalData?.find(
        //   (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
        // )?.additionalData ||
        "#000000", // Default to black
      backgroundColor:
        invoiceContent?.additionalData?.find(
          (data) => data.additionalDataType === "DOCUMENT_BODY_COLOR"
        )?.additionalData || "#F5F5F5", // Default to light gray
      margin: "16px 8px 0px 8px",
      padding: "12px 16px",
      borderRadius: "4px",
    },
    vatDisclaimer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "between",
      fontSize: "6px",
      padding: "0 4px",
      margin: "8px 16px 0 16px",
    },
    paymentSection: {
      fontSize: "8px",
      flexDirection: "column",
      textAlign: "left",
      marginTop: "48px",
      padding: "0 24px",
    },
    invoiceh6: {
      fontSize: "8px",
      fontWeight: 600,
      color: "#000",
      marginBottom: "8px",
    },
    paymentInfos: {
      flexDirection: "column",
      gap: "6px",
    },
    bank: {
      flexDirection: "row",
      gap: "12px",
    },
    imgBox: {
      height: "12px",
      width: "18px",
      justifyContent: "center",
    },
    bankImage: {
      height: "12px",
      objectFit: "contain",
    },
    bankInfosComponent: {
      flexDirection: "row",
      gap: "4px",
    },
    bankInfosTitle: {
      fontSize: "8px",
      fontWeight: "600",
      color: "#000",
    },
    paypal: {
      flexDirection: "row",
      gap: "12px",
      fontWeight: "400",
    },
    conditionsSection: {
      flexDirection: "column",
      margin: "24px 24px 0 24px",
      textAlign: "left",
      fontSize: "8px",
    },
    conditionsInfos: {
      fontSize: "8px",
      fontWeight: "400",
    },
    invoiceSignature: {
      textAlign: "right",
      justifyContent: "right",
      fontSize: "8px",
      fontWeight: "bold",
      margin: "24px 24px 0 24px",
    },
    pageNumber: {
      position: "absolute",
      fontSize: "10px",
      bottom: 24,
      right: 24,
      fontWeight: "400",
    },
  });

  const unitPriceReference = [
    { unitValue: "FIXED_PRICE", label: "Forfait", pluralLabel: "Forfaits" },
    { unitValue: "DAY", label: "Jour", pluralLabel: "Jours" },
    { unitValue: "HOUR", label: "Heure", pluralLabel: "Heures" },
    { unitValue: "UNIT", label: "Unité", pluralLabel: "Unités" },
    { unitValue: "PERSON", label: "Personne", pluralLabel: "Personnes" },
    { unitValue: "WORD", label: "Mot", pluralLabel: "Mots" },
    { unitValue: "CHARACTER", label: "Caractère", pluralLabel: "Caractères" },
    { unitValue: "ARTICLE", label: "Article", pluralLabel: "Articles" },
    { unitValue: "PAGE", label: "Page", pluralLabel: "Pages" },
    { unitValue: "CLICK", label: "Clic", pluralLabel: "Clics" },
  ];

  const getUnitLabel = (unit, quantity) => {
    const unitInfo = unitPriceReference.find(
      (unitPrice) => unitPrice.unitValue === unit
    );
    return quantity > 1 && unitInfo?.pluralLabel
      ? unitInfo.pluralLabel
      : unitInfo?.label;
  };

  const logoSrc =
    invoiceContent?.Logo?.imageFormat && invoiceContent?.Logo?.imageContent
      ? `data:image/${invoiceContent?.Logo?.imageFormat};base64,${invoiceContent?.Logo?.imageContent}`
      : undefined;

  const tvaVentilation = [];

  const PaymentMethodEquivalent = {
    IBAN: "IBAN",
    PAYMENT_LINK: "Lien de paiement",
    CHEQUE: "Chèque",
    CASH: "Liquide",
  };

  return (
    <Document>
      <Page style={invoiceStyle.page}>
        <View
          style={invoiceStyle.invoicePreviewMainBox}
          className={props?.documentData?.className}
        >
          <View style={invoiceStyle.invoicePreviewHeader} fixed>
            <View style={invoiceStyle.invoiceInfos}>
              <View
                style={{
                  ...invoiceStyle.invoiceUserInfosWrapper,
                  ...(logoSrc
                    ? { padding: "0px 32px 0px 0px" }
                    : { padding: "0px 40px 0px 0px" }),
                }}
              >
                {logoSrc && (
                  <View style={invoiceStyle.invoiceUserInfosLogo}>
                    <Image
                      src={logoSrc}
                      style={invoiceStyle.invoiceLogo}
                    ></Image>
                  </View>
                )}
              </View>

              <View style={invoiceStyle.invoiceIdBox}>
                <Text style={invoiceStyle.textInvoice}>{docTitle()}</Text>
                <Text style={invoiceStyle.invoiceId}>{invoiceNumber}</Text>
              </View>
            </View>

            <View style={invoiceStyle.invoiceClientInfosWrapper}>
              <View style={invoiceStyle.invoiceClientInfosGroup}>
                <View style={invoiceStyle.invoiceClientInfosBox}>
                  <Text style={invoiceStyle.invoiceClientInfosTitle}>
                    Date d&apos;émission
                  </Text>
                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {/* {dayjs(invoiceContent?.issuingDate).format("DD/MM/YYYY")} */}
                    {invoiceContent?.issuingDate}
                  </Text>
                </View>
                {invoiceContent?.dueDate &&
                  invoiceContent?.dueDate !== "NaN/NaN/NaN" && (
                    <View style={invoiceStyle.invoiceClientInfosBox}>
                      <Text style={invoiceStyle.invoiceClientInfosTitle}>
                        Date d&apos;échéance
                      </Text>

                      <Text style={invoiceStyle.invoiceClientInfos}>
                        {/* {dayjs(invoiceContent?.dueDate).format("DD/MM/YYYY")} */}
                        {invoiceContent?.dueDate}
                      </Text>
                    </View>
                  )}
              </View>

              <View style={invoiceStyle.invoiceClientInfosGroup}>
                <View style={invoiceStyle.invoiceClientInfosBox}>
                  <Text style={invoiceStyle.invoiceClientInfosTitle}>De</Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {
                      invoiceContent.additionalData.find(
                        (data) => data.additionalDataType === "ISSUER_NAME"
                      )?.additionalData
                    }
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {senderAddress?.address?.streetName}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {senderAddress?.address?.postalCode},{" "}
                    {senderAddress?.address?.city}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {countriesName[senderAddress?.address?.country]}
                  </Text>
                  <Text style={invoiceStyle.invoiceClientInfos}>
                    N°{" "}
                    {invoiceContent?.enterpriseNumber?.type == "SIREN" &&
                      "SIREN"}
                    : {invoiceContent?.enterpriseNumber?.value}{" "}
                  </Text>
                  <Text style={invoiceStyle.invoiceClientInfos}>
                    N° TVA: {invoiceContent?.enterpriseNumber?.vat}
                  </Text>
                  <View>
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {
                        invoiceContent.additionalData?.filter(
                          (data) => data.additionalDataType === "ISSUER_EMAIL"
                        )[0]?.additionalData
                      }
                    </Text>
                    <Text style={invoiceStyle.invoiceClientInfos}>
                      {
                        invoiceContent.additionalData?.filter(
                          (data) => data.additionalDataType === "ISSUER_PHONE"
                        )[0]?.additionalData
                      }
                    </Text>
                  </View>
                </View>

                <View style={invoiceStyle.invoiceClientInfosBox}>
                  <Text style={invoiceStyle.invoiceClientInfosTitle}>À</Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {
                      invoiceContent.additionalData.find(
                        (data) => data.additionalDataType === "RECEIVER_NAME"
                      )?.additionalData
                    }
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {receiverAddress?.address?.streetName}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {receiverAddress?.address?.postalCode},{" "}
                    {receiverAddress?.address?.city}
                  </Text>

                  <Text style={invoiceStyle.invoiceClientInfos}>
                    {countriesName[receiverAddress?.address?.country]}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {invoiceContent?.documentType === "CREDIT_NOTE" && (
            <View style={invoiceStyle.creditNoteHeadInfos}>
              <Text style={invoiceStyle.creditReasonTitle}>
                Note de crédit sur facture{" "}
                {invoiceContent?.selectedInvoice?.invoiceNumber
                  ? invoiceContent?.selectedInvoice.invoiceNumber
                  : ""}{" "}
                {invoiceContent?.selectedInvoice?.issuingDate ? "du" : null}{" "}
                {invoiceContent?.selectedInvoice?.issuingDate
                  ? convertISOToDate(
                      invoiceContent?.selectedInvoice?.issuingDate
                    )
                  : null}
              </Text>
              <View>
                <Text style={invoiceStyle.creditReasonTitle}>
                  Raison de l&apos;émission de cette note de crédit
                </Text>
                <View style={invoiceStyle.creditReasonWrapper}>
                  <Text style={invoiceStyle.bulletPoint}>•</Text>
                  <Text style={invoiceStyle.creditReason}>
                    {
                      invoiceContent?.additionalData?.filter(
                        (data) =>
                          data.additionalDataType === "CREDIT_NOTE_REASON"
                      )[0]?.additionalData
                    }
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View style={invoiceStyle.invoiceServicesSection}>
            <View style={invoiceStyle.namesBox}>
              <View style={invoiceStyle.service}>
                <Text>Service(s)</Text>
              </View>
              <View style={invoiceStyle.namesBoxRight}>
                <Text style={invoiceStyle.serviceQuantity}>Quantité</Text>
                <Text style={invoiceStyle.serviceUnit}>Unité</Text>
                <Text style={invoiceStyle.servicePrice}>Coût unit.</Text>
                <Text style={invoiceStyle.serviceTVA}>TVA</Text>
                <Text style={invoiceStyle.serviceTotal}>Total HT</Text>
                <Text style={invoiceStyle.serviceTotal}>Total TVA</Text>
              </View>
            </View>
            {services?.map((service) => (
              <View style={invoiceStyle.serviceBox} key={service.id}>
                <View style={invoiceStyle.serviceTitleInfos}>
                  <View style={invoiceStyle.serviceTitleWrapper}>
                    <Text style={invoiceStyle.serviceTitle}>
                      {service.title}
                    </Text>
                    <Text style={invoiceStyle.serviceDescription}>
                      {service.description}
                    </Text>
                  </View>

                  <View style={invoiceStyle.serviceInfos}>
                    <Text style={invoiceStyle.serviceQuantity}>
                      {service.quantity.amount}
                    </Text>
                    <Text style={invoiceStyle.serviceUnit}>
                      {service?.quantity?.unit
                        ? getUnitLabel(
                            service?.quantity?.unit,
                            service?.quantity?.amount
                          )
                        : ""}
                    </Text>
                    <Text style={invoiceStyle.servicePrice}>
                      {service.unitPrice.amount}
                      {countriesCurrency[service?.unitPrice?.currency]}
                    </Text>
                    <Text style={invoiceStyle.serviceTVA}>
                      {service?.vatRate?.taxRate * 100}%
                    </Text>
                    <Text style={invoiceStyle.serviceTotal}>
                      {formatNumber(
                        service.quantity.amount * service.unitPrice.amount
                      )}
                      {countriesCurrency[service?.unitPrice?.currency]}
                    </Text>
                    <Text style={invoiceStyle.serviceTotalVAT}>
                      {service?.quantity?.amount && service?.unitPrice?.amount
                        ? formatNumber(
                            service?.quantity?.amount *
                              service?.unitPrice?.amount *
                              service?.vatRate?.taxRate +
                              service?.quantity?.amount *
                                service?.unitPrice?.amount
                          )
                        : 0}
                      {countriesCurrency[service?.unitPrice?.currency]}
                    </Text>
                  </View>
                </View>
              </View>
            ))}
          </View>

          {services &&
            services.map((service) => {
              const vatRate = service?.vatRate?.taxRate || 0;
              if (vatRate > 0) {
                const existingVentilation = tvaVentilation.find(
                  (vent) => vent.VAT === vatRate
                );
                const serviceTotal =
                  service?.quantity?.amount * service?.unitPrice?.amount;
                const serviceTVA = serviceTotal * vatRate;

                if (existingVentilation) {
                  existingVentilation.total += serviceTVA;
                } else {
                  tvaVentilation.push({
                    VAT: vatRate,
                    total: serviceTVA,
                  });
                }
              }
            })}

          <View style={invoiceStyle.invoiceTotalSection}>
            <View style={invoiceStyle.totalBox}>
              <Text>Sous-total (HT)</Text>
              <Text>
                {formattedSubtotalHT}
                {countriesCurrency[invoiceContent?.total?.currency]}
              </Text>
            </View>
            {tvaVentilation.length > 0 && (
              <View
              // className="flex flex-row justify-between text-[8px] text-neutral-500"
              >
                {tvaVentilation.map((vent, index) => (
                  <View key={`vent-${index}`} style={invoiceStyle.totalBox}>
                    <View>
                      <Text>TVA ({vent.VAT * 100}%)</Text>
                    </View>
                    <View>
                      <Text>
                        {formatNumber(vent.total)}{" "}
                        {
                          countriesCurrency[
                            invoiceContent?.totalWithTax?.currency
                          ]
                        }
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
            <View style={invoiceStyle.totalBox}>
              <Text>
                Total TVA
                {invoiceContent?.additionalData?.find(
                  (type) => type?.additionalDataType == "VAT_DISCLAIMER"
                )?.additionalData ? (
                  <Text style={invoiceStyle.asterisk}>*</Text>
                ) : (
                  ""
                )}
              </Text>
              <Text>
                {formattedTotalTVA}
                {countriesCurrency[invoiceContent?.totalWithTax?.currency]}
              </Text>
            </View>
          </View>

          <View style={invoiceStyle.invoiceTotalsSum}>
            <Text>Total (TTC)</Text>
            <Text>
              {formattedTotalTTC}
              {countriesCurrency[invoiceContent?.totalRounded?.currency]}
            </Text>
          </View>
          <Text style={invoiceStyle.vatDisclaimer}>
            {
              invoiceContent?.additionalData?.find(
                (type) => type?.additionalDataType == "VAT_DISCLAIMER"
              )?.additionalData
            }
          </Text>

          <View style={invoiceStyle.paymentSection} wrap={false}>
            <Text style={invoiceStyle.invoiceh6}>Moyen de paiement</Text>
            <View style={invoiceStyle.paymentInfos}>
              {invoiceContent?.additionalData
                ?.filter(
                  (account) =>
                    account.additionalDataType === "PAYMENT_METHOD" &&
                    (account.externalId || account.id)
                )
                ?.map((account, index) => (
                  <Text
                    key={"paiement" + index}
                    style={invoiceStyle.bankInfosComponent}
                  >
                    <Text style={invoiceStyle.bankInfosTitle}>
                      {/* {account.additionalDataSubType}:{" "} */}
                      {PaymentMethodEquivalent[account.additionalDataSubType] ||
                        account.additionalDataSubType}
                      :{" "}
                    </Text>
                    {account.additionalData}
                  </Text>
                ))}
            </View>
          </View>

          {invoiceContent?.additionalData?.find(
            (condition) =>
              condition?.additionalDataType === "CREDIT_NOTE_CONDITIONS"
          )?.additionalData ||
            (invoiceContent?.additionalData?.find(
              (condition) =>
                condition?.additionalDataType === "INVOICE_CONDITIONS"
            )?.additionalData && (
              <View style={invoiceStyle.conditionsSection}>
                <Text style={invoiceStyle.invoiceh6}>Conditions</Text>
                <Text style={invoiceStyle.conditionsInfos}>
                  {/* {props?.documentData?.conditionsMentions} */}
                  {invoiceContent?.additionalData?.find(
                    (condition) =>
                      condition?.additionalDataType === "CREDIT_NOTE_CONDITIONS"
                  )?.additionalData ||
                    invoiceContent?.additionalData?.find(
                      (condition) =>
                        condition?.additionalDataType === "INVOICE_CONDITIONS"
                    )?.additionalData}
                </Text>
              </View>
            ))}

          {invoiceContent?.documentType === "CREDIT_NOTE" ? (
            <View style={invoiceStyle.conditionsSection}>
              <Text style={invoiceStyle.invoiceh6}>
                Moyen d&apos;application du crédit
              </Text>
              <Text style={invoiceStyle.conditionsInfos}>
                {
                  invoiceContent?.additionalData?.find(
                    (condition) =>
                      condition?.additionalDataType ===
                      "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
                  )?.additionalData
                }
              </Text>
            </View>
          ) : null}

          {invoiceContent.documentType !== "INVOICE" && (
            <View style={invoiceStyle.invoiceSignature}>
              <Text style={invoiceStyle.signatureH6}>Signature</Text>
            </View>
          )}
        </View>
        <Text
          style={invoiceStyle.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
