import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lidapi from "@/utils/lidapi";

export function useCreateUpdatePaymentInformation(
  queryKeyToInvalidate,
  onSuccess
) {
  const queryClient = useQueryClient();
  const api = new Lidapi();

  const action = useMutation({
    mutationFn: async ({ stakeholderId, id, paymentInformation }) =>
      id
        ? await api.updatePaymentInformation(
            stakeholderId,
            id,
            paymentInformation
          )
        : await api.createPaymentInformation(stakeholderId, paymentInformation),
    onSuccess: (data) => {
      if (queryKeyToInvalidate && queryKeyToInvalidate.length > 0) {
        queryClient.invalidateQueries({
          queryKey: [...queryKeyToInvalidate],
        });
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return action;
}
