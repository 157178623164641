/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-useless-escape */
import TextArea from "@/components/token/textareas/textareas";
import { useInputValidation, useTextUtf8 } from "@/hooks/validation/useRegexp";

export default function ServiceDescription({
  serviceDescription,
  setDescriptionError,
  setServiceDescription,
  descriptionError,
  ...props
}) {
  const regexp = useTextUtf8();
  const handleChange = useInputValidation({
    setInputValue: setServiceDescription,
    setErrorValue: setDescriptionError,
  });

  return (
    <TextArea
      name="description"
      label="Description (optionnel)"
      placeholder="Indiquez une description..."
      value={serviceDescription}
      onChange={(e) => handleChange(e, regexp)}
      error={descriptionError}
      {...props}
    />
  );
}
