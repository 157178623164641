import React, { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchCombobox({
  options,
  onSelect,
  label,
  selectedValue,
  displayCreationElement,
  creationElement,
  placeholder,
  accessor,
}) {
  const [query, setQuery] = useState("");
  const [openOptions, setOpenOptions] = useState(false);

  const filteredOptions =
    query === ""
      ? options
      : options?.filter((e) =>
          e[accessor]?.toLowerCase()?.includes(query?.toLowerCase())
        );

  const SkeletonOption = () => (
    <div className="animate-pulse mx-2 flex items-center justify-center">
      <div className="h-7 bg-neutral-200 dark:bg-neutral-800 rounded w-full py-2 pl-10 pr-4 mx-4"></div>
    </div>
  );

  return (
    <Combobox
      value={selectedValue}
      onChange={(value) => {
        onSelect(value);
        setOpenOptions(false);
      }}
      onBlur={() => setOpenOptions(false)}
    >
      {({ open }) => (
        <div>
          {label && (
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 w-max">
              {label}
            </Combobox.Label>
          )}
          <div className="relative mt-1">
            <Combobox.Input
              className="w-full rounded-md border border-neutral-300 pl-3 pr-10 py-2 text-left shadow-sm focus:ring-neutral-900 focus:outline-none focus:border-neutral-500 sm:text-sm cursor-pointer placeholder:text-black box-border"
              displayValue={(option) => option[accessor] || option.name}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
              onClick={() => setOpenOptions(!openOptions)}
            />
            <Combobox.Button
              className="absolute inset-y-0 right-0 flex items-center pr-2"
              onClick={() => setOpenOptions(!openOptions)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {openOptions ? (
                <ChevronUpIcon className="h-4 w-4 text-gray-500" />
              ) : (
                <ChevronDownIcon className="h-4 w-4 text-gray-400" />
              )}
            </Combobox.Button>

            <Transition
              show={openOptions}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options
                className="absolute mt-1 max-h-48 w-full z-[1000] rounded-md bg-white shadow-lg border border-neutral-300 focus:outline-none sm:text-sm flex flex-col overflow-hidden"
                static={openOptions}
                onMouseDown={(e) => e.preventDefault()}
              >
                {displayCreationElement && (
                  <div className="border-b border-neutral-300 dark:border-neutral-800 sticky top-0 bg-neutral-100 z-50 ">
                    <button
                      type="button"
                      className="flex items-center gap-2 w-full py-2 px-4 rounded-md text-neutral-700 hover:bg-neutral-50 hover:text-black dark:hover:bg-neutral-900 dark:hover:text-white"
                      onClick={() => {
                        displayCreationElement(true), setOpenOptions(false);
                      }}
                    >
                      <PlusIcon className="h-4 w-4" strokeWidth={2.5} />
                      {creationElement}
                    </button>
                  </div>
                )}
                {options ? (
                  <div className="max-h-full overflow-y-scroll">
                    {filteredOptions?.map((e) => (
                      <Combobox.Option
                        key={e.id}
                        value={e}
                        className={({ active }) =>
                          classNames(
                            active ? "bg-neutral-100" : "text-gray-900",
                            "relative cursor-pointer select-none py-2 pl-10 pr-4"
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {e[accessor] || e.name}
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </div>
                ) : (
                  <div className="max-h-full overflow-y-scroll space-y-2 mt-1">
                    <SkeletonOption />
                    <SkeletonOption />
                    <SkeletonOption />
                    <SkeletonOption />
                  </div>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Combobox>
  );
}
