import Presentation from "@/Pages/Presentation";
import InvoiceIllustration from "@/assets/Images/InvoiceIllustration.svg";
import { useNavigate } from "react-router-dom";

export function InvoiceStart() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row w-full h-full overflow-y-scroll">
      <Presentation
        title={`Créez vos premiers documents`}
        text={`Vos factures, devis et notes de crédit centralisés en un seul endroit`}
        illustration={InvoiceIllustration}
        buttonContent={"Nouveau document"}
        onClick={() => navigate("/invoices/create")}
      />
    </div>
  );
}
