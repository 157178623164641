/* eslint-disable no-irregular-whitespace */

import { uniqueChars } from "@/utils/uniqueChars";

/* eslint-disable no-useless-escape */
export function useTextUtf8() {
  const regexp =
    /([\p{L}0-9\.\\\/+!?@_()=',{}\[\]<>\$€:&'‘’“”`´"%;:*~#°^•  –|‚„…†‡‰‹—˜™›¡¢£¤¥¦§¨©«¬­®¯±²³¶·¸¹»¼½¾¿×÷\n-]+)/gu;

  return regexp;
}

export function useInputValidation({ setInputValue, setErrorValue }) {
  const handleChange = (e, regexp) => {
    const value = e.target.value;
    const invalid = uniqueChars(value?.replace(regexp, ""));
    if (invalid) {
      setErrorValue(`Caractères spéciaux non autorisés: ${invalid}`);
    } else {
      setErrorValue("");
    }
    setInputValue(value);
  };

  return handleChange;
}
