import React from "react";
import "./SettingsHeader.css";

export default function SettingsHeader(props) {
  return (
    <div className="flex flex-col space-y-1 pb-6 w-full">
      <p className="text-lg font-bold text-black">{props.title}</p>
      <span className="text-base text-neutral-500">{props.subTitle}</span>
    </div>
  );
}
