import React, { useEffect, useState } from "react";
import ButtonsList from "../../token/ButtonsList/ButtonsList";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import {
  ChevronUpIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Box from "@mui/material/Box";
import styles from "./ServiceForm.module.css";
import InputPriceSelector from "../../../components/token/Input/inputPriceSelector/InputPriceSelector";
import { useUser } from "@/contexts/UserContext";
import { unitPriceReference } from "@/components/elements/Article/Units";
import ServiceTitle from "../Document/Service/ServiceTitle";
import ServiceDescription from "../Document/Service/ServiceDescription";
import { useCreateUpdateArticle } from "@/hooks/useCreateUpdateDeleteArticles";
import { notify } from "@/utils/notify";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import Modal from "@/components/elements/Modal/Modal";
import Select from "@/components/token/select/select";

export default function ServiceForm(props) {
  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  const [isDisplayed, setIsDisplayed] = useState(props.isDisplayed);
  const [displaySaveModal, setDisplaySaveModal] = useState(false);

  const [serviceTitle, setServiceTitle] = useState(props.title || "");
  const [serviceQuantity, setServiceQuantity] = useState(
    props.quantityAmount || 1
  );
  const [quantityUnit, setQuantityUnit] = useState(
    props.quantityUnit || "Unité"
  ); // ["Forfait", "Jour", "Heure", "Unité", "Personne", "Mot", "Caractère", "Article", "Page", "Clic"]
  const [servicePrice, setServicePrice] = useState(props.priceAmount || "");
  const [serviceCurrency, setServiceCurrency] = useState("EUR"); // ["EUR", "USD", "CHF", "CAD"]
  const [serviceDescription, setServiceDescription] = useState(
    props.description || ""
  );
  const [serviceDescriptionError, setServiceDescriptionError] = useState("");
  const [serviceVAT, setServiceVAT] = useState(props.VATRate || 0);
  const [vatCountry, setVatCountry] = useState(
    userContext?.state?.user?.country || "BE"
  );

  // GESTION DES REQUIS ET ERREURS
  const [TitleError, setTitleError] = useState("");
  const [isQuantityError, setIsQuantityError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);
  const [VATError, setVATError] = useState("");
  const [isVATFromButton, setIsVATFromButton] = useState(true);

  const [updateCreateChoice, setUpdateCreateChoice] = useState({
    name: "Nouveau service",
    action: "CREATE",
  });

  const id = props.id;
  const TemplateId = props.TemplateId;

  const createUpdateArticle = useCreateUpdateArticle(["articles"], null, () => {
    notify({
      isSuccess: true,
      message:
        updateCreateChoice.action == "CREATE"
          ? "Article créé avec succès"
          : "Article mis à jour avec succès",
    }),
      setDisplaySaveModal(false);
  });

  useEffect(() => {
    const newService = {
      title: serviceTitle,
      quantity: {
        amount: serviceQuantity,
        unit:
          unitPriceReference.find(
            (unitPrice) => unitPrice.label == quantityUnit
          )?.unitValue || "UNIT",
      },
      unitPrice: {
        amount: servicePrice,
        currency: props.currency,
      },
      description: serviceDescription,
      vatRate: { taxRate: serviceVAT, countryOrRegion: vatCountry },
      isDisplayed: isDisplayed,
      id: id,
      TemplateId: TemplateId,
    };

    props.manageServices(id, newService);
  }, [
    serviceTitle,
    serviceQuantity,
    servicePrice,
    serviceDescription,
    serviceVAT,
    quantityUnit,
    serviceCurrency,
    props.currency,
  ]);

  function saveService(action) {
    const newService = {
      title: serviceTitle,
      description: serviceDescription,
      quantity: {
        amount: serviceQuantity,
        unit:
          unitPriceReference.find(
            (unitPrice) => unitPrice.label == quantityUnit
          )?.unitValue || "UNIT",
      },
      unitPrice: {
        amount: servicePrice,
        currency: props.currency,
      },
      vatRate: {
        taxRate: serviceVAT,
        countryOrRegion: vatCountry,
      },
    };
    if (
      action == "CREATE" &&
      serviceTitle &&
      serviceQuantity &&
      servicePrice &&
      (serviceVAT || serviceVAT === 0)
    ) {
      createUpdateArticle.mutate({
        enterpriseId: enterpriseId,
        article: newService,
      });
    }
    if (
      action == "UPDATE" &&
      serviceTitle &&
      serviceQuantity &&
      servicePrice &&
      serviceVAT
    ) {
      createUpdateArticle.mutate({
        enterpriseId: enterpriseId,
        article: newService,
        id: TemplateId,
      });
    }
  }

  function Valider() {
    const isTitleError = serviceTitle === "" || TitleError;
    const isQuantityError = serviceQuantity === null; // or other appropriate checks for a number
    const isPriceError = servicePrice === "";
    const isVATError = serviceVAT === "";
    const isDescriptionError = !!serviceDescriptionError;

    setTitleError(isTitleError);
    setIsQuantityError(isQuantityError);
    setIsPriceError(isPriceError);
    setVATError(isVATError);

    const isValid = !(
      isTitleError ||
      isQuantityError ||
      isPriceError ||
      isVATError ||
      serviceVAT === "Autre" ||
      isDescriptionError
    );

    // If valid, change the display mode
    setIsDisplayed(!isValid);

    props.setServicesIsOk(isValid);
  }

  return (
    <>
      {displaySaveModal && (
        <Modal>
          {TemplateId ? (
            <>
              <h1 className="text-3xl">Sauvegarder ce service ?</h1>
              <Select
                data={[
                  { name: "Mettre à jour ce service", action: "UPDATE" },
                  { name: "Nouveau service", action: "CREATE" },
                ]}
                selectedItem={updateCreateChoice}
                setSelectedItem={setUpdateCreateChoice}
                label="Action"
              ></Select>
              <div className="flex justify-end gap-4">
                <ButtonTailwind
                  variant="outlined"
                  onClick={() => setDisplaySaveModal(false)}
                >
                  Annuler
                </ButtonTailwind>
                <ButtonTailwind
                  variant={"filled"}
                  onClick={() => saveService(updateCreateChoice.action)}
                >
                  Enregistrer
                </ButtonTailwind>
              </div>
            </>
          ) : (
            <>
              <h1 className="text-3xl">
                Enregistrer ce service dans <br />
                votre bibliothèque ?
              </h1>
              <p>
                L&apos;enregistrement d&apos;un service permet de
                l&apos;exploiter de
                <br />
                nouveau à l&apos;avenir pour gagner du temps. Vous pourrez le
                <br /> modifier plus tard si besoin.
              </p>

              <div className="flex justify-end gap-4">
                <ButtonTailwind
                  variant="outlined"
                  onClick={() => setDisplaySaveModal(false)}
                >
                  Annuler
                </ButtonTailwind>
                <ButtonTailwind
                  variant={"filled"}
                  onClick={() => saveService(updateCreateChoice.action)}
                >
                  Enregistrer
                </ButtonTailwind>
              </div>
            </>
          )}
        </Modal>
      )}{" "}
      {isDisplayed ? (
        <div className="flex flex-col w-full h-max border border-gray-300 rounded-md overflow-hidden">
          <button className="flex justify-between items-center w-full bg-gray-50 border-b border-gray-300 py-3 px-4 max-w-full">
            <div className="flex justify-between items-center gap-2 w-full max-w-72">
              {/* <DragIndicatorIcon sx={{ width: "16px", color: "darkGrey" }} /> */}
              <span className="text-sm text-current text-black text-left truncate max-w-full">
                {serviceTitle || "Titre"}
              </span>
            </div>
            <div className="flex justify-between items-center gap-3 max-w-full">
              <ArrowDownOnSquareIcon
                className="h-4 w-4 text-gray-400 stroke-2"
                onClick={() => setDisplaySaveModal(true)}
              />
              <DeleteOutlineRoundedIcon
                sx={{ width: "16px", color: "darkGrey" }}
                onClick={() => props.deleteService(id)}
              />
              <ContentCopyRoundedIcon
                sx={{ width: "16px", color: "darkGrey" }}
                onClick={() => props.duplicateService(id)}
              />
              <ChevronUpIcon
                className="h-4 w-4 text-gray-400 stroke-2"
                onClick={() => {
                  Valider();
                  setIsVATFromButton(
                    !(
                      serviceVAT !== 0 &&
                      serviceVAT !== 0.06 &&
                      serviceVAT !== 0.12 &&
                      serviceVAT !== 0.21
                    )
                  );
                }}
              />
            </div>
          </button>
          <form
            onSubmit={props.onSubmit}
            className="flex flex-col w-full gap-8 px-4 py-4"
          >
            <Box className={"flex flex-col gap-4"}>
              <ServiceTitle
                TitleError={TitleError}
                setServiceTitle={setServiceTitle}
                serviceTitle={serviceTitle}
                setTitleError={setTitleError}
                errorOnFocus={true}
              />
              <ServiceDescription
                descriptionError={serviceDescriptionError}
                serviceDescription={serviceDescription}
                setDescriptionError={setServiceDescriptionError}
                setServiceDescription={setServiceDescription}
                name="description"
                label="Description (optionnel)"
                placeholder="Indiquez une description..."
                errorOnFocus={true}
              />
              <div className={styles.QuantityBox}>
                <InputPriceSelector
                  label="Quantité"
                  placeholder="1"
                  isRequired={true}
                  name="price"
                  type="number"
                  id="QuantityInput"
                  numberValue={serviceQuantity}
                  onChange={(value) => setServiceQuantity(value)}
                  selectValue={
                    unitPriceReference.find(
                      (unitPrice) => unitPrice.unitValue === quantityUnit
                    )?.label || quantityUnit
                  }
                  onSelect={(e) => setQuantityUnit(e.target.value)}
                  selectLabel="Unité"
                  options={[
                    "Unité",
                    "Forfait",
                    "Jour",
                    "Heure",
                    "Personne",
                    "Mot",
                    "Caractère",
                    "Article",
                    "Page",
                    "Clic",
                  ]}
                  error={isQuantityError}
                />
                <InputPriceSelector
                  label="Prix unitaire"
                  placeholder="0.00"
                  isRequired={true}
                  name="price"
                  type="number"
                  numberValue={servicePrice}
                  onChange={(value) => setServicePrice(value)}
                  selectValue={props.currency}
                  onSelect={(e) => props.setCurrency(e.target.value)}
                  selectLabel="Currency"
                  error={isPriceError}
                  options={["EUR", "USD", "CHF", "CAD"]}
                />
              </div>
              <ButtonsList
                setServiceVAT={setServiceVAT}
                serviceVAT={serviceVAT}
                error={VATError}
                isVATFromButton={isVATFromButton}
                setIsVATFromButton={setIsVATFromButton}
                vatCountry={vatCountry}
              ></ButtonsList>
            </Box>
          </form>
        </div>
      ) : (
        <button
          className="flex justify-between items-center w-full rounded-md border border-gray-300 bg-gray-50
    py-3 px-4 max-w-full"
        >
          <div className="flex justify-between items-center gap-2 w-full max-w-72">
            {/* <DragIndicatorIcon sx={{ width: "16px", color: "darkGrey" }} /> */}
            <span className="text-sm text-current text-left truncate max-w-full">
              {serviceTitle || "Titre"}
            </span>
          </div>
          <div className="flex justify-between items-center gap-3">
            <ArrowDownOnSquareIcon
              className="h-4 w-4 text-gray-400 stroke-2"
              onClick={() => setDisplaySaveModal(true)}
            />
            <DeleteOutlineRoundedIcon
              sx={{ width: "16px", color: "darkGrey" }}
              onClick={() => props.deleteService(id)}
            />
            <ContentCopyRoundedIcon
              sx={{ width: "16px", color: "darkGrey" }}
              onClick={() => props.duplicateService(id)}
            />

            <ModeOutlinedIcon
              sx={{ width: "16px", color: "darkGrey" }}
              onClick={() => setIsDisplayed(true)}
            />
          </div>
        </button>
      )}
    </>
  );
}
