import React, { useState, useEffect } from "react";
import StepFormDocuments from "@/components/elements/StepFormDocuments";
import TextArea from "@/components/token/textareas/textareas";
import ServiceForm from "@/components/elements/ServiceForm/ServiceForm";
import Box from "@mui/material/Box";
import ButtonTailwind from "@/components/token/button/buttonTailwind";
import toast from "react-hot-toast";
import Notification from "@/components/token/toaster/notification";
import LastStepCreditForm from "@/feature/LastStepCreditForm/LastStepCreditForm";
import { useUser } from "@/contexts/UserContext";
import { useInvoices } from "@/hooks/useInvoices";
import CouldNotLoad from "@/components/elements/CouldNotLoad";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useClients } from "@/hooks/useClients";
import DocumentCreationModal from "@/components/token/documentCreationModal/DocumentCreationModal";
import { CRUDL } from "@/utils/crudl";
import { useArticles } from "@/hooks/useArticles";
import SearchSelect from "@/components/token/select/SearchSelect";
import { usePaymentInformation } from "@/hooks/usePaymentInformation";
import CenteredLoadingBlock from "@/components/elements/CenteredLoadingBlock";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function CreditForm(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  // const selectedInvoiceNumber = searchParams.get("invoiceNumber");

  const userContext = useUser();
  const enterpriseId = userContext.state.user.enterpriseId;

  const { isClientsLoading, clientsData } = useClients({
    enterpriseId: enterpriseId,
    clientId: props?.documentData?.data?.selectedInvoice?.clientId,
  });

  const articlesData = useArticles({ enterpriseId });

  const paymentInformation = usePaymentInformation({
    stakeholderId: enterpriseId,
  });

  const PaymentMethod = {
    IBAN: "IBAN",
    PAYMENT_LINK: "PAYMENT_LINK",
    CHEQUE: "CHEQUE",
    CASH: "CASH",
  };

  const accounts = (() => {
    if (paymentInformation.isLoading || paymentInformation.isError) {
      return [];
    }

    return paymentInformation.data?.payload?.stakeholderPaymentInformation.map(
      (p) => {
        const method = p.paymentInformationDetails.paymentMethod;
        return {
          id: p.id,
          name: p.title,
          title: p.title,
          paymentMethod: p.paymentInformationDetails.paymentMethod,
          iban:
            method === PaymentMethod.IBAN
              ? p.paymentInformationDetails.iban?.replace(/\s/g, "")
              : undefined,
          inFavourOf:
            method === PaymentMethod.CHEQUE
              ? p.paymentInformationDetails.inFavourOf
              : undefined,
          url:
            method === PaymentMethod.PAYMENT_LINK
              ? p.paymentInformationDetails.url
              : undefined,
          cash: method === PaymentMethod.CASH ? p.title : undefined,
        };
      }
    );
  })();

  useEffect(() => {
    props?.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        addresses: [
          {
            addressId: clientsData?.payload?.addresses?.find(
              (address) => address.isMain
            ).id,
            address: clientsData?.payload?.addresses?.find(
              (address) => address.isMain
            ),
            addressType: "RECEIVER_BILLING",
          },
          {
            addressId: props?.enterpriseInformation?.addresses?.find(
              (address) => address.isMain === true
            ).id,
            address: props?.enterpriseInformation?.addresses?.find(
              (address) => address.isMain === true
            ),
            addressType: "ISSUER_BILLING",
          },
        ],
      },
    });
  }, [isClientsLoading]);

  const selectedClient = clientsData?.payload;
  const articles = articlesData ? articlesData?.data?.payload : undefined;

  function handleAccountsToCredit(accountToAdd) {
    const updatedData = props?.documentData?.data?.additionalData.filter(
      (data) => data?.additionalDataType !== "PAYMENT_METHOD"
    );

    accountToAdd?.forEach((account) => {
      updatedData.push({
        additionalDataType: "PAYMENT_METHOD",
        additionalDataSubType:
          account.additionalDataSubType || account.paymentMethod,
        title: account.title,
        additionalData: account.additionalData
          ? account.additionalData
          : account.paymentMethod === "IBAN"
          ? account.iban
          : account.paymentMethod === "BIC"
          ? account.bic
          : account.paymentMethod === "PAYMENT_LINK"
          ? account.url
          : account.paymentMethod === "CHEQUE"
          ? account.inFavourOf
          : account.paymentMethod === "CASH"
          ? account.cash
          : undefined,
        externalId: account.externalId || account.id,
        // id: account.externalId || account.id,
      });
    });

    props?.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        additionalData: updatedData,
      },
    });
  }

  const submitFunctions = [step1, step2, () => setIsCreationModalOpen(true)];
  const [displayLeavingPopUp, setDisplayLeavingPopUp] = useState(false);

  const [servicesIsOk, setServicesIsOk] = useState(false);
  const [servicesId, setServicesId] = useState(0);
  const [currency, setCurrency] = useState("EUR");

  const [isAccountsSet, setIsAccountsSet] = useState(false);

  useEffect(() => {
    if (accounts?.length > 0) {
      const updatedAccountsToCredit = accounts.filter(
        (account) =>
          !props?.documentData?.data?.accountsToCredit?.some(
            (acc) => acc.id === account.id
          )
      );

      if (
        updatedAccountsToCredit?.length > 0 &&
        !isAccountsSet &&
        props?.documentData?.data?.additionalData?.filter(
          (a) => a.additionalDataType === "PAYMENT_METHOD"
        )?.length === 0
      ) {
        handleAccountsToCredit(updatedAccountsToCredit);

        setIsAccountsSet(true);
      } else if (
        props?.documentData?.data?.accountsToCredit?.length > 0 &&
        !isAccountsSet
      ) {
        setIsAccountsSet(true);
      }
    }
  }, [accounts]);

  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  const invoice = useInvoices({ enterpriseId });
  const invoiceContent = invoice.invoicesData?.payload?.items;

  const onServiceSelect = (service) => {
    const serviceStructure = {
      title: service.title,
      quantity: {
        amount: service.quantity.amount,
        unit: service.quantity.unit,
      },
      unitPrice: {
        amount: service.unitPrice.amount,
        currency: service.unitPrice.currency,
      },
      description: service.description,
      vatRate: {
        taxRate: service.vatRate.taxRate,
        countryOrRegion: service.vatRate.countryOrRegion,
      },
      isDisplayed: false,
      id: service.id + servicesId,
      TemplateId: service.id,
    };
    // setServices((currentServices) => [...currentServices, serviceStructure]);
    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        services: [...props.documentData.data.services, serviceStructure],
      },
    });
    setServicesId((currentId) => currentId + 1);
  };

  const onInvoiceSelect = (invoice) => {
    searchParams.set("invoiceNumber", invoice.invoiceNumber);
    setSearchParams(searchParams);

    const newAddData = props?.documentData?.data?.additionalData.map((data) => {
      if (data?.additionalDataType === "RECEIVER_NAME") {
        return {
          ...data,
          additionalData: invoice?.additionalData?.find(
            (a) => a.additionalDataType === "RECEIVER_NAME"
          )?.additionalData,
        };
      }
      return data;
    });

    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        clientId: invoice.clientId,
        selectedInvoice: invoice,
        additionalData: newAddData,
        services: invoice?.services,
      },
    });

    // setSelectedInvoice(invoice);
  };

  useEffect(() => {
    props?.documentData?.data?.selectedInvoice !==
      props?.documentData?.data?.selectedInvoice &&
      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          services: props?.documentData?.data?.selectedInvoice?.services?.map(
            (service) => ({
              title: service?.title,
              description: service?.description,
              quantity: {
                amount: service?.quantity?.amount,
                unit: service?.quantity?.unit || "",
              },
              unitPrice: {
                amount: service?.unitPrice?.amount,
                currency: currency,
              },
              vatRate: {
                countryOrRegion: userContext?.state?.user?.country || "BE",
                taxRate: service?.vatRate?.taxRate,
              },
              id: service?.id,
            })
          ),
        },
      });
  }, [props?.documentData?.data?.selectedInvoice]);

  useEffect(() => {
    if (
      props.context === CRUDL.UPDATE &&
      !props?.documentData?.data?.selectedInvoice
    ) {
      props?.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          selectedInvoice: props?.invoiceContent,
          linkedInvoice: props?.invoiceContent?.linkedInvoice,
        },
      });

      props?.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          additionalData: props?.invoiceContent?.additionalData.filter(
            (data) => data.additionalDataType !== "PAYMENT_METHOD"
          ),
        },
      });

      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          services: props?.invoiceContent?.services,
        },
      });
    }

    const totalPrice =
      props?.documentData?.services?.reduce(
        (acc, service) =>
          acc + service?.unitPrice?.amount * service?.quantity?.amount,
        0
      ) || 0;
    const totalTVAPrice =
      props?.documentData?.services?.reduce(
        (acc, service) =>
          acc +
          service?.unitPrice?.amount *
            service?.quantity?.amount *
            service?.vatRate?.taxRate,
        0
      ) || 0;

    const totalRounded = totalPrice + totalTVAPrice;

    props.setDocumentData({
      data: {
        step: props?.documentData?.data?.step || 0,
        Logo: props?.documentData?.data?.Logo,
        SIREN: props?.siren,
        enterpriseNumber: props?.enterpriseNumber,
        clientId:
          props?.documentData?.clientId ||
          props?.documentData?.data?.selectedInvoice?.clientId,
        issuingDate:
          props?.documentData?.data?.issuingDate || transformDate(new Date()),
        services: props?.documentData?.data?.services || [],
        purchaseOrder: props?.documentData?.data?.purchaseOrder,
        addresses: [
          {
            addressId: selectedClient?.addresses?.find(
              (address) => address.isMain
            ).id,
            address:
              props?.documentData?.data?.selectedInvoice?.addresses?.find(
                (address) => address.addressType == "RECEIVER_BILLING"
              )?.address,
            addressType: "RECEIVER_BILLING",
          },
          {
            addressId: props?.enterpriseInformation?.addresses?.find(
              (address) => address.isMain === true
            ).id,
            address: props?.enterpriseInformation?.addresses?.find(
              (address) => address.isMain === true
            ),
            addressType: "ISSUER_BILLING",
          },
        ],
        additionalData: props?.documentData?.data?.additionalData || [
          {
            // additionalData: props?.documentData?.data?.conditionsMentions,
            additionalData:
              props?.documentData?.data?.additionalData?.find(
                (data) => data.additionalDataType === "INVOICE_CONDITIONS"
              )?.additionalData || "",
            additionalDataType: "INVOICE_CONDITIONS",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData: "",
            additionalDataType: "VAT_DISCLAIMER",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData:
              props?.documentData?.selectedInvoice?.additionalData?.find(
                (a) => a.additionalDataType === "RECEIVER_NAME"
              )?.additionalData,
            additionalDataType: "RECEIVER_NAME",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData: props?.enterpriseInformation?.name,
            additionalDataType: "ISSUER_NAME",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData: props?.invoiceEmail,
            additionalDataType: "ISSUER_EMAIL",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData: props?.invoicePhone,
            additionalDataType: "ISSUER_PHONE",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData:
              props?.documentData?.data?.additionalData?.find(
                (credit) => credit.additionalDataType === "CREDIT_NOTE_REASON"
              )?.additionalData || "",
            additionalDataType: "CREDIT_NOTE_REASON",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData:
              props?.documentData?.data?.additionalData?.find(
                (credit) =>
                  credit.additionalDataType ===
                  "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
              )?.additionalData || "",
            additionalDataType: "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData:
              props?.documentData?.data?.additionalData?.find(
                (data) => data.additionalDataType === "CREDIT_NOTE_CONDITIONS"
              )?.additionalData || "",
            additionalDataType: "CREDIT_NOTE_CONDITIONS",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData: "",
            additionalDataType: "DOCUMENT_BODY_COLOR",
            additionalDataSubType: "NOT_APPLICABLE",
          },
          {
            additionalData: "",
            additionalDataType: "DOCUMENT_TITLE_COLOR",
            additionalDataSubType: "NOT_APPLICABLE",
          },
        ],

        linkedInvoice:
          props?.documentData?.data?.selectedInvoice?.invoiceNumber,
        selectedInvoice: props?.documentData?.data?.selectedInvoice || "",
        selectedClient: selectedClient,
        documentType: "CREDIT_NOTE",
        total: {
          amount: totalPrice,
          currency: currency,
        },
        totalWithTax: {
          amount: totalTVAPrice,
          currency: currency,
        },
        totalRounded: {
          amount: totalRounded,
          currency: currency,
        },
        issuerMail: props?.documentData?.data?.issuerMail || {
          isShown: true,
          data: props?.invoiceEmail,
        },
        issuerPhone: props?.documentData?.data?.issuerPhone || {
          isShown: true,
          data: props?.invoicePhone,
        },
        accountsToCredit:
          props?.documentData?.data?.accountsToCredit ||
          props?.documentData?.data?.additionalData
            ?.filter((data) => data.additionalDataType === "PAYMENT_METHOD") // Use filter instead of find to get an array
            ?.map((account) => ({
              id: account.externalId, // It seems you want to use the id directly from the account object
              paymentMethod: account.additionalDataSubType,
              title: account.title,
              iban:
                account.additionalDataSubType === "IBAN"
                  ? account.additionalData
                  : undefined,
              bic:
                account.additionalDataSubType === "BIC"
                  ? account.additionalData
                  : undefined,
              url:
                account.additionalDataSubType === "PAYMENT_LINK"
                  ? account.additionalData
                  : undefined,
              inFavourOf:
                account.additionalDataSubType === "CHEQUE"
                  ? account.additionalData
                  : undefined,
              cash:
                account.additionalDataSubType === "CASH"
                  ? account.additionalData
                  : undefined,
            })) ||
          [],
      },
      enterpriseId,
    });
  }, [currency, enterpriseId, props?.invoiceStyle, selectedClient]);

  const navigate = useNavigate();

  if (invoice.isInvoicesLoading) {
    return (
      <CenteredLoadingBlock message="Chargement de la facture..." tag="tr" />
    );
  }

  if (invoice.isInvoicesError || !invoiceContent) {
    return (
      <CouldNotLoad
        message="Impossible de charger la facture"
        error={invoice.invoicesError}
      />
    );
  }

  function transformDate(date) {
    const dateObj = new Date(date);
    const day = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const month = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function step1() {
    if (!props?.documentData?.data?.selectedInvoice) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Veuillez sélectionner une facture avant de continuer"
          info={null}
        />
      ));
    } else if (
      !props?.documentData?.data?.additionalData?.find(
        (data) => data.additionalDataType === "CREDIT_NOTE_REASON"
      )?.additionalData
    ) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Veuillez ajouter une raison pour la note de crédit avant de continuer"
          info={null}
        />
      ));
    } else {
      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          step: props?.documentData?.data?.step + 1,
        },
      });
    }
  }

  function step2() {
    let allServicesValid = true; // Assume all services are valid initially

    props?.documentData?.data?.services?.forEach((service) => {
      const isTitleError = service.title === "";
      const isQuantityError = service.quantity.amount === null; // or other appropriate checks for a number
      const isPriceError = service.unitPrice.amount === "";
      const isVATError =
        service.vatRate.taxRate === "" || service.vatRate.taxRate === "Autre";

      const isValid = !(
        isTitleError ||
        isQuantityError ||
        isPriceError ||
        isVATError
      );

      // If any service is invalid, mark allServicesValid as false
      if (!isValid) {
        allServicesValid = false;
      }
    });

    if (!allServicesValid) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Veuillez valider tous vos service avant de continuer"
          info={null}
        />
      ));
    } else if (props?.documentData?.data?.services.length === 0) {
      toast.custom(() => (
        <Notification
          isSuccess={false}
          message="Vous devez ajouter au moins un service pour continuer"
          info={null}
        />
      ));
    } else {
      props.setDocumentData({
        ...props.documentData,
        data: {
          ...props.documentData.data,
          step: props?.documentData?.data?.step + 1,
        },
      });
    }
  }
  function addService() {
    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        services: [
          ...props.documentData.data.services,
          { id: servicesId, isDisplayed: true },
        ],
      },
    });

    setServicesId((currentId) => currentId + 1);
    setServicesIsOk(false);
  }

  function duplicateService(serviceId) {
    // Trouver le service à dupliquer
    const serviceToDuplicate = props?.documentData?.data?.services.find(
      (service) => service.id === serviceId
    );

    // Créer une copie du service avec un nouvel ID
    const duplicatedService = { ...serviceToDuplicate, id: servicesId };

    props.setDocumentData({
      ...props.documentData,
      data: {
        ...props.documentData.data,
        services: [
          ...props.documentData.data.services,
          {
            id: servicesId + 1,
            title: duplicatedService.title,
            quantity: {
              amount: duplicatedService.quantity.amount,
              unit: duplicatedService.quantity.unit,
            },
            unitPrice: {
              amount: duplicatedService.unitPrice.amount,
              currency: duplicatedService.unitPrice.currency,
            },
            description: duplicatedService.description,
            vatRate: {
              taxRate: duplicatedService.vatRate.taxRate,
              countryOrRegion: duplicatedService.vatRate.countryOrRegion,
            },
          },
        ],
      },
    });

    setServicesId((currentId) => currentId + 1);
    setServicesIsOk(false);
  }

  function manageServices(id, data) {
    const updatedServices = props?.documentData?.data?.services.map(
      (service) => {
        if (service?.id === id) {
          return { ...service, ...data };
        }
        return service;
      }
    );

    props.setDocumentData({
      ...props.documentData,
      data: { ...props.documentData.data, services: updatedServices },
    });
  }

  const filteredInvoices = invoiceContent
    .filter(
      (inv) =>
        inv.documentType == "INVOICE" &&
        inv.invoiceStatus != "DRAFT" &&
        inv.invoiceStatus != "TO_VALIDATE" &&
        inv.invoiceStatus != "CANCELLED"
    )
    .sort((a, b) => new Date(b.issuingDate) - new Date(a.issuingDate));

  function deleteService(serviceId) {
    // Filtrer le tableau pour exclure le service avec l'ID spécifié
    const updatedServices = props?.documentData?.data?.services.filter(
      (service) => service?.id !== serviceId
    );

    // Mettre à jour l'état avec le nouveau tableau
    props.setDocumentData({
      ...props.documentData,
      data: { ...props.documentData.data, services: updatedServices },
    });
  }

  return (
    <>
      {isCreationModalOpen && (
        <DocumentCreationModal
          createDocument={() => props.createInvoice("CREATED")}
          unDisplay={() => setIsCreationModalOpen(false)}
          isLoading={props.isDocumentCreationLoading}
        />
      )}
      {displayLeavingPopUp && (
        <div className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center bg-[rgba(0,0,0,0.5)] backdrop-blur-sm z-50">
          <div className="rounded-2xl bg-white flex w-[32rem] py-12 px-8 flex-col items-start gap-8">
            <h1 className="text-black text-4xl font-semibold">
              Êtes-vous sûr de vouloir quitter ?
            </h1>
            <div className="flex gap-4">
              <ButtonTailwind
                variant="outlined2"
                width="100%"
                onClick={() => setDisplayLeavingPopUp(false)}
              >
                Non
              </ButtonTailwind>
              <ButtonTailwind
                variant="filled"
                width="100%"
                onClick={() => {
                  navigate("/invoices"), props?.setDocumentData({});
                }}
              >
                Oui
              </ButtonTailwind>
            </div>
          </div>
        </div>
      )}
      <StepFormDocuments
        docType="Note de crédit"
        setStep={(step) => {
          props.setDocumentData({
            ...props.documentData,
            data: {
              ...props.documentData.data,
              step: step,
            },
          });
        }}
        numberOfSteps={3}
        currentStep={props?.documentData?.data?.step}
        TextSubmit="Créer ma note de crédit"
        onSubmit={submitFunctions[props?.documentData?.data?.step]}
        setStepBack={() => {
          props.setDocumentData({
            ...props.documentData,
            data: {
              ...props.documentData.data,
              step: props?.documentData?.data?.step - 1,
            },
          });
        }}
        setTab={props.setTab}
        createDraft={() => props.createInvoice("DRAFT")}
        setDisplayLeavingPopUp={setDisplayLeavingPopUp}
        setParametersOpen={props.setParametersOpen}
        draftDisplay={
          (props?.documentData?.data?.step == 0 &&
            props?.documentData?.data?.selectedInvoice) ||
          props?.documentData?.data?.step > 0
        }
        isDocumentCreationLoading={props.isDocumentCreationLoading}
      >
        {props?.documentData?.data?.step === 0 && (
          <Box className="flex flex-col gap-4 w-full">
            <SearchSelect
              label="Facture originale concernée"
              selectedValue={props?.documentData?.data?.selectedInvoice}
              onSelect={onInvoiceSelect}
              options={filteredInvoices}
              placeholder={`Rechercher votre facture (ex: F-${new Date().getFullYear()}-0005)...`}
              accessor="invoiceNumber"
            />
            <TextArea
              label="Raison de l'émission d'une note de crédit"
              placeholder="Description du motif (retour de marchandise, erreur de facturation, accord commercial, etc.)."
              value={
                props?.documentData?.data?.additionalData?.find(
                  (data) => data.additionalDataType === "CREDIT_NOTE_REASON"
                )?.additionalData
              }
              onChange={(e) => {
                const newAddData =
                  props?.documentData?.data?.additionalData?.map((data) => {
                    if (data?.additionalDataType === "CREDIT_NOTE_REASON") {
                      return {
                        ...data,
                        additionalData: e.target.value,
                      };
                    }
                    return data;
                  });
                props?.setDocumentData({
                  ...props?.documentData,
                  data: {
                    ...props?.documentData?.data,
                    additionalData: newAddData,
                  },
                });
              }}
              required
            />
          </Box>
        )}
        {props?.documentData?.data?.step === 1 && (
          <Box className="flex flex-col w-full gap-4">
            <SearchSelect
              label="Service"
              selectedValue={"services"}
              onSelect={onServiceSelect}
              displayCreationElement={() => addService()}
              creationElement="Ajouter un service"
              options={articles}
              placeholder="Sélectionnez un service"
              accessor="title"
            />
            <Box className="flex flex-col w-full gap-4">
              {props?.documentData?.data?.services?.map((service) => (
                <div key={service?.id} className="w-full min-h-max">
                  <ServiceForm
                    manageServices={manageServices}
                    setCurrency={setCurrency}
                    currency={currency}
                    id={service?.id}
                    setServicesIsOk={setServicesIsOk}
                    duplicateService={duplicateService}
                    deleteService={deleteService}
                    title={service?.title}
                    quantityAmount={service?.quantity?.amount}
                    quantityUnit={service?.quantity?.unit}
                    priceAmount={service?.unitPrice?.amount}
                    priceCurrency={service?.price?.currency}
                    description={service?.description}
                    VATRate={service?.vatRate?.taxRate}
                    VATCountry={service?.vatRate?.countryOrRegion}
                    isDisplayed={service?.isDisplayed}
                    TemplateId={service?.TemplateId}
                  />
                </div>
              ))}{" "}
              <ButtonTailwind variant="text" onClick={() => addService()}>
                <PlusIcon class="h-6 w-6 text-neutral-700" />
                Ajouter un service
              </ButtonTailwind>
            </Box>
          </Box>
        )}
        {props?.documentData?.data?.step === 2 && (
          <LastStepCreditForm
            paymentInformation={paymentInformation}
            accounts={accounts}
            issuingDate={props?.documentData?.data?.issuingDate}
            setIssuingDate={(date) => {
              props.setDocumentData({
                ...props.documentData,
                data: { ...props.documentData.data, issuingDate: date },
              });
            }}
            accountsToCredit={props?.documentData?.data?.additionalData.filter(
              (data) => data?.additionalDataType === "PAYMENT_METHOD"
            )}
            setAccountsToCredit={(data) => {
              handleAccountsToCredit(data);
            }}
            conditionsMentions={
              props?.documentData?.data?.additionalData.find(
                (data) => data?.additionalDataType === "INVOICE_CONDITIONS"
              )?.additionalData
            }
            setConditionsMentions={(e) => {
              const newAddData = props?.documentData?.data?.additionalData.map(
                (data) => {
                  if (data?.additionalDataType === "INVOICE_CONDITIONS") {
                    return {
                      ...data,
                      additionalData: e,
                    };
                  }
                  return data;
                }
              );
              props?.setDocumentData({
                ...props?.documentData,
                data: {
                  ...props?.documentData?.data,
                  additionalData: newAddData,
                },
              });
            }}
            PurchaseOrderNumber={props.documentData.data.purchaseOrder}
            setPurchaseOrderNumber={(e) => {
              props.setDocumentData({
                ...props.documentData,
                data: {
                  ...props.documentData.data,
                  purchaseOrder: e,
                },
              });
            }}
            creditAppliance={
              props?.documentData?.data?.additionalData.find(
                (data) =>
                  data?.additionalDataType ===
                  "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
              )?.additionalData
            }
            setCreditAppliance={(e) => {
              const newAddData = props?.documentData?.data?.additionalData.map(
                (data) => {
                  if (
                    data?.additionalDataType ===
                    "CREDIT_NOTE_PAYMENT_APPLICATION_METHOD"
                  ) {
                    return {
                      ...data,
                      additionalData: e,
                    };
                  }
                  return data;
                }
              );
              props?.setDocumentData({
                ...props?.documentData,
                data: {
                  ...props?.documentData?.data,
                  additionalData: newAddData,
                },
              });
            }}
            VATMentions={
              props?.documentData?.data?.additionalData.find(
                (data) => data?.additionalDataType === "VAT_DISCLAIMER"
              )?.additionalData
            }
            setVATMentions={(e) => {
              const newAddData = props?.documentData?.data?.additionalData.map(
                (data) => {
                  if (data?.additionalDataType === "VAT_DISCLAIMER") {
                    return {
                      ...data,
                      additionalData: e,
                    };
                  }
                  return data;
                }
              );
              props?.setDocumentData({
                ...props?.documentData,
                data: {
                  ...props?.documentData?.data,
                  additionalData: newAddData,
                },
              });
            }}
          />
        )}
      </StepFormDocuments>
    </>
  );
}
