import React from "react";
import { Datepicker } from "flowbite-react";
import { datePickerTheme } from "@/data/themes.js";

const MyDateRangePicker = ({
  issuingDate,
  setIssuingDate,
  dueDate,
  setDueDate,
  labeLDueDate,
}) => {
  // Function to transform JavaScript Date to formatted string
  function transformDate(date) {
    const dateObj = new Date(date);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed, so +1
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Function to convert string date to JavaScript Date object
  function convertStringToDate(dateString) {
    if (!dateString) return null; // Handle empty or undefined case
    const [day, month, year] = dateString.split("/");
    return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date constructor
  }

  return (
    <div className="flex items-center w-full gap-4 max-w-full">
      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Date d&apos;émission
        </label>
        <Datepicker
          placeholder="Sélectionner la date de début"
          dateformat="dd/MM/yyyy"
          selected={convertStringToDate(issuingDate)}
          onSelectedDateChanged={(date) => setIssuingDate(transformDate(date))}
          selectsStart
          startDate={convertStringToDate(issuingDate)}
          endDate={convertStringToDate(dueDate)}
          language="fr"
          weekStart={1} // Adjust to your desired week start (1 for Monday)
          maxDate={convertStringToDate(dueDate)}
          theme={datePickerTheme}
          className="dark:bg-black focus:ring-gray-700"
          defaultDate={convertStringToDate(issuingDate)}
          labelTodayButton="Aujourd'hui"
          labelClearButton="Annuler"
        />
      </div>

      <div className="w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {labeLDueDate || "Date d'échéance"}
        </label>
        <Datepicker
          placeholder="Sélectionner la date de fin"
          dateformat="dd/MM/yyyy"
          selected={convertStringToDate(dueDate)}
          onSelectedDateChanged={(date) => setDueDate(transformDate(date))}
          selectsEnd
          startDate={convertStringToDate(issuingDate)}
          endDate={convertStringToDate(dueDate)}
          language="fr"
          weekStart={1} // Adjust to your desired week start
          minDate={convertStringToDate(issuingDate)}
          theme={datePickerTheme}
          className="dark:bg-black focus:ring-gray-700"
          defaultDate={convertStringToDate(dueDate)}
          labelTodayButton="Aujourd'hui"
          labelClearButton="Annuler"
        />
      </div>
    </div>
  );
};

export default MyDateRangePicker;
