import React from "react";
import styles from "./EnterpriseForm.module.css";
import { Identifier } from "@/components/elements/stakeholder/identifier/Identifier";
import { PostalCode } from "@/components/elements/stakeholder/address/PostalCode";
import { City } from "@/components/elements/stakeholder/address/City";
import { StreetName } from "@/components/elements/stakeholder/address/StreetName";
import { CompanyName } from "@/components/elements/stakeholder/main/CompanyName";
import Toggle from "@/components/token/button/toggle";
import { Country } from "@/components/elements/stakeholder/address/Country";

export default function EnterpriseForm({ enterpriseForm }) {
  const {
    companyName,
    setCompanyName,
    streetName,
    setStreetName,
    city,
    setCity,
    postalCode,
    setPostalCode,
    selectedNation,
    setSelectedNation,
    companyIdentifier,
    setCompanyIdentifier,
    tvaFacturation,
    setTVAFacturation,
    createEnterprise,
    isFirstEnterprise,
    countryIdentifierType,
    isInvalidFormSubmitted,
  } = enterpriseForm;

  return (
    <div className={styles.enterpriseFormWrap}>
      <div className={styles.enterpriseForm}>
        <div className={styles.enterpriseFormHeader}>
          <div id="getStartedSignUp">
            {isFirstEnterprise
              ? "Une dernière étape"
              : "Créez une nouvelle enterprise"}
          </div>
          <p className="text-base text-slate-500">
            Vos informations de société seront
            <br />
            automatiquement ajoutées à vos factures
          </p>
        </div>

        <div className={styles.enterpriseFormInputs}>
          {/* <div className={styles.enterpriseSelects}> */}
          <div className={styles.nationSelectBox}>
            <Country
              onChange={(c) => {
                setSelectedNation(c.data);
                setCompanyIdentifier({
                  success: true,
                  data: {
                    ...companyIdentifier.data,
                    countryOrRegion: c.data,
                    identifierType: countryIdentifierType(c.data),
                    identifier: companyIdentifier.data.identifier.replace(
                      /\D/g,
                      ""
                    ),
                  },
                  isDirty: true,
                });
              }}
              initialCountry={selectedNation}
              disabled={false}
              name="country"
              countries={["BE", "FR"]}
            />
            {/* <NationSelect
              setSelectedNation={(c) => {
                setSelectedNation(c);
                setCompanyIdentifier({
                  success: true,
                  data: {
                    ...companyIdentifier.data,
                    countryOrRegion: c,
                  },
                  isDirty: true,
                });
              }}
              selectedNation={selectedNation}
            /> */}
          </div>
          <Identifier
            identifierType={companyIdentifier?.data?.identifierType}
            countryOrRegion={companyIdentifier?.data?.countryOrRegion}
            initialIdentifier={companyIdentifier?.data?.identifier}
            disabled={createEnterprise.isLoading}
            onChange={setCompanyIdentifier}
            isLoading={false}
            name={"companyNumber"}
            required={companyIdentifier.isDirty}
            displayError={
              (companyIdentifier.isDirty &&
                companyIdentifier.data.identifier !== "") ||
              isInvalidFormSubmitted
            }
            // label={"Numéro d'enterprise"}
          />
          {/* <div className={styles.deviseBox}>
              <label htmlFor="currency">Devise</label>
              <SelectCurrency currency={currency} setCurrency={setCurrency} />
            </div>
          </div> */}

          <CompanyName
            initialCompanyName={companyName.data}
            disabled={createEnterprise.isLoading}
            onChange={(cn) => {
              setCompanyName((prev) => ({
                ...prev,
                data: cn.data,
                success: cn.success,
                isDirty: cn.isDirty,
              }));
            }}
            isLoading={false}
            name="companyName"
            required={companyName.isDirty}
            displayError={
              (companyName.isDirty && companyName.data !== "") ||
              isInvalidFormSubmitted
            }
          />

          <StreetName
            initialStreetName={streetName.data}
            disabled={createEnterprise.isLoading}
            onChange={(sn) => {
              setStreetName((prev) => ({
                ...prev,
                data: sn.data,
                success: sn.success,
                isDirty: sn.isDirty,
              }));
            }}
            isLoading={false}
            name="streetName"
            required={streetName.isDirty}
            displayError={
              (streetName.isDirty && streetName.data !== "") ||
              isInvalidFormSubmitted
            }
          />

          <div className={styles.postalCityBox}>
            <PostalCode
              country={selectedNation}
              initialPostalCode={postalCode?.data}
              disabled={createEnterprise.isLoading}
              onChange={(pc) => {
                setPostalCode((prev) => ({
                  ...prev,
                  data: pc.data,
                  success: pc.success,
                  isDirty: pc.isDirty,
                }));
              }}
              isLoading={false}
              name="postalCode"
              required={postalCode.isDirty}
              displayError={
                (postalCode.isDirty && postalCode.data !== "") ||
                isInvalidFormSubmitted
              }
            />
            <City
              initialCity={city?.data}
              disabled={createEnterprise.isLoading}
              onChange={(c) => {
                setCity((prev) => ({
                  ...prev,
                  data: c.data,
                  success: c.success,
                  isDirty: c.isDirty,
                }));
              }}
              isLoading={false}
              name="streetName"
              required={city.isDirty}
              displayError={
                (city.isDirty && city.data !== "") || isInvalidFormSubmitted
              }
            />
          </div>
        </div>

        {/* <ButtonTailwind
          onClick={createNewEnterprise}
          disabled={!isFieldCompleted}
          variant={isFieldCompleted ? "outlined2" : "filled"}
          width="100%"
          loading={createEnterprise.isLoading}
        >
          {isFirstEnterprise
            ? "Débuter gratuitement"
            : "Créer une nouvelle enterprise"}
        </ButtonTailwind> */}
        <div className="flex flex-col gap-2">
          <label>Je facture la TVA</label>
          <Toggle
            onClick={() => setTVAFacturation(!tvaFacturation)}
            isToggled={tvaFacturation}
          />
        </div>
      </div>
    </div>
  );
}
